import React, { Fragment, useEffect, useState } from 'react';
import { FormGroup, Label, Input, Button, Spinner } from 'reactstrap';
import axios from 'axios';
import activities from '../global/activities';

const Filter = ({ source, period, type, loading, onFilter }) => {
    const [fields, setFields] = React.useState();
    const [actividades, setActividades] = useState([]);
    const [tiposDeIngresos, setTiposDeIngresos] = React.useState([]);

    const handleChange = (field, value) => {
        setFields({ ...fields, [field]: value });
    }

    useEffect(() => {
        if(period) getData()
    }, [period])

    const getData = () => {
        axios.get(`/api/issued-invoices/actividades/?ejercicio=${period}&filtro=CODACT>=0`, {
            cancelToken: source.token
            })
            .then(res => {
                setActividades(res.data);
            })

        axios.get(`/api/issued-invoices/tipos_de_ingreso/?ejercicio=${period}&filtro=LIBTGI=1 ORDER BY CODTGI`, {
            cancelToken: source.token
            })
            .then(res => {
                setTiposDeIngresos(res.data);
            })
    }

    const setFilter = () => {
        let params = {}

        if(type === 'issued-invoices') {
            if(fields?.actividad && fields?.actividad !== '') params.ACTFEM = fields.actividad
            if(fields?.tipo_de_ingreso && fields?.tipo_de_ingreso !== '') params.TREFEM = fields.tipo_de_ingreso
            if(fields?.tipo_de_operacion && fields?.tipo_de_operacion !== '') params.TIPFEM = fields.tipo_de_operacion
        }
        else if(type === 'invoices-received') {
            if(fields?.actividad && fields?.actividad !== '') params.ACTFRE = fields.actividad
            if(fields?.tipo_de_ingreso && fields?.tipo_de_ingreso !== '') params.TREFRE = fields.tipo_de_ingreso
            if(fields?.tipo_de_operacion && fields?.tipo_de_operacion !== '') params.TIPFRE = fields.tipo_de_operacion
        }

        let query = ''
        for(let key in params) {
            query += ` AND ${key}=${params[key]} `
        }

        onFilter(query)
    }

    return (
        <Fragment>
            <FormGroup>
                <Label htmlFor="actividad">Actividad</Label>
                <Input 
                    id="actividad" 
                    type="select" 
                    name="actividad"    
                    onChange={(e) => handleChange("actividad", e.target.value)}
                    value={fields?.fields}
                >
                    <option value=""></option>
                    {actividades.map((actividad, key) => {
                        return <option key={key} value={actividad.CODACT}>{actividad.EPIACT} - {activities[actividad.EPIACT]}</option>
                    })}
                </Input>
            </FormGroup>

            <FormGroup>
                <Label htmlFor="tipo_de_ingreso">Tipo de ingreso</Label>
                <Input 
                    id="tipo_de_ingreso" 
                    type="select" 
                    name="tipo_de_ingreso"    
                    onChange={(e) => handleChange("tipo_de_ingreso", e.target.value)}
                    value={fields?.tipo_de_ingreso}
                >
                    <option value=""></option>
                    {tiposDeIngresos.map((tipoDeIngreso, key) => {
                        return <option key={key} value={tipoDeIngreso.CODTGI}>{tipoDeIngreso.DENTGI}</option>
                    })}
                </Input>
            </FormGroup>

            <FormGroup>
                <Label htmlFor="tipo_de_operacion">Tipo de operación</Label>
                <Input 
                    id="tipo_de_operacion" 
                    type="select" 
                    name="tipo_de_operacion"    
                    onChange={(e) => handleChange("tipo_de_operacion", e.target.value)}
                    value={fields?.tipo_de_operacion}
                >
                    <option value=""></option>
                    <option value="0">General</option>
                    <option value="1">Intracomunitario</option>
                    <option value="2">Exportaciones</option>
                    <option value="3">Interior exento</option>
                </Input>
            </FormGroup>

            <Button color="primary" block onClick={() => setFilter()}>
                {loading ? <Spinner size="sm" className="me-2"/> : null} 
                {!loading ? 'Aplicar' : 'Filtrando...'}</Button>
        </Fragment>
    )
}

export default Filter;