import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import axios from 'axios';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-slidedown/lib/slidedown.css'
import './index.css';

window.baseURL = window.location.hostname === 'localhost' ? 'http://localhost:8000/' : 'https://api.gestapymes.com/';
axios.defaults.baseURL = window.baseURL;
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

if(localStorage.token) {
  axios.defaults.headers.common['Authorization'] = 'Token ' + localStorage.token;
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
