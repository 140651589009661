import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, Alert, Row, Col, Form, FormGroup, FormFeedback, Input, Label, Button, Spinner, CardTitle } from 'reactstrap';
import axios from 'axios';


export default function ChangePassword() {
  const [fields, setFields] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState(null);

  const navigate = useNavigate();

  const handleChange = (field, value) => {
    setFields({ ...fields, [field]: value });
  }

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    //old_password
    if (!fields['old_password']) {
      formIsValid = false;
      errors['old_password'] = 'Campo obligatorio.';
    }

    //new_password
    if (!fields['new_password']) {
      formIsValid = false;
      errors['new_password'] = 'Campo obligatorio.';
    }

    //new_password_confirm
    if (!fields['new_password_confirm']) {
      formIsValid = false;
      errors['new_password_confirm'] = 'Campo obligatorio.';
    }
    else if (fields['new_password_confirm'] !== fields['new_password']) {
      formIsValid = false;
      errors['new_password_confirm'] = 'La contraseña no coincide';
    }

    setErrors(errors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      changePassword()
    }
  }

  const changePassword = () => {
    setMessage();
    setLoading(true);
    axios.put('/api/auth/change-password/', fields)
      .then(res => {
        setLoading(false);
        localStorage.clear();
        delete axios.defaults.headers.common['Authorization'];
        navigate('/login?message=Contraseña cambiada correctamente. Inicie sesión con su nueva contraseña.&color=success');
      })
      .catch(err => {
        setLoading(false);
        if(err.response.data.detail) {
          setMessage({color: 'danger', text: err.response.data.detail});
        } else {
          let errors = {};
          for(let key in err.response.data) {
            errors[key] = err.response.data[key];
          }
          setErrors(errors)
        }
      })
  }

  return (
    <Card>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="6">
              <CardTitle>Contraseña</CardTitle>
              <p>Después de una actualización exitosa de la contraseña, será redirigido a la página de inicio de sesión donde podrá iniciar sesión con su nueva contraseña.</p>
            </Col>
            <Col md="6">
              {message ? <Alert color={message.color}>{message.text}</Alert> : null}
              <FormGroup>
                <Label htmlFor="old_password">Contraseña actual</Label>
                <Input 
                  id="old_password" 
                  type="password" 
                  name="old_password"    
                  onChange={(e) => handleChange("old_password", e.target.value)}
                  value={fields["old_password"]}
                  invalid={errors["old_password"] ? true : false}
                />
                <FormFeedback>{errors["old_password"]}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="new_password">Nueva contraseña</Label>
                <Input 
                  id="new_password" 
                  type="password" 
                  name="new_password"    
                  onChange={(e) => handleChange("new_password", e.target.value)}
                  value={fields["new_password"]}
                  invalid={errors["new_password"] ? true : false}
                />
                <FormFeedback>{errors["new_password"]}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="new_password">Confirmación de contraseña</Label>
                <Input 
                  id="new_password_confirm" 
                  type="password" 
                  name="new_password_confirm"    
                  onChange={(e) => handleChange("new_password_confirm", e.target.value)}
                  value={fields["new_password_confirm"]}
                  invalid={errors["new_password_confirm"] ? true : false}
                />
                <FormFeedback>{errors["new_password_confirm"]}</FormFeedback>
              </FormGroup>
              <Button type="submit" color="primary">Cambiar contraseña{loading ? <Spinner size="sm" className="ms-1" /> : null}</Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}