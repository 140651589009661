import axios from 'axios';
import * as React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Label, Row, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Table, ListGroup, ListGroupItem } from 'reactstrap';
import Select from 'react-select'
import moment from 'moment';
import SupplierForm from './SupplierForm';
import Loading from '../components/Loading';
import EmptyData from '../components/EmptyData';
import { isEmpty, validEmail, validURL } from '../lib/validator';
import activities from '../global/activities';
import gruposDeAmortizacion from '../global/gruposDeAmortizacion.json';
import NumberFormat from '../components/NumberFormat';

const initialValues = {

}

export default function InvestmentAssetForm({ period, source }) {
  const [fields, setFields] = React.useState(initialValues);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [loading3, setLoading3] = React.useState(false);
  const [loading4, setLoading4] = React.useState(false);
  const [modalDelete, setModalDelete] = React.useState(false);
  const [actividades, setActividades] = React.useState([]);
  const [tiposDeIngresos, setTiposDeIngresos] = React.useState([]);
  const [suppliers, setSuppliers] = React.useState([]);
  const [amortizationGroup, setAmortizationGroup] = React.useState(gruposDeAmortizacion);
  const [modalProveedor, setModalProveedor] = React.useState(false);
  const [modalSelectProveedor, setModalSelectProveedor] = React.useState(false);
  const [modalSelectGA, setModalSelectGA] = React.useState(false);

  const params = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    getData();
  }, [period, params.id])

  const getData = () => {
    axios.get(`/api/investment-assets/actividades/?ejercicio=${period}&filtro=CODACT>=0`, {
        cancelToken: source.token
      })
        .then(res => {
            setActividades(res.data);
        })

    axios.get(`/api/investment-assets/tipos_de_ingreso/?ejercicio=${period}&filtro=LIBTGI=1 ORDER BY CODTGI`, {
        cancelToken: source.token
        })
        .then(res => {
            setTiposDeIngresos(res.data);
        })

    getSuppliers()
    // getAmortizationGroup()

    if(params.id) {
        setLoading2(true);
        axios.get(`/api/investment-assets/${params.id ? params.id +'/' : ''}?ejercicio=${period}&filtro=CODINM=${params.id}`, {
            cancelToken: source.token
          })
          .then(res => {
            setLoading2(false);
            // res.data.NOMGIN = 
            let data = res.data;
            if(data.GAMINM) data.NOMGIN = amortizationGroup.find(item => parseInt(item.id) === parseInt(data.GAMINM)).description;
            // console.log('asdf', amortizationGroup, data.GAM)
            setFields(data.CODINM ? data : null);
          })
    } else {
        setFields({ ...fields });
        setLoading4(true)
        axios.get(`/api/investment-assets/last_id/?ejercicio=${period}`, {
            cancelToken: source.token
          })
            .then(res => {
                setLoading4(false);
                setFields({...fields, CODINM: res.data});
            })
    }
  }

  const setNewSupplier = (data) => {
    setFields({ 
        ...fields,
        PROINM: data.CODPRO,
    });
  }

  const getSuppliers = (callback) => {
    axios.get(`/api/investment-assets/suppliers/?ejercicio=${period}&filtro=CODPRO>=0 ORDER BY CODPRO DESC`, {
        cancelToken: source.token
        })
        .then(res => {
            setSuppliers(res.data);
        })
  }

//   const getAmortizationGroup = (callback) => {
//     axios.get(`/api/investment-assets/amortization_group/?ejercicio=${period}&filtro=CODGIN>=0 ORDER BY CODGIN DESC`, {
//         cancelToken: source.token
//         })
//         .then(res => {
//             setAmortizationGroup(res.data);
//         })
//   }

  const handleChange = (field, value) => {
    setFields({ ...fields, [field]: value });
  }

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (isEmpty(fields['CODINM'])) {
        formIsValid = false;
        errors['CODINM'] = 'Campo obligatorio.';
    }

    setErrors(errors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      setInvoiceIssued()
    }
  }

  const setInvoiceIssued = () => {
    setLoading(true);

    let _fields = fields;
    delete _fields.NOMGIN

    axios[!params.id ? 'post' : 'put'](`/api/investment-assets/${params.id ? params.id +'/' : ''}?ejercicio=${period}`, {..._fields, CODINM: fields.CODINM})
      .then(res => {
        setLoading(false);
        toast(!params.id ? 'Bien de inversión creada con éxito' : 'Bien de inversión actualizada con éxito', {type: 'success'});
        if(!params.id) navigate(`/investment-assets/${res.data.CODINM}`)
      })
      .catch((err) => {
        err.response.data.forEach((error, key) => {
            setLoading(false);
            toast(error, {type: 'error'});
        })
      })
  }

  const handleDelete = () => {
    setLoading3(true);

    axios.delete(`/api/investment-assets/${params.id}/?ejercicio=${period}`)
      .then(res => {
        setLoading3(false);
        toast('Bien de inversión eliminada con éxito', {type: 'success'});
        navigate(`/investment-assets/`)
      })
  }

  return (
    <>
      <Card>
        <CardBody>
          <div className="d-flex align-items-center">
               {fields !== null ? <CardTitle>{!params.id ? 'Crear bien de inversión' : 'Editar bien de inversión'}</CardTitle> : null} 
               {loading2 || loading4 ? <div className="mb-2 ms-2"><Loading text="Cargando..." /></div> : null}
            </div>
          {fields !== null ?
          <Form onSubmit={handleSubmit} noValidate>
            <Row>
                <Col md="12">
                    <Row>
                        <Col md="3">
                            <FormGroup>
                            <Label htmlFor="CODINM">Nº de registro</Label>
                            <Input 
                                id="CODINM" 
                                type="number" 
                                name="CODINM"    
                                onChange={(e) => handleChange("CODINM", e.target.value)}
                                value={fields["CODINM"]}
                                invalid={errors["CODINM"] ? true : false}
                                readOnly
                            />
                            <FormFeedback>{errors["CODINM"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="9">
                            <FormGroup>
                                <Label htmlFor="ACTINM">Actividad</Label>
                                <Input 
                                    id="ACTINM" 
                                    type="select" 
                                    name="ACTINM"    
                                    onChange={(e) => handleChange("ACTINM", e.target.value)}
                                    value={fields["ACTINM"]}
                                    invalid={errors["ACTINM"] ? true : false}
                                >
                                    <option value="0">[Sin predefinir]</option>
                                    {actividades.map((actividad, key) => {
                                        return <option key={key} value={actividad.CODACT}>{actividad.EPIACT} - {activities[actividad.EPIACT]}</option>
                                    })}
                                </Input>
                                <FormFeedback>{errors["ACTINM"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="NOMINM">Descripción</Label>
                                <Input 
                                    id="NOMINM" 
                                    type="text" 
                                    name="NOMINM"    
                                    maxLength={35}
                                    onChange={(e) => handleChange("NOMINM", e.target.value)}
                                    value={fields["NOMINM"]}
                                    invalid={errors["NOMINM"] ? true : false}
                                />
                                <FormFeedback>{errors["NOMINM"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3">
                            <FormGroup>
                                <Label htmlFor="NS1INM">Número de serie 1</Label>
                                <Input 
                                    id="NS1INM" 
                                    type="text" 
                                    name="NS1INM"    
                                    maxLength={10}
                                    onChange={(e) => handleChange("NS1INM", e.target.value)}
                                    value={fields["NS1INM"]}
                                    invalid={errors["NS1INM"] ? true : false}
                                />
                                <FormFeedback>{errors["NS1INM"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="3">
                            <FormGroup>
                                <Label htmlFor="NS2INM">Número de serie 2</Label>
                                <Input 
                                    id="NS2INM" 
                                    type="text" 
                                    name="NS2INM"    
                                    maxLength={10}
                                    onChange={(e) => handleChange("NS2INM", e.target.value)}
                                    value={fields["NS2INM"]}
                                    invalid={errors["NS2INM"] ? true : false}
                                />
                                <FormFeedback>{errors["NS2INM"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col>
                            <Row>
                                <Col md="12" className="d-flex">
                                    <FormGroup>
                                        <Button color="dark" outline onClick={() => setModalSelectGA(true)} className="me-2" style={{width: 180}}>Grupo amortización</Button>
                                    </FormGroup>
                                    <FormGroup >
                                        <Input 
                                            id="GAMINM" 
                                            type="number" 
                                            name="GAMINM"
                                            maxLength={4}
                                            value={fields["GAMINM"]}
                                            invalid={errors["GAMINM"] ? true : false}
                                            readOnly
                                        />
                                        <FormFeedback>{errors["GAMINM"]}</FormFeedback>
                                    </FormGroup>
                                    <FormGroup className="w-100 ms-2">
                                        <Input 
                                            id="NOMGIN" 
                                            type="text" 
                                            name="NOMGIN"
                                            value={fields["NOMGIN"]}
                                            invalid={errors["NOMGIN"] ? true : false}
                                            readOnly
                                        />
                                        <FormFeedback>{errors["NOMGIN"]}</FormFeedback>
                                    </FormGroup>
                                    {/* <FormGroup className="ms-2" >
                                        <Input 
                                            id="TIPGIN" 
                                            type="number" 
                                            name="TIPGIN"
                                            value={fields["TIPGIN"]}
                                            invalid={errors["TIPGIN"] ? true : false}
                                            readOnly
                                        />
                                        <FormFeedback>{errors["TIPGIN"]}</FormFeedback>
                                    </FormGroup>
                                    <FormGroup className="ms-2" >
                                        <Input 
                                            id="TAMGIN" 
                                            type="number" 
                                            name="TAMGIN"
                                            value={fields["TAMGIN"]}
                                            invalid={errors["TAMGIN"] ? true : false}
                                            readOnly
                                        />
                                        <FormFeedback>{errors["TAMGIN"]}</FormFeedback>
                                    </FormGroup>
                                    <FormGroup className="ms-2" >
                                        <Input 
                                            id="TA2GIN" 
                                            type="number" 
                                            name="TA2GIN"
                                            value={fields["TA2GIN"]}
                                            invalid={errors["TA2GIN"] ? true : false}
                                            readOnly
                                        />
                                        <FormFeedback>{errors["TA2GIN"]}</FormFeedback>
                                    </FormGroup>
                                    <FormGroup className="ms-2 w-50">
                                        <Input 
                                            id="AANGIN" 
                                            type="number" 
                                            name="AANGIN"
                                            value={fields["AANGIN"]}
                                            invalid={errors["AANGIN"] ? true : false}
                                            readOnly
                                        />
                                        <FormFeedback>{errors["AANGIN"]}</FormFeedback>
                                    </FormGroup> */}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="NATINM">Naturaleza</Label>
                                <Input 
                                    id="NATINM" 
                                    type="select" 
                                    name="NATINM"    
                                    onChange={(e) => handleChange("NATINM", e.target.value)}
                                    value={fields["NATINM"]}
                                    invalid={errors["NATINM"] ? true : false}
                                >
                                    <option value="0">Sin especificar</option>
                                    <option value="1">Intangible</option>
                                    <option value="2">Material</option>
                                    <option value="3">Inmobiliaria</option>
                                </Input>
                                <FormFeedback>{errors["NATINM"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        {/* <Col md="6">
                            <FormGroup>
                                <Label htmlFor="FAMINM">Familia <span className="text-danger">{'<= (No encuentro las opciones para poner acá?)'}</span></Label>
                                <Input 
                                    id="FAMINM" 
                                    type="select" 
                                    name="FAMINM"    
                                    maxLength={20}
                                    onChange={(e) => handleChange("FAMINM", e.target.value)}
                                    value={fields["FAMINM"]}
                                    invalid={errors["FAMINM"] ? true : false}
                                >
                                    <option value="">No seleccionada</option>
                                </Input>
                                <FormFeedback>{errors["FAMINM"]}</FormFeedback>
                            </FormGroup>
                        </Col> */}
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="TIPINM">Tipo de bien</Label>
                                <Input 
                                    id="TIPINM" 
                                    type="select" 
                                    name="TIPINM"    
                                    onChange={(e) => handleChange("TIPINM", e.target.value)}
                                    value={fields["TIPINM"]}
                                    invalid={errors["TIPINM"] ? true : false}
                                >
                                    <option value="0">Nuevo</option>
                                    <option value="1">Usado</option>
                                </Input>
                                <FormFeedback>{errors["TIPINM"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="LOCINM">Localización</Label>
                                <Input 
                                    id="LOCINM" 
                                    type="text" 
                                    name="LOCINM"  
                                    maxLength={50}  
                                    onChange={(e) => handleChange("LOCINM", e.target.value)}
                                    value={fields["LOCINM"]}
                                    invalid={errors["LOCINM"] ? true : false}
                                />
                                <FormFeedback>{errors["LOCINM"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <FormGroup>
                                <Label htmlFor="FCOINM">Fecha de compra</Label>
                                <Input 
                                    id="FCOINM" 
                                    type="date" 
                                    name="FCOINM"    
                                    onChange={(e) => handleChange("FCOINM", e.target.value)}
                                    value={fields["FCOINM"] ? moment(fields["FCOINM"]).format('yyyy-MM-DD') : ''}
                                    invalid={errors["FCOINM"] ? true : false}
                                />
                                <FormFeedback>{errors["FCOINM"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label htmlFor="PCOINM">Precio de compra</Label>
                                <NumberFormat 
                                    id="PCOINM" 
                                    type="number" 
                                    name="PCOINM"    
                                    onChange={(e) => handleChange("PCOINM", e.target.value)}
                                    value={fields["PCOINM"]}
                                    invalid={errors["PCOINM"] ? true : false}
                                />
                                <FormFeedback>{errors["PCOINM"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label htmlFor="NFAINM">Nº Factura</Label>
                                <Input 
                                    id="NFAINM" 
                                    type="text" 
                                    name="NFAINM"  
                                    maxLength={30}  
                                    onChange={(e) => handleChange("NFAINM", e.target.value)}
                                    value={fields["NFAINM"]}
                                    invalid={errors["NFAINM"] ? true : false}
                                />
                                <FormFeedback>{errors["NFAINM"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col>
                            <Row>
                                <Col md="12" className="d-flex">
                                    <FormGroup>
                                        <Button color="dark" outline onClick={() => setModalSelectProveedor(true)} className="me-2">Seleccionar proveedor</Button>
                                        <Button color="dark" outline onClick={() => setModalProveedor(true)} className="me-2">Crear proveedor</Button>
                                    </FormGroup>
                                    <FormGroup>
                                        <Input 
                                            id="PROINM" 
                                            type="number" 
                                            name="PROINM"
                                            value={fields["PROINM"]}
                                            invalid={errors["PROINM"] ? true : false}
                                            readOnly
                                        />
                                        <FormFeedback>{errors["PROINM"]}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="OBSINM">Observaciones</Label>
                                <Input 
                                    id="OBSINM" 
                                    type="textarea" 
                                    name="OBSINM"  
                                    maxLength={100}  
                                    onChange={(e) => handleChange("OBSINM", e.target.value)}
                                    value={fields["OBSINM"]}
                                    invalid={errors["OBSINM"] ? true : false}
                                    style={{height: 100}}
                                />
                                <FormFeedback>{errors["OBSINM"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>


            <br />
            <div className="d-flex">
              <Button type="submit" color="primary" disabled={loading || loading2 || loading3 || loading4}>{!params.id ? 'Crear' : 'Editar'} {loading ? <Spinner size="sm" className="ms-1" /> : null}</Button>
              <Button type="button" color="dark" className="ms-2" outline disabled={loading || loading2 || loading3 || loading4} onClick={() => navigate('/investment-assets')}>Cancelar</Button>
              {params.id ? <Button color="danger" type="button" className="ms-auto" outline disabled={loading || loading2 || loading3 || loading4} onClick={() => setModalDelete(true)}>Eliminar{loading3 ? <Spinner size="sm" className="ms-1" /> : null}</Button> : null}
            </div>
          </Form>
          : 
            loading2 ?
                <Loading />  
            :
                <EmptyData
                title={`No existe bien de inversión con código ${params.id}`}
                btn="Crear bien de inversión"
                onClick={() => [setFields({}), navigate('/investment-assets/new')]}
                />
          }
        </CardBody>
      </Card>

      <Modal isOpen={modalDelete} toggle={() => setModalDelete(false)}>
        <ModalHeader toggle={() => setModalDelete(false)}>Eliminar usuario</ModalHeader>
        <ModalBody>
          ¿Está seguro de que desea eliminar la bien de inversión con código{fields?.CODINM}?
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            disabled={loading || loading2 || loading3}
            onClick={() => handleDelete()}
          >
            {loading3 ? <Spinner size="sm" className="me-1"/> : null}
            {!loading3 ? 'Eliminar' : 'Eliminando...'}
          </Button>
          {' '}
          <Button outline disabled={loading || loading2 || loading3} onClick={() => setModalDelete(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="xl" isOpen={modalProveedor} toggle={() => setModalProveedor(false)}>
        <ModalHeader toggle={() => setModalProveedor(false)}>CREAR PROVEEDOR</ModalHeader>
        <SupplierForm 
            period={period} 
            source={source} 
            isModal 
            closeModal={(e) => setModalProveedor(false)} 
            save={setNewSupplier}  
        />
      </Modal>
      
      <Modal size="xl" isOpen={modalSelectProveedor} toggle={() => setModalSelectProveedor(false)}>
        <ModalHeader toggle={() => setModalSelectProveedor(false)}>SELECCIONAR PROVEEDOR</ModalHeader>
        <ModalBody>
            <ListGroup>
                {suppliers.map((customer, key) => {
                    return (
                        <ListGroupItem 
                            key={key} 
                            action 
                            onClick={() => [
                                setFields({
                                    ...fields,
                                    PROINM: customer.CODPRO,
                                }),
                                setModalSelectProveedor(false)
                            ]}
                        >
                            <b>{customer.CODPRO}</b> - {customer.NOMPRO}
                        </ListGroupItem>
                    )
                })}
            </ListGroup>
        </ModalBody>
      </Modal>

      <Modal size="xl" isOpen={modalSelectGA} toggle={() => setModalSelectGA(false)}>
        <ModalHeader toggle={() => setModalSelectGA(false)}>SELECCIONAR GRUPO AMORTIZACIÓN</ModalHeader>
        <ModalBody>
            <ListGroup>
                {amortizationGroup.map((item, key) => {
                    return (
                        <ListGroupItem 
                            key={key} 
                            action 
                            onClick={() => [
                                setFields({
                                    ...fields,
                                    GAMINM: item.id,
                                    NOMGIN: item.description
                                }),
                                setModalSelectGA(false)
                            ]}
                        >
                            <b>{item.id}</b> - {item.description}
                        </ListGroupItem>
                    )
                })}
            </ListGroup>
        </ModalBody>
      </Modal>
    </>
  );
}