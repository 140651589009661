import * as React from 'react';
import { Card, CardBody, CardTitle, CardHeader, Row, Col, Table, Badge, Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loading from '../components/Loading';
import EmptyData from '../components/EmptyData';


export default function Customers({ period, source, customers, setCustomers }) {
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [modalDelete, setModalDelete] = React.useState(null);
  const [modalCopy, setModalCopy] = React.useState(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  React.useEffect(() => {
    getData();
  }, [period])

  const getData = () => {
    setLoading(true);
    axios.get(`/api/customers/?ejercicio=${period}&filtro=CODCLI>=0 ORDER BY CODCLI DESC`, {
      cancelToken: source.token
    })
      .then(res => {
        setCustomers(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setCustomers([]);
          setLoading(false);
        }
      })
  }

  const handleCopy = (e, item) => {
    e.preventDefault();
    setLoading2(true);

    axios.get(`/api/customers/last_id/?ejercicio=${period}`, {
      cancelToken: source.token
    })
      .then(res => {
        let data = {...item, CODCLI: res.data}
        axios.post(`/api/customers/?ejercicio=${period}`, data)
          .then(res => {
            setLoading2(false);
            setModalCopy(null);
            let _customers = [...customers]
            _customers.unshift(data)
            setCustomers(_customers);
            toast('Item copiado con éxito', {type: 'success'});
            navigate(`/customers/${data.CODCLI}?period=${searchParams.get('period')}`)
          })
          .catch((err) => {
            err.response.data.forEach((error, key) => {
                setLoading(false);
                toast(error, {type: 'error'});
            })
          })
      })
      .catch(err => {
        setLoading2(false);
        setModalCopy(null);
        toast(JSON.stringify(err.response.data), {type: 'error'});
      })
  }

  const handleDelete = (e, id) => {
    e.preventDefault();
    setLoading2(true);

    axios.delete(`/api/customers/${id}/?ejercicio=${period}`)
      .then(res => {
        setLoading2(false);
        setModalDelete(null);
        const newData = customers.filter(item => item.CODCLI !== id);
        setCustomers(newData);
        toast('Cliente eliminado con éxito', {type: 'success'});
      })
      .catch(err => {
        setLoading2(false);
        setModalDelete(null);
        toast(JSON.stringify(err.response.data), {type: 'error'});
      })
  }

  return (
    <Card>
      <CardHeader className="border-0 pb-0">
        <Row>
            <Col className="d-flex align-items-center">
              <CardTitle>Clientes</CardTitle>
              { loading && customers ? <div className="mb-2 ms-2"><Loading text="Actualizando..." /></div> : null }
            </Col>
            <Col className="d-flex justify-content-end">
              <div>
                <Button size="sm" color="primary" className="d-flex align-items-center" onClick={() => navigate(`/customers/new?period=${searchParams.get('period')}`)}><span className="material-icons">add</span> Crear cliente</Button>
              </div>
            </Col>
          </Row>
      </CardHeader>
      <CardBody>
        {customers && customers.length >= 0  ?
          customers.length > 0 ?
          <>
            <Table responsive>
              <thead>
                <tr>
                  <th>Código</th>
                  <th>Nombre Fiscal</th>
                  <th>Nombre Comercial</th>
                  <th>Teléfono</th>
                  <th>Móvil</th>
                  <th>N.I.F.</th>
                  <th>Población</th>
                  <th>Provincia</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {customers.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td>{item.CODCLI}</td>
                      <td>{item.NOMCLI}</td>
                      <td>{item.NCOCLI}</td>
                      <td>{item.TELCLI}</td>
                      <td>{item.MOVCLI}</td>
                      <td>{item.CIFCLI}</td>
                      <td>{item.POBCLI}</td>
                      <td>{item.PROCLI}</td>
                      <td className="d-flex">
                        <Button size="sm" outline className="pb-0 me-1" onClick={() => navigate(`/customers/${item.CODCLI}?period=${searchParams.get('period')}`)}><span className="material-icons fs-6">edit</span></Button>
                        <Button size="sm" outline className="pb-0 me-1" onClick={() => setModalCopy(item)}><span className="material-icons fs-6">content_copy</span></Button>
                        <Button size="sm" color="danger" outline className="pb-0 me-1" onClick={() => setModalDelete(item.CODCLI)}><span className="material-icons fs-6">delete</span></Button>
                        
                        <Modal isOpen={modalCopy === item} toggle={() => setModalCopy(null)}>
                          <ModalHeader toggle={() => setModalCopy(null)}>Copiar cliente</ModalHeader>
                          <ModalBody>
                            ¿Está seguro de que desea copiar el cliente {item.NOMCLI}?
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              type="button"
                              color="primary"
                              disabled={loading2}
                              onClick={(e) => handleCopy(e, item)}
                            >
                              {loading2 ? <Spinner size="sm" className="me-1"/> : null}
                              {!loading2 ? 'Copiar' : 'Copiando...'}
                            </Button>
                            {' '}
                            <Button outline disabled={loading2} onClick={() => setModalCopy(null)}>
                              Cancel
                            </Button>
                          </ModalFooter>
                        </Modal>

                        <Modal isOpen={modalDelete === item.CODCLI} toggle={() => setModalDelete(null)}>
                          <ModalHeader toggle={() => setModalDelete(null)}>Eliminar cliente</ModalHeader>
                          <ModalBody>
                            ¿Está seguro de que desea eliminar el cliente {item.NOMCLI}?
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              type="button"
                              color="danger"
                              disabled={loading2}
                              onClick={(e) => handleDelete(e, item.CODCLI)}
                            >
                              {loading2 ? <Spinner size="sm" className="me-1"/> : null}
                              {!loading2 ? 'Eliminar' : 'Eliminando...'}
                            </Button>
                            {' '}
                            <Button outline disabled={loading2} onClick={() => setModalDelete(null)}>
                              Cancel
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            {/* <Pagination data={data} /> */}
          </>
          :
            <EmptyData
              title="No hay clientes registrados"
              btn="Crear cliente"
              onClick={() => navigate(`/customers/new?period=${searchParams.get('period')}`)}
            />
        : 
            <Loading />
        }
      </CardBody>
    </Card>
  );
}