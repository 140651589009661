import * as React from 'react';
import { Form, FormGroup, FormFeedback, Input, Button, Alert, Label, Row, Col, CardLink, CardBody, CardHeader, CardTitle, CardSubtitle, CardFooter, Spinner } from 'reactstrap';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function Login() {
  const [fields, setFields] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [check, setCheck] = React.useState(false);
  const [message, setMessage] = React.useState(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  React.useEffect(() => {
    if (searchParams.get('message')) {
      setMessage({color: searchParams.get('color'), text: searchParams.get('message')})
    }
  }, [])

  const handleChange = (field, value) => {
    setFields({ ...fields, [field]: value });
  }

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    //email
    if (!fields['username']) {
      formIsValid = false;
      errors['username'] = 'Campo obligatorio.';
    }

    if (typeof fields['username'] !== 'undefined') {
      let lastAtPos = fields['username'].lastIndexOf('@');
      let lastDotPos = fields['username'].lastIndexOf('.');

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields['username'].indexOf('@@') == -1 &&
          lastDotPos > 2 &&
          fields['username'].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors['username'] = 'El correo no es válido';
      }
    }

    //password
    if (!fields['password']) {
      formIsValid = false;
      errors['password'] = 'Campo obligatorio.';
    }

    setErrors(errors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      login()
    }
  }

  const login = () => {
    setMessage();
    setLoading(true);
    axios.post('/api/auth/login/', fields)
      .then(res => {
        localStorage.token = res.data.token;
        axios.defaults.headers.common['Authorization'] = 'Token ' + localStorage.token;
        setLoading(false);
        navigate('/dashboard')
      })
      .catch(err => {
        setLoading(false);
        setMessage({color: 'danger', text: err.response.data.detail});
      })
  }

  return (
    <>
      <CardHeader className="border-0 pb-0" style={{background: 'none'}}>
        <CardTitle className="text-center">
          <div className="p-3">
            <img src="logo-gestapymes.png" style={{width: 120}} />
          </div>
        </CardTitle>
        <CardSubtitle className="line-on-side text-muted text-center pt-2 fw-light">
          <span><small>Iniciar sesión</small></span>
        </CardSubtitle>
      </CardHeader>
      <CardBody>
        {message ? <Alert color={message.color}>{message.text}</Alert> : null}
        <Form onSubmit={handleSubmit} noValidate>
          <FormGroup floating>
            <Input 
              id="username" 
              type="email" 
              name="username"   
              placeholder="Correo" 
              onChange={(e) => handleChange("username", e.target.value)}
              value={fields["username"]}
              invalid={errors["username"] ? true : false}
            />
            <Label htmlFor="username">Correo</Label>
            <FormFeedback>{errors["username"]}</FormFeedback>
          </FormGroup>
          <FormGroup floating>
            <Input 
              id="password" 
              type="password" 
              name="password" 
              placeholder="Contraseña" 
              onChange={(e) => handleChange("password", e.target.value)}
              value={fields["password"]}
              invalid={errors["password"] ? true : false}
            />
            <Label htmlFor="password">Contraseña</Label>
            <FormFeedback>{errors["password"]}</FormFeedback>
          </FormGroup>
          <Row className="mb-3">
            <Col>
              <div className="form-check">
                  <input className="form-check-input form-check-input-primary" type="checkbox" id="remember" name="remember" onChange={() => setCheck(!check)} />
                  <label className="form-check-label" htmlFor="remember">&nbsp;Recuérdame</label>
              </div>
            </Col>
            <Col className="d-flex align-items-center justify-content-end">
              <CardLink href="https://api.gestapymes.com/reset_password/">¿Te olvidaste tu contraseña?</CardLink>
            </Col>
          </Row>
          <div className="d-grid">
            <Button type="submit" color="primary" block className="d-flex justify-content-center align-items-center" style={{paddingTop: '0.75rem', paddingBottom: '0.75rem'}}>
              <span className="material-icons"> lock_open </span>Entrar
              {loading ? <Spinner size="sm" className="ms-1" /> : null}
            </Button>
          </div>
        </Form>
      </CardBody>
      {/* <CardFooter className="text-center">
        ¿No tienes una cuenta?
        <CardLink href="#"> Regístrese</CardLink>
      </CardFooter> */}
    </>
  );
}