import axios from 'axios';
import * as React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import Loading from '../components/Loading';
import EmptyData from '../components/EmptyData';
import { isEmpty, validEmail, validURL } from '../lib/validator';
import countries from '../global/countries';
import activities from '../global/activities';
import clienteClaveOperacionHabitual from '../global/cliente_clave_operacion_habitual';
import NumberFormat from '../components/NumberFormat';

const initialValues = {
    ACTCLI: '0',
    TRECLI: '0',
    COPCLI: '0',
    IVACLI: '0.0',
    RECCLI: '0.0',
    TPICLI: '1',
    CBACLI: '',
    IFICLI: '0',
}

export default function CustomerForm({ period, source, isModal, closeModal, save }) {
  const [fields, setFields] = React.useState(initialValues);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [loading3, setLoading3] = React.useState(false);
  const [loading4, setLoading4] = React.useState(false);
  const [modalDelete, setModalDelete] = React.useState(false);
  const [tab, setTab] = React.useState('1');
  const [actividades, setActividades] = React.useState([]);
  const [tiposDeIngresos, setTiposDeIngresos] = React.useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const params = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    getData();
  }, [period])

  const getData = () => {
    axios.get(`/api/customers/actividades/?ejercicio=${period}&filtro=CODACT>=0`, {
        cancelToken: source.token
      })
        .then(res => {
            setActividades(res.data);
        })

    axios.get(`/api/suppliers/tipos_de_gastos/?ejercicio=${period}&filtro=LIBTGI=1 ORDER BY CODTGI`, {
        cancelToken: source.token
        })
        .then(res => {
            setTiposDeIngresos(res.data);
        })

    if(params.id && !isModal) {
        setLoading2(true);
        axios.get(`/api/customers/${params.id ? params.id +'/' : ''}?ejercicio=${period}&filtro=CODCLI=${params.id}`, {
            cancelToken: source.token
          })
          .then(res => {
            setLoading2(false);
            setFields(res.data.CODCLI ? res.data : null);
          })
    } else {
        setFields({ ...fields });
        setLoading4(true)
        axios.get(`/api/customers/last_id/?ejercicio=${period}`, {
            cancelToken: source.token
          })
            .then(res => {
                setLoading4(false)
                setFields({...fields, CODCLI: res.data});
            })
    }
  }

  const handleChange = (field, value) => {
    setFields({ ...fields, [field]: value });
  }

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (isEmpty(fields['CODCLI'])) {
        formIsValid = false;
        errors['CODCLI'] = 'Campo obligatorio.';
    }

    if (isEmpty(fields['NOMCLI'])) {
        formIsValid = false;
        errors['NOMCLI'] = 'Campo obligatorio.';
    }

    if (!isEmpty(fields['EMACLI']) && !validEmail(fields['EMACLI'])) {
        formIsValid = false;
        errors['EMACLI'] = 'Correo electrónico con formato incorrecto.';
    }

    if(!isEmpty(fields['WEBCLI']) && !validURL(fields['WEBCLI'])) {
        formIsValid = false;
        errors['WEBCLI'] = 'URL con formato no válido.';
    }

    if (isEmpty(fields['TIPCLI'])) {
        formIsValid = false;
        errors['TIPCLI'] = 'Campo obligatorio.';
    }

    setErrors(errors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      setCustomer()
    }
  }

  const setCustomer = () => {
    setLoading(true);

    axios[!params.id || isModal ? 'post' : 'put'](`/api/customers/${params.id && !isModal ? params.id +'/' : ''}?ejercicio=${period}`, {...fields, CODCLI: fields['CODCLI']})
      .then(res => {
        setLoading(false);
        toast(!params.id ? 'Cliente creado con éxito' : 'Cliente actualizado con éxito', {type: 'success'});
        if(!params.id && !isModal) navigate(`/customers/${res.data.CODCLI}`)
        if(isModal) {
            save(fields);
            closeModal(false);
        }
      })
      .catch((err) => {
        err.response.data.forEach((error, key) => {
            setLoading(false);
            toast(error, {type: 'error'});
        })
      })
  }

  const handleDelete = () => {
    setLoading3(true);

    axios.delete(`/api/customers/${params.id}/?ejercicio=${period}`)
      .then(res => {
        setLoading3(false);
        toast('Cliente eliminado con éxito', {type: 'success'});
        navigate(`/customers/`)
      })
  }

  return (
    <>
      <Card style={{margin: isModal ? 0 : null}}>
        <CardBody>
            <div className="d-flex align-items-center">
               {fields !== null && !isModal ? <CardTitle>{!params.id ? 'Crear cliente' : 'Editar cliente'}</CardTitle> : null} 
               {loading2 || loading4 ? <div className="mb-2 ms-2"><Loading text="Cargando..." /></div> : null}
            </div>
          
          {fields !== null ?
          <Form onSubmit={handleSubmit} noValidate>
            <Row>
              <Col md="3">
                <FormGroup>
                  <Label htmlFor="CODCLI">Código</Label>
                  <Input 
                    id="CODCLI" 
                    type="number" 
                    name="CODCLI"    
                    onChange={(e) => handleChange("CODCLI", e.target.value)}
                    value={fields["CODCLI"]}
                    invalid={errors["CODCLI"] ? true : false}
                    readOnly
                  />
                  <FormFeedback>{errors["CODCLI"]}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                    <Label htmlFor="IFICLI">Tipo de identificación fiscal</Label>
                    <Input 
                        id="IFICLI" 
                        type="select" 
                        name="IFICLI"    
                        onChange={(e) => handleChange("IFICLI", e.target.value)}
                        value={fields["IFICLI"]}
                        invalid={errors["IFICLI"] ? true : false}
                    >
                        <option value="0">N.I.F.</option>
                        <option value="1">NIF/IVA (NIF operador intracomunitario</option>
                        <option value="2">Pasaporte</option>
                        <option value="3">Documento Oficial de identificación expedido por el país o territorio de residencia </option>
                        <option value="4">Certificado de residencia fiscal</option>
                        <option value="5">Otro documento probatorio.</option>
                    </Input>
                    <FormFeedback>{errors["IFICLI"]}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                    <Label htmlFor="CIFCLI">N.I.F.</Label>
                    <Input 
                        id="CIFCLI" 
                        type="text" 
                        name="CIFCLI"   
                        maxLength={18} 
                        onChange={(e) => handleChange("CIFCLI", e.target.value)}
                        value={fields["CIFCLI"]}
                        invalid={errors["CIFCLI"] ? true : false}
                    />
                  <FormFeedback>{errors["CIFCLI"]}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="NOMCLI">Nombre fiscal</Label>
                  <Input 
                    id="NOMCLI" 
                    type="text" 
                    name="NOMCLI"    
                    maxLength={100}
                    onChange={(e) => {
                        setFields({ 
                            ...fields,
                            NOMCLI: e.target.value,
                            NCOCLI: e.target.value
                        })
                    }}
                    value={fields["NOMCLI"]}
                    invalid={errors["NOMCLI"] ? true : false}
                  />
                  <FormFeedback>{errors["NOMCLI"]}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                    <Label htmlFor="NCOCLI">Nombre comercial</Label>
                    <Input 
                        id="NCOCLI" 
                        type="text" 
                        name="NCOCLI"   
                        maxLength={100} 
                        onChange={(e) => handleChange("NCOCLI", e.target.value)}
                        value={fields["NCOCLI"]}
                        invalid={errors["NCOCLI"] ? true : false}
                    />
                  <FormFeedback>{errors["NCOCLI"]}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            <Row>
            <Nav tabs className="mt-3">
                <NavItem>
                    <NavLink className={`${tab === '1' ? 'active' : ''}`} onClick={() => setTab('1')}>Contact</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={`${tab === '2' ? 'active' : ''}`} onClick={() => setTab('2')}>Parámetros</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={`${tab === '3' ? 'active' : ''}`} onClick={() => setTab('3')}>Observaciones</NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={tab} className="p-3">
                <TabPane tabId="1">
                    <Row>
                        <Col md="2">
                            <FormGroup>
                                <Label htmlFor="SIGCLI">Siglas del domicilio</Label>
                                <Input 
                                    id="SIGCLI" 
                                    type="text" 
                                    name="SIGCLI"    
                                    onChange={(e) => handleChange("SIGCLI", e.target.value)}
                                    value={fields["SIGCLI"]}
                                    invalid={errors["SIGCLI"] ? true : false}
                                    maxLength={2}
                                />
                                <FormFeedback>{errors["SIGCLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="8">
                            <FormGroup>
                                <Label htmlFor="DOMCLI">Domicilio</Label>
                                <Input 
                                    id="DOMCLI" 
                                    type="text" 
                                    name="DOMCLI"   
                                    maxLength={100} 
                                    onChange={(e) => handleChange("DOMCLI", e.target.value)}
                                    value={fields["DOMCLI"]}
                                    invalid={errors["DOMCLI"] ? true : false}
                                />
                                <FormFeedback>{errors["DOMCLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="2">
                            <FormGroup>
                                <Label htmlFor="NUMCLI">Número</Label>
                                <Input 
                                    id="NUMCLI" 
                                    type="number" 
                                    name="NUMCLI"    
                                    maxLength={6}
                                    onChange={(e) => handleChange("NUMCLI", e.target.value)}
                                    value={fields["NUMCLI"]}
                                    invalid={errors["NUMCLI"] ? true : false}
                                />
                                <FormFeedback>{errors["NUMCLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="2">
                            <FormGroup>
                                <Label htmlFor="CPOCLI">Código Postal</Label>
                                <Input 
                                    id="CPOCLI" 
                                    type="text" 
                                    name="CPOCLI"    
                                    maxLength={15}
                                    onChange={(e) => handleChange("CPOCLI", e.target.value)}
                                    value={fields["CPOCLI"]}
                                    invalid={errors["CPOCLI"] ? true : false}
                                />
                                <FormFeedback>{errors["CPOCLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="10">
                            <FormGroup>
                                <Label htmlFor="POBCLI">Población</Label>
                                <Input 
                                    id="POBCLI" 
                                    type="text" 
                                    name="POBCLI"    
                                    maxLength={30}
                                    onChange={(e) => handleChange("POBCLI", e.target.value)}
                                    value={fields["POBCLI"]}
                                    invalid={errors["POBCLI"] ? true : false}
                                />
                                <FormFeedback>{errors["POBCLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="5">
                            <FormGroup>
                                <Label htmlFor="PROCLI">Província</Label>
                                <Input 
                                    id="PROCLI" 
                                    type="text" 
                                    name="PROCLI"   
                                    maxLength={20} 
                                    onChange={(e) => handleChange("PROCLI", e.target.value)}
                                    value={fields["PROCLI"]}
                                    invalid={errors["PROCLI"] ? true : false}
                                />
                                <FormFeedback>{errors["PROCLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="5">
                            <FormGroup>
                                <Label htmlFor="PAICLI">País</Label>
                                <Input 
                                    id="PAICLI" 
                                    type="select" 
                                    name="PAICLI"    
                                    maxLength={3}
                                    onChange={(e) => [handleChange("PAICLI", e.target.value)]}
                                    value={fields["PAICLI"]}
                                    invalid={errors["PAICLI"] ? true : false}
                                >
                                    <option value=""></option>
                                    {countries.map((country, index) => {
                                        return <option key={index} value={country.code}>{country.name}</option>
                                    })}
                                </Input>
                                <FormFeedback>{errors["PAICLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="2">
                            <FormGroup>
                                <Label htmlFor="country">Código del país</Label>
                                <Input 
                                    id="country"
                                    type="text" 
                                    value={fields["PAICLI"]}
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="TELCLI">Teléfono</Label>
                                <Input 
                                    id="TELCLI" 
                                    type="text" 
                                    name="TELCLI"  
                                    maxLength={12}  
                                    onChange={(e) => handleChange("TELCLI", e.target.value)}
                                    value={fields["TELCLI"]}
                                    invalid={errors["TELCLI"] ? true : false}
                                />
                                <FormFeedback>{errors["TELCLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="MOVCLI">Móvil</Label>
                                <Input 
                                    id="MOVCLI" 
                                    type="text" 
                                    name="MOVCLI"   
                                    maxLength={12} 
                                    onChange={(e) => handleChange("MOVCLI", e.target.value)}
                                    value={fields["MOVCLI"]}
                                    invalid={errors["MOVCLI"] ? true : false}
                                />
                                <FormFeedback>{errors["MOVCLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="BANCLI">Banco</Label>
                                <Input 
                                    id="BANCLI" 
                                    type="text" 
                                    name="BANCLI"  
                                    maxLength={50}  
                                    onChange={(e) => handleChange("BANCLI", e.target.value)}
                                    value={fields["BANCLI"]}
                                    invalid={errors["BANCLI"] ? true : false}
                                />
                                <FormFeedback>{errors["BANCLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="ENTCLI">Número de cuenta</Label>
                                <Row>
                                    <Col md="3">
                                        <Input 
                                            id="ENTCLI" 
                                            type="text"
                                            maxLength={4}
                                            name="ENTCLI"    
                                            onChange={(e) => handleChange("ENTCLI", e.target.value.replace(/[^0-9.]/g, ''))}
                                            value={fields["ENTCLI"]}
                                            invalid={errors["ENTCLI"] ? true : false}
                                        />
                                    </Col>
                                    <Col md="3">
                                        <Input 
                                            id="OFICLI" 
                                            type="text"
                                            maxLength={4}
                                            name="OFICLI"    
                                            onChange={(e) => handleChange("OFICLI", e.target.value.replace(/[^0-9.]/g, ''))}
                                            value={fields["OFICLI"]}
                                            invalid={errors["OFICLI"] ? true : false}
                                        />
                                    </Col>
                                    <Col md="2">
                                        <Input 
                                            id="DCOCLI" 
                                            type="text"
                                            maxLength={2} 
                                            name="DCOCLI"    
                                            onChange={(e) => handleChange("DCOCLI", e.target.value.replace(/[^0-9.]/g, ''))}
                                            value={fields["DCOCLI"]}
                                            invalid={errors["DCOCLI"] ? true : false}
                                        />
                                    </Col>
                                    <Col md="4">
                                        <Input 
                                            id="CTACLI" 
                                            type="text"
                                            maxLength={10}
                                            name="CTACLI"    
                                            onChange={(e) => handleChange("CTACLI", e.target.value.replace(/[^0-9.]/g, ''))}
                                            value={fields["CTACLI"]}
                                            invalid={errors["CTACLI"] ? true : false}
                                        />
                                    </Col>
                                </Row>
                                <FormFeedback>
                                    {
                                        errors["ENTCLI"] 
                                            ? errors["ENTCLI"] 
                                            : errors["OFICLI"]
                                                ? errors["OFICLI"]
                                                : errors["DCOCLI"]
                                                    ? errors["DCOCLI"]
                                                    : errors["CTACLI"]
                                                        ? errors["CTACLI"]
                                                        : null
                                    }
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="IBACLI">IBAN</Label>
                                <Input 
                                    id="IBACLI" 
                                    type="text" 
                                    name="IBACLI"  
                                    maxLength={50}  
                                    onChange={(e) => handleChange("IBACLI", e.target.value)}
                                    value={fields["IBACLI"]}
                                    invalid={errors["IBACLI"] ? true : false}
                                />
                                <FormFeedback>{errors["IBACLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col md="12">
                            <FormGroup>
                                <Input
                                    type="checkbox" 
                                    id="MUTCLI" 
                                    name="MUTCLI"
                                    checked={fields["MUTCLI"]}
                                    onClick={(e) => handleChange("MUTCLI", e.target.checked)}
                                    invalid={errors["MUTCLI"] ? true : false}
                                />
                                    <Label className="form-check-label" htmlFor="MUTCLI">Utilizar el siguiente mandato para las domiciliaciones</Label>
                                <FormFeedback>{errors["MUTCLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="MRECLI">Referencia única</Label>
                                <Input 
                                    id="MRECLI" 
                                    type="text" 
                                    name="MRECLI"   
                                    maxLength={35} 
                                    onChange={(e) => handleChange("MRECLI", e.target.value)}
                                    value={fields["MRECLI"]}
                                    invalid={errors["MRECLI"] ? true : false}
                                    disabled={fields['MUTCLI'] ? false : true}
                                />
                                <FormFeedback>{errors["MRECLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="MFECLI">Fecha de firma</Label>
                                <Input 
                                    id="MFECLI" 
                                    type="date" 
                                    name="MFECLI"    
                                    onChange={(e) => handleChange("MFECLI", e.target.value)}
                                    value={fields["MFECLI"] ? moment(fields["MFECLI"]).format('yyyy-MM-DD') : ''}
                                    invalid={errors["MFECLI"] ? true : false}
                                    disabled={fields['MUTCLI'] ? false : true}
                                >
                                    <option value=""></option>
                                </Input>
                                <FormFeedback>{errors["MFECLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="FPACLI">Forma de pago</Label>
                                <Input 
                                    id="FPACLI" 
                                    type="text" 
                                    name="FPACLI"    
                                    maxLength={50}
                                    onChange={(e) => handleChange("FPACLI", e.target.value)}
                                    value={fields["FPACLI"]}
                                    invalid={errors["FPACLI"] ? true : false}
                                />
                                <FormFeedback>{errors["FPACLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="PCOCLI">Persona de Contacto</Label>
                                <Input 
                                    id="PCOCLI" 
                                    type="text" 
                                    name="PCOCLI"    
                                    maxLength={50}
                                    onChange={(e) => handleChange("PCOCLI", e.target.value)}
                                    value={fields["PCOCLI"]}
                                    invalid={errors["PCOCLI"] ? true : false}
                                />
                                <FormFeedback>{errors["PCOCLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="EMACLI">Correo electrónico</Label>
                                <Input 
                                    id="EMACLI" 
                                    type="text" 
                                    name="EMACLI"    
                                    maxLength={50}
                                    onChange={(e) => handleChange("EMACLI", e.target.value)}
                                    value={fields["EMACLI"]}
                                    invalid={errors["EMACLI"] ? true : false}
                                />
                                <FormFeedback>{errors["EMACLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="WEBCLI">Web</Label>
                                <Input 
                                    id="WEBCLI" 
                                    type="text" 
                                    name="WEBCLI"    
                                    maxLength={50}
                                    onChange={(e) => handleChange("WEBCLI", e.target.value)}
                                    value={fields["WEBCLI"]}
                                    invalid={errors["WEBCLI"] ? true : false}
                                />
                                <FormFeedback>{errors["WEBCLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="MEMCLI">Mensaje emergente</Label>
                                <Input 
                                    id="MEMCLI" 
                                    type="text" 
                                    name="MEMCLI"   
                                    maxLength={50} 
                                    onChange={(e) => handleChange("MEMCLI", e.target.value)}
                                    value={fields["MEMCLI"]}
                                    invalid={errors["MEMCLI"] ? true : false}
                                />
                                <FormFeedback>{errors["MEMCLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="ACTCLI">Actividad</Label>
                                <Input 
                                    id="ACTCLI" 
                                    type="select" 
                                    name="ACTCLI"    
                                    onChange={(e) => handleChange("ACTCLI", e.target.value)}
                                    value={fields["ACTCLI"]}
                                    invalid={errors["ACTCLI"] ? true : false}
                                >
                                    <option value="0">[Sin predefinir]</option>
                                    {actividades.map((actividad, key) => {
                                        return <option key={key} value={actividad.CODACT}>{actividad.EPIACT} - {activities[actividad.EPIACT]}</option>
                                    })}
                                </Input>
                                <FormFeedback>{errors["ACTCLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="TIPCLI">Tipo de operaciones</Label>
                                <Input 
                                    id="TIPCLI" 
                                    type="select" 
                                    name="TIPCLI"    
                                    onChange={(e) => handleChange("TIPCLI", e.target.value)}
                                    value={fields["TIPCLI"]}
                                    invalid={errors["TIPCLI"] ? true : false}
                                >
                                    <option value="">Seleccionar</option>
                                    <option value="0">Interior</option>
                                    <option value="1">Interior exento</option>
                                    <option value="2">Exterior</option>
                                    <option value="3">Intracomunitario</option>
                                </Input>
                                <FormFeedback>{errors["TIPCLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="TGICLI">Tipo de ingreso</Label>
                                <Input 
                                    id="TGICLI" 
                                    type="select" 
                                    name="TGICLI"   
                                    maxLength={3} 
                                    onChange={(e) => handleChange("TGICLI", e.target.value)}
                                    value={fields["TGICLI"]}
                                    invalid={errors["TGICLI"] ? true : false}
                                >
                                    <option value="">Seleccionar</option>
                                    {tiposDeIngresos.map((tipoDeIngreso, key) => {
                                        return <option key={key} value={tipoDeIngreso.CODTGI}>{tipoDeIngreso.DENTGI}</option>
                                    })}
                                </Input>
                                <FormFeedback>{errors["TGICLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="TPICLI">Tipo de IVA predefinido</Label>
                                <Input 
                                    id="TPICLI"
                                    type="select" 
                                    name="TPICLI"    
                                    onChange={(e) => handleChange("TPICLI", e.target.value)}
                                    value={fields["TPICLI"]}
                                    invalid={errors["TPICLI"] ? true : false}
                                >
                                    <option value="">Seleccionar</option>
                                    <option value="0">Manual</option>
                                    <option value="1">Normal</option>
                                    <option value="2">Reducido</option>
                                    <option value="3">Súper reducido</option>
                                    <option value="4">Normal + Recargo de Equivalencia</option>
                                    <option value="5">Reducido + Recargo de Equivalencia</option>
                                    <option value="6">Súper reducido + Recargo de Equivalencia</option>
                                </Input>
                                <FormFeedback>{errors["TPICLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>

                    {fields.TIPCLI === '0' || fields.TIPCLI === '1' ?
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Input
                                    type="checkbox" 
                                    id="RCCCLI" 
                                    name="RCCCLI"
                                    defaultChecked={fields["RCCCLI"]}
                                    onClick={(e) => handleChange("RCCCLI", e.target.checked)}
                                    invalid={errors["RCCCLI"] ? true : false}
                                />
                                    <Label className="form-check-label" htmlFor="RCCCLI">Admite facturas acogidas al Régimen especial del criterio de caja</Label>
                                <FormFeedback>{errors["RCCCLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    : null}

                    <Row>
                        <Col md="9">
                            <FormGroup>
                                <Label htmlFor="TRECLI">Tipo de retenciones</Label>
                                <Input 
                                    id="TRECLI" 
                                    type="select" 
                                    name="TRECLI"    
                                    onChange={(e) => {
                                        setFields({ 
                                            ...fields,
                                            TRECLI: e.target.value,
                                            RETCLI: e.target.value === '1' ? 15.00 : (e.target.value === '5' ? 19.00 : 0)
                                        })
                                    }}
                                    value={fields["TRECLI"]}
                                    invalid={errors["TRECLI"] ? true : false}
                                >
                                    <option value="0">[Sin predefinir]</option>
                                    <option value="1">Actividad profesional (dineraria) Retención 15% y 7%</option>
                                    <option value="2">Actividad profesional (en espécie)</option>
                                    <option value="3">Actividad agrícola (dineraria)</option>
                                    <option value="4">Actividad agrícola (en espécie)</option>
                                    <option value="5">Arrendamiento (dinerario) Retención 19%</option>
                                    <option value="6">Arrendamiento (en espécie)</option>
                                    <option value="7">Actividad empresarial</option>
                                </Input>
                                <FormFeedback>{errors["TRECLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="3">
                            <FormGroup>
                                <Label htmlFor="RETCLI">% Retención</Label>
                                <NumberFormat 
                                    id="RETCLI" 
                                    type="number" 
                                    name="RETCLI"    
                                    onChange={(e) => handleChange("RETCLI", e.target.value)}
                                    value={fields["RETCLI"]}
                                    invalid={errors["RETCLI"] ? true : false}
                                />
                            <FormFeedback>{errors["RETCLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="COPCLI">Clave de operación habitual</Label>
                                <Input 
                                    id="COPCLI" 
                                    type="select" 
                                    name="COPCLI"    
                                    onChange={(e) => handleChange("COPCLI", e.target.value)}
                                    value={fields["COPCLI"]}
                                    invalid={errors["COPCLI"] ? true : false}
                                >
                                    {/* <option value="">Seleccionar</option> */}
                                    {clienteClaveOperacionHabitual.map((claveOperacion, key) => {
                                        return <option value={key}>{claveOperacion}</option>
                                    })}
                                </Input>
                                <FormFeedback>{errors["COPCLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="ACPCLI">Ayuda en el cálculo</Label>
                                <Input 
                                    id="ACPCLI" 
                                    type="select" 
                                    name="ACPCLI"    
                                    onChange={(e) => handleChange("ACPCLI", e.target.value)}
                                    value={fields["ACPCLI"]}
                                    invalid={errors["ACPCLI"] ? true : false}
                                >
                                    <option value="">Seleccionar</option>
                                    <option value="0">Manual</option>
                                    <option value="1">Un tipo de IVA</option>
                                    <option value="2">Varios tipos de IVA</option>
                                    <option value="3">Exenta de IVA</option>
                                    <option value="4">Parte exenta, con un tipo de IVA</option>
                                    <option value="5">Parte exenta, con varios tipos de IVA</option>
                                    <option value="6">Un tipo de IVA con I.R.P.F.</option>
                                    <option value="7">Varios tipos de IVA con I.R.P.F.</option>
                                    <option value="8">Exenta de IVA con I.R.P.F.</option>
                                    <option value="9">Parte exenta, con un tipo de IVA con I.R.P.F.</option>
                                    <option value="10">Parte exenta, con varios tipos de IVA con I.R.P.F.</option>
                                </Input>
                                <FormFeedback>{errors["ACPCLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="A1KCLI">Actividad para Código 1kB <span className="text-danger">{'<= (Podes pasarme las opciones para el campo?)'}</span></Label>
                                <Input 
                                    id="A1KCLI" 
                                    type="select" 
                                    name="A1KCLI"    
                                    onChange={(e) => handleChange("A1KCLI", e.target.value)}
                                    value={fields["A1KCLI"]}
                                    invalid={errors["A1KCLI"] ? true : false}
                                >
                                    <option value="">Sin seleccionar</option>
                                </Input>
                                <FormFeedback>{errors["A1KCLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row> */}
                    {fields.TIPCLI === '3' ?
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="CINCLI">Clave de operación intracomunitaria</Label>
                                <Input 
                                    id="CINCLI" 
                                    type="select" 
                                    name="CINCLI"    
                                    onChange={(e) => handleChange("CINCLI", e.target.value)}
                                    value={fields["CINCLI"]}
                                    invalid={errors["CINCLI"] ? true : false}
                                >
                                    <option value="0">[Sin predefinir]</option>
                                    <option value="1">Adquisiciones intracomunitarias sujetas</option>
                                    <option value="2">Entregas intracomunitarias exentas, excepto las entregas en otros Estados miembros subsiguientes a adquisiciones intracomunitarias exentas en el marco de operaciones triangulares, que se consignará «T», y las entregas intracomunitarias de bienes posteriores a una importación exenta, que se consignarán con las claves «M» o «H» según corresponda.</option>
                                    <option value="3">Entregas intracomunitarias de bienes posteriores a una importación exenta, si son efectuadas por el representante fiscal.</option>
                                    <option value="4">Adquisiciones intracomunitarias de servicios localizadas en el TAI prestadas por empresarios y profesionales establecidos en otros Estados miembros cuyo destinatario es el declarante.</option>
                                    <option value="5">Entregas intracomunitarias de bienes posteriores a una importación exenta.</option>
                                    <option value="6">Prestaciones intracomunitarias de servicios realizadas por el declarante.</option>
                                    <option value="7">Entrega intracomunitaria de bienes posteriores a una importación exenta, de acuerdo con el artículo 27.12o de la Ley del Impuesto sobre el Valor Añadido.</option>
                                    <option value="8">Sustituciones del empresario o profesional destinatario de los bienes expedidos o transportados a otro Estado miembro en el marco de acuerdos de bienes en consigna.</option>
                                    <option value="9">Devolución de bienes desde otro Estado miembro al que previamente fueron enviados desde el TAI en el marco de acuerdos de venta de bienes en consigna.</option>

                                    {/* <option value="1">A - Adquisiciones intracomunitarias sujetas</option>
                                    <option value="2">E - Entregas intracomunitarias exentas,
                                    excepto las entregas en otros Estados
                                    miembros subsiguientes a adquisiciones
                                    intracomunitarias exentas en el marco de
                                    operaciones triangulares, que se
                                    consignará 'T', y las entregas
                                    intracomunitarias de bienes posteriores a
                                    una importación exenta, que se
                                    consignarán con las claves 'M' o 'H' según
                                    corresponda</option>
                                    <option value="3">H - Entregas intracomunitarias de
                                    bienes posteriores a una importación
                                    exenta, de acuerdo con el artículo 27.12o
                                    de la Ley del Impuesto sobre el Valor
                                    Añadido, efectuadas por el representante
                                    fiscal según lo previsto en el artículo
                                    86.Tres de la Ley del Impuesto</option>
                                    <option value="4">= I - Adquisiciones intracomunitarias de
                                    servicios localizadas en el TAI prestadas por
                                    empresarios o profesionales establecidos
                                    en otros EM cuyo destinatario es el
                                    declarante</option>
                                    <option value="5">M - Entregas intracomunitarias de
                                    bienes posteriores a una importación
                                    exenta, de acuerdo con el artículo 27.12o
                                    de la Ley del Impuesto sobre el Valor
                                    Añadido</option>
                                    <option value="6">S - Prestaciones intracomunitarias de servicios realizadas por el declarante</option>
                                    <option value="7">T - Entregas en otros Estados miembros
                                    subsiguientes a adquisiciones
                                    intracomunitarias exentas en el marco de
                                    operaciones triangulares. Cuando se realice
                                    alguna entrega de bienes de las
                                    mencionadas en el artículo 79, apartado
                                    dos del Reglamento del IVA. Estas
                                    operaciones, cuando sean efectuadas para
                                    un destinatario para el cual se hayan
                                    realizado entregas intracomunitarias, se
                                    consignarán en un registro independiente
                                    de aquél en que se hayan consignado estas
                                    últimas operaciones</option> */}
                                </Input>
                                <FormFeedback>{errors["CINCLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    : null}
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="DESCLI">Descripción operación SII</Label>
                                <Input 
                                    id="DESCLI" 
                                    type="textarea" 
                                    name="DESCLI"    
                                    onChange={(e) => handleChange("DESCLI", e.target.value)}
                                    value={fields["DESCLI"]}
                                    invalid={errors["DESCLI"] ? true : false}
                                    style={{height: 100}}
                                />
                                <FormFeedback>{errors["DESCLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="3">
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Input 
                                    id="OBSCLI" 
                                    type="textarea" 
                                    name="OBSCLI"    
                                    onChange={(e) => handleChange("OBSCLI", e.target.value)}
                                    value={fields["OBSCLI"]}
                                    invalid={errors["OBSCLI"] ? true : false}
                                    style={{height: 300}}
                                />
                                <FormFeedback>{errors["OBSCLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </TabPane>
            </TabContent> 
            </Row>           

            <br />
            <div className="d-flex">
              <Button type="submit" color="primary" disabled={loading || loading2 || loading3 || loading4}>{!params.id || (params.id && isModal) ? 'Crear' : 'Editar'} {loading ? <Spinner size="sm" className="ms-1" /> : null}</Button>
              <Button type="button" color="dark" className="ms-2" outline disabled={loading || loading2 || loading3 || loading4} onClick={() => !isModal ? navigate('/customers') : closeModal(false)}>Cancelar</Button>
              {params.id ? <Button color="danger" type="button" className="ms-auto" outline disabled={loading || loading2 || loading3 || loading4} onClick={() => setModalDelete(true)}>Eliminar{loading3 ? <Spinner size="sm" className="ms-1" /> : null}</Button> : null}
            </div>
          </Form>
          : 
            loading2 ?
                <Loading />  
            :
                <EmptyData
                title={`No existe cliente con código ${params.id}`}
                btn="Crear cliente"
                onClick={() => [setFields({}), navigate('/customers/new')]}
                />
          }
        </CardBody>
      </Card>

      <Modal isOpen={modalDelete} toggle={() => setModalDelete(false)}>
        <ModalHeader toggle={() => setModalDelete(false)}>Eliminar usuario</ModalHeader>
        <ModalBody>
          ¿Está seguro de que desea eliminar el cliente {fields?.NOMCLI}?
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            disabled={loading || loading2 || loading3}
            onClick={() => handleDelete()}
          >
            {loading3 ? <Spinner size="sm" className="me-1"/> : null}
            {!loading3 ? 'Eliminar' : 'Eliminando...'}
          </Button>
          {' '}
          <Button outline disabled={loading || loading2 || loading3} onClick={() => setModalDelete(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}