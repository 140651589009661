import * as React from 'react';
import { Card, CardBody, CardTitle, Alert, Row, Col, Form, FormGroup, FormFeedback, Input, Label, Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

export default function UserForm(props) {
  const [fields, setFields] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [loading3, setLoading3] = React.useState(false);
  const [modalDelete, setModalDelete] = React.useState(false);

  const inputRef = React.useRef(null);

  const params = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    if(params.id) {
        setLoading2(true);
        axios.get(`/api/users/${params.id}/`)
          .then(res => {
            setLoading2(false);
            setFields(res.data);
          })
          .catch(err => {
            setLoading2(false);
            toast(err.response.data.detail, {type: 'error'});
          })
    } else {
        setFields({ ...fields, is_active: true });
    }
  }, [])

  const handleChange = (field, value) => {
    setFields({ ...fields, profile: { ...fields.profile }, [field]: value });
  }

  const handleChangeProfile = (field, value) => {
    setFields({ ...fields, profile: { ...fields.profile, [field]: value } });
  }

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    //first_name
    if (!fields['first_name']) {
      formIsValid = false;
      errors['first_name'] = 'Campo obligatorio.';
    }

    //last_name
    if (!fields['last_name']) {
      formIsValid = false;
      errors['last_name'] = 'Campo obligatorio.';
    }

    //username
    if (!fields['username']) {
      formIsValid = false;
      errors['username'] = 'Campo obligatorio.';
    }

    //manufacturer_code
    if (!fields.profile?.manufacturer_code) {
      formIsValid = false;
      errors['manufacturer_code'] = 'Campo obligatorio.';
    }

    //customer_code
    if (!fields.profile?.customer_code) {
      formIsValid = false;
      errors['customer_code'] = 'Campo obligatorio.';
    }

    //customer_database
    if (!fields.profile?.customer_database) {
      formIsValid = false;
      errors['customer_database'] = 'Campo obligatorio.';
    }

    //codemp
    if (!fields.profile?.codemp) {
      formIsValid = false;
      errors['codemp'] = 'Campo obligatorio.';
    }

    //customer_password
    if (!fields.profile?.customer_password) {
      formIsValid = false;
      errors['customer_password'] = 'Campo obligatorio.';
    }

    setErrors(errors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      setUser()
    }
  }

  const handleFileChange = (e) => {
    var file = inputRef.current.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);
  
    reader.onloadend = function (e) {
      setFields({ ...fields, profile: {...fields.profile, image: reader.result} });
    }.bind(this);

  }

  const setUser = () => {
    setLoading(true);

    axios[!params.id ? 'post' : 'patch'](`/api/users/${params.id ? params.id +'/' : ''}`, {...fields, email: fields.username})
      .then(res => {
        setLoading(false);
        toast(!params.id ? 'Usuario creado con éxito' : 'Usuario actualizado con éxito', {type: 'success'});
        navigate(`/users/${res.data.id}`)
      })
      .catch(err => {
        setLoading(false);
        if(err.response.data.detail) {
          toast(err.response.data.detail, {type: 'error'});
        } else {
          let errors = {};
          for(let key in err.response.data) {
            let alias = key === 'email' ? 'username' : key;
            errors[alias] = err.response.data[key];
            if(key === 'profile') {
              for(let key2 in err.response.data[key]) {
                errors[key2] = err.response.data[key][key2];
              }
            }
          }
          setErrors(errors)
        }
      })
  }

  const handleDelete = () => {
    setLoading3(true);

    axios.delete(`/api/users/${params.id}/`)
      .then(res => {
        setLoading3(false);
        toast('Usuario eliminado con éxito', {type: 'success'});
        navigate(`/users/`)
      })
      .catch(err => {
        setLoading3(false);
        if(err.response.data.detail) {
          toast(err.response.data.detail, {type: 'error'});
        } else {
          let errors = {};
          for(let key in err.response.data) {
            let alias = key === 'email' ? 'username' : key;
            errors[alias] = err.response.data[key];
            if(key === 'profile') {
              for(let key2 in err.response.data[key]) {
                errors[key2] = err.response.data[key][key2];
              }
            }
          }
          setErrors(errors)
        }
      })
  }

  return (
    <>
      <Card>
        <CardBody>
          <CardTitle>{!params.id ? 'Crear usuario' : 'Editar usuario'}</CardTitle>
          <Form onSubmit={handleSubmit} noValidate>
            <Row>
              <Col>
                <label 
                  htmlFor="image"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 100,
                    height: 100,
                    borderRadius: '50%',
                    border: '1px solid #ced4da',
                    marginBottom: 10,
                    backgroundImage: `url(${fields.profile?.image ? fields.profile?.image : ''})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    position: 'relative'
                  }}
                >
                  <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1, backgroundColor: '#000', opacity: 0.5, borderRadius: '50%'}} />
                  <div className="d-flex flex-column justify-content-center align-items-center" style={{position: 'relative', zIndex: 2, color: '#fff', textShadow: '0 0 2px #000'}}>
                    <span className="material-icons"> {fields.profile?.image ? 'photo' : 'photo'} </span>
                    {fields.profile?.image ? 'Cambiar foto' : 'Añadir foto'}
                  </div>
                </label>
                <input 
                  ref={inputRef}
                  id="image" 
                  type="file" 
                  style={{
                    position: 'absolute',
                    visibility: 'hidden'
                  }} 
                  onChange={handleFileChange} 
                />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label htmlFor="first_name">Nombre</Label>
                  <Input 
                    id="first_name" 
                    type="text" 
                    name="first_name"    
                    onChange={(e) => handleChange("first_name", e.target.value)}
                    value={fields["first_name"]}
                    invalid={errors["first_name"] ? true : false}
                  />
                  <FormFeedback>{errors["first_name"]}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label htmlFor="last_name">Apellido</Label>
                  <Input 
                    id="last_name" 
                    type="text" 
                    name="last_name"    
                    onChange={(e) => handleChange("last_name", e.target.value)}
                    value={fields["last_name"]}
                    invalid={errors["last_name"] ? true : false}
                  />
                  <FormFeedback>{errors["last_name"]}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label htmlFor="username">Correo</Label>
                  <Input 
                    id="username" 
                    type="text" 
                    name="username"    
                    onChange={(e) => handleChange("username", e.target.value)}
                    value={fields["username"]}
                    invalid={errors["username"] ? true : false}
                    
                  />
                  <FormFeedback>{errors["username"]}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label htmlFor="username">Contraseña</Label>
                  <p className="text-muted"><small>La contraseña se generará automáticamente y se enviará al correo electrónico del usuario.</small></p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
                <Col md="4">
                  <FormGroup>
                      <Input
                          type="checkbox" 
                          id="is_active" 
                          name="is_active"
                          defaultChecked={fields["is_active"]}
                          onClick={(e) => handleChange("is_active", e.target.checked)}
                          invalid={errors["is_active"] ? true : false}
                      />
                      <Label className="form-check-label" htmlFor="is_active">Activo</Label>
                      <FormFeedback>{errors["is_active"]}</FormFeedback>
                  </FormGroup>
                </Col>
            </Row>
            
            <hr />
            <h6>Credenciales de la API DelSol</h6>
            <Row>
              <Col md="3">
                <FormGroup>
                  <Label htmlFor="manufacturer_code">Código del Fabricante</Label>
                  <Input 
                    id="manufacturer_code" 
                    type="text" 
                    name="manufacturer_code"    
                    onChange={(e) => handleChangeProfile("manufacturer_code", e.target.value)}
                    value={fields.profile?.manufacturer_code}
                    invalid={errors["manufacturer_code"] ? true : false}
                  />
                  <FormFeedback>{errors["manufacturer_code"]}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label htmlFor="customer_code">Código del Cliente</Label>
                  <Input 
                    id="customer_code" 
                    type="text" 
                    name="customer_code"    
                    onChange={(e) => handleChangeProfile("customer_code", e.target.value)}
                    value={fields.profile?.customer_code}
                    invalid={errors["customer_code"] ? true : false}
                  />
                  <FormFeedback>{errors["customer_code"]}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label htmlFor="customer_database">Base Datos del Cliente</Label>
                  <Input 
                    id="customer_database" 
                    type="text" 
                    name="customer_database"    
                    onChange={(e) => handleChangeProfile("customer_database", e.target.value)}
                    value={fields.profile?.customer_database}
                    invalid={errors["customer_database"] ? true : false}
                    
                  />
                  <FormFeedback>{errors["customer_database"]}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label htmlFor="codemp">ID Empresa (CODEMP)</Label>
                  <Input 
                    id="codemp" 
                    type="text" 
                    name="codemp"    
                    onChange={(e) => handleChangeProfile("codemp", e.target.value)}
                    value={fields.profile?.codemp}
                    invalid={errors["codemp"] ? true : false}
                    
                  />
                  <FormFeedback>{errors["codemp"]}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label htmlFor="customer_password">Contraseña</Label>
                  <Input 
                    id="customer_password" 
                    type="password" 
                    name="customer_password"    
                    onChange={(e) => handleChangeProfile("customer_password", e.target.value)}
                    value={fields.profile?.customer_password}
                    invalid={errors["customer_password"] ? true : false}
                    
                  />
                  <FormFeedback>{errors["customer_password"]}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <br />
            <div className="d-flex">
              <Button type="submit" color="primary" disabled={loading || loading2 || loading3}>{!params.id ? 'Crear' : 'Editar'} {loading ? <Spinner size="sm" className="ms-1" /> : null}</Button>
              <Button type="button" color="dark" className="ms-2" outline disabled={loading || loading2 || loading3} onClick={() => navigate('/users')}>Cancelar</Button>
              {params.id ? <Button color="danger" type="button" className="ms-auto" outline disabled={loading || loading2 || loading3} onClick={() => setModalDelete(true)}>Eliminar{loading3 ? <Spinner size="sm" className="ms-1" /> : null}</Button> : null}
            </div>
          </Form>
        </CardBody>
      </Card>

      <Modal isOpen={modalDelete} toggle={() => setModalDelete(false)}>
        <ModalHeader toggle={() => setModalDelete(false)}>Eliminar usuario</ModalHeader>
        <ModalBody>
          ¿Está seguro de que desea eliminar el usuario {fields.email}?
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            disabled={loading || loading2 || loading3}
            onClick={() => handleDelete()}
          >
            {loading3 ? <Spinner size="sm" className="me-1"/> : null}
            {!loading3 ? 'Eliminar' : 'Eliminando...'}
          </Button>
          {' '}
          <Button outline disabled={loading || loading2 || loading3} onClick={() => setModalDelete(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}