import * as React from 'react';
import moment from 'moment';
import NumberFormat from '../components/NumberFormat';

const Invoice = ({ data, conceptos }) => {
    return (
        <div id="section-to-print" style={{width: '100%', height: '100%', backgroundColor: '#fff'}}>
            <div style={{marginBottom: '15pt'}}>
                <div style={{width: '50%', display: 'inline-block', verticalAlign: 'top', paddingRight: '5px'}}>
                    <h1 className="h5"><b>{data?.F_EMP[0].DENEMP}</b></h1>
                    <p>
                        {data?.F_EMP[0].NOMEMP} <br/>
                        N.I.F.: {data?.F_EMP[0].NIFEMP} <br/>
                        Dirección: {data?.F_EMP[0].SIGEMP} {data?.F_EMP[0].DOMEMP}, {data?.F_EMP[0].NUMEMP}, {data?.F_EMP[0].ESCEMP} {data?.F_EMP[0].PISEMP} {data?.F_EMP[0].PTREMP} {data?.F_EMP[0].CPOEMP} {data?.F_EMP[0].POBEMP} {data?.F_EMP[0].PROEMP} - ESPAÑA<br />
                        Teléfono: {data?.F_EMP[0].MOVEMP} <br/>
                        Correo: {data?.F_EMP[0].EMAEMP}
                    </p>
                </div>
                <div style={{width: '50%', display: 'inline-block', verticalAlign: 'top', paddingRight: '5px'}}>
                    <img src={data?.USER?.logo} width="200pt" />
                </div>
            </div>
            <div style={{marginBottom: '15pt'}}>
                <div style={{width: '50%', display: 'inline-block', verticalAlign: 'bottom', paddingRight: '5px'}}>
                    <div style={{height: '7pt', backgroundColor: '#023684', marginBottom: '4pt'}}></div>
                    <div style={{border: '1px solid #ccc', padding: '5pt'}}>
                        <div style={{width: '50%', display: 'inline-block', verticalAlign: 'top'}}><b>Serie / Nº factura</b> <p>{data?.TIPFAC} / {data?.CODFAC}</p></div>
                        <div style={{width: '50%', display: 'inline-block', verticalAlign: 'top'}}><b>Fecha</b> <p>{moment(data?.FECFAC).format('DD/MM/yyyy')}</p></div>
                    </div>
                </div>
                <div style={{width: '50%', display: 'inline-block', verticalAlign: 'bottom', paddingRight: '5px'}}>
                    <div style={{height: '7pt', backgroundColor: '#023684', marginBottom: '4pt'}}></div>
                    <div style={{border: '1px solid #ccc', padding: '5pt'}}>
                        <h2 className='h6'><small><b>{data?.F_CLI[0].NOMCLI}</b></small></h2>
                        <p>
                            N.I.F. / IVA: {data?.F_CLI[0].CIFCLI} <br/>
                            {data?.F_CLI[0].SIGCLI} {data?.F_CLI[0].DOMCLI}, {data?.F_CLI[0].NUMCLI} <br/>
                            {data?.F_CLI[0].CPOCLI} {data?.F_CLI[0].POBCLI} - {data?.F_CLI[0].PROCLI} <br/>
                            {data?.F_CLI[0].PAICLI} <br/>
                            {data?.F_CLI[0].WEBCLI}
                        </p>
                    </div>
                </div>
            </div>
            <div style={{marginBottom: '15pt'}}>
                <div>
                <div style={{width: '20%', display: 'inline-block', verticalAlign: 'top', textAlign: 'center'}}>
                        <div style={{color: '#fff', padding: '7pt', backgroundColor: '#023684', marginBottom: '4pt'}}>Cantidad</div> 
                        {/* {data?.F_LFA?.map((item, index) => {
                            return <p style={{marginBottom: '2pt'}}>{item?.TOTLFA}</p>
                        })} */}
                        {conceptos.map((item, index) => {
                            return <p style={{marginBottom: '2pt'}}>{item?.CANLFA}</p>
                        })}
                    </div>
                    <div style={{width: '60%', display: 'inline-block', verticalAlign: 'top', borderLeft: '1px solid #fff'}}>
                        <div style={{color: '#fff', padding: '7pt', backgroundColor: '#023684', marginBottom: '4pt', textAlign: 'center'}}>Concepto</div>
                        {/* {data?.F_LFA?.map((item, index) => {
                            return <p style={{marginBottom: '2pt', paddingLeft: '5pt'}}>{item?.DESLFA}</p>
                        })} */}
                        {conceptos.map((item, index) => {
                            return <p style={{marginBottom: '2pt', paddingLeft: '5pt'}}>{item?.DESLFA}</p>
                        })}
                    </div>
                    <div style={{width: '20%', display: 'inline-block', verticalAlign: 'top', borderLeft: '1px solid #fff', textAlign: 'center'}}>
                        <div style={{color: '#fff', padding: '7pt', backgroundColor: '#023684', marginBottom: '4pt'}}>Total</div> 
                        {/* {data?.F_LFA?.map((item, index) => {
                            return <p style={{marginBottom: '2pt'}}>{item?.TOTLFA}</p>
                        })} */}
                        {conceptos.map((item, index) => {
                            return <p style={{marginBottom: '2pt'}}>{<NumberFormat value={item?.TOTLFA} displayType={'text'} />}</p>
                        })}
                    </div>
                </div>
            </div>

            <div style={{marginBottom: '15pt'}}>
                <div>
                    <div style={{width: '20%', display: 'inline-block', verticalAlign: 'top', borderLeft: '1px solid #fff', textAlign: 'center'}}>
                        <div style={{color: '#fff', padding: '7pt', backgroundColor: '#023684', marginBottom: '4pt'}}>Base Imponible</div> 
                        <p style={{marginBottom: '2pt', padding: '7pt', textAlign: 'center'}}>{<NumberFormat value={data?.BASFAC} displayType={'text'} />}</p>
                    </div>
                    <div style={{width: '20%', display: 'inline-block', verticalAlign: 'top', borderLeft: '1px solid #fff', textAlign: 'left'}}>
                        <div style={{color: '#fff', padding: '7pt', backgroundColor: '#023684', marginBottom: '4pt'}}>
                        <div style={{width: '45%', display: 'inline-block', verticalAlign: 'top', backgroundColor: '#023684'}}>%</div> <div style={{width: '45%', display: 'inline-block', verticalAlign: 'top', textAlign: 'right', backgroundColor: '#023684'}}>I.V.A.</div></div> 
                        <p style={{marginBottom: '2pt', padding: '7pt'}}>
                            <div style={{width: '45%', display: 'inline-block', verticalAlign: 'top'}}>{<NumberFormat value={data?.IVPFAC} displayType={'text'} />}</div> 
                            <div style={{width: '45%', display: 'inline-block', verticalAlign: 'top', textAlign: 'right'}}>{<NumberFormat value={data?.IVCFAC} displayType={'text'} />}</div>
                        </p>
                    </div>
                    <div style={{width: '20%', display: 'inline-block', verticalAlign: 'top', borderLeft: '1px solid #fff', textAlign: 'left'}}>
                        <div style={{color: '#fff', padding: '7pt', backgroundColor: '#023684', marginBottom: '4pt'}}>
                        <div style={{width: '45%', display: 'inline-block', verticalAlign: 'top', backgroundColor: '#023684'}}>%</div> <div style={{width: '45%', display: 'inline-block', verticalAlign: 'top', textAlign: 'right', backgroundColor: '#023684'}}>R.E.</div></div> 
                        <p style={{marginBottom: '2pt', padding: '7pt'}}>
                            <div style={{width: '45%', display: 'inline-block', verticalAlign: 'top'}}>{<NumberFormat value={data?.REPFAC} displayType={'text'} />}</div> 
                            <div style={{width: '45%', display: 'inline-block', verticalAlign: 'top', textAlign: 'right'}}>{<NumberFormat value={data?.RECFAC} displayType={'text'} />}</div>
                        </p>
                    </div>
                    <div style={{width: '20%', display: 'inline-block', verticalAlign: 'top', borderLeft: '1px solid #fff', textAlign: 'left'}}>
                        <div style={{color: '#fff', padding: '7pt', backgroundColor: '#023684', marginBottom: '4pt'}}>
                        <div style={{width: '45%', display: 'inline-block', verticalAlign: 'top', backgroundColor: '#023684'}}>%</div> <div style={{width: '45%', display: 'inline-block', verticalAlign: 'top', textAlign: 'right', backgroundColor: '#023684'}}>I.R.P.F.</div></div> 
                        <p style={{marginBottom: '2pt', padding: '7pt'}}>
                            <div style={{width: '45%', display: 'inline-block', verticalAlign: 'top'}}>{<NumberFormat value={data?.IRPFAC} displayType={'text'} />}</div> 
                            <div style={{width: '45%', display: 'inline-block', verticalAlign: 'top', textAlign: 'right'}}>{<NumberFormat value={data?.IRCFAC} displayType={'text'} />}</div>
                        </p>
                    </div>
                    <div style={{width: '20%', display: 'inline-block', verticalAlign: 'top', borderLeft: '1px solid #fff', textAlign: 'center'}}>
                        <div style={{color: '#fff', padding: '7pt', backgroundColor: '#023684', marginBottom: '4pt'}}>
                        <div style={{width: '45%', display: 'inline-block', verticalAlign: 'top', textAlign: 'center', backgroundColor: '#023684'}}>Suplidos</div></div> 
                        <p style={{marginBottom: '2pt', padding: '7pt'}}>
                            <div style={{width: '45%', display: 'inline-block', verticalAlign: 'top'}}>{<NumberFormat value={data?.SUPFAC} displayType={'text'} />}</div> 
                        </p>
                    </div>
                </div>
            </div>

            <div style={{marginBottom: '15pt'}}>
                <div>
                    <div style={{width: '80%', display: 'inline-block', verticalAlign: 'top'}}>
                        <div style={{color: '#fff', padding: '7pt', backgroundColor: '#023684', marginBottom: '4pt', textAlign: 'center'}}>Observaciones</div> 
                        <p style={{marginBottom: '2pt', paddingLeft: '5pt'}}>
                            {data?.OB1FAC} <br/> 
                            {data?.OB2FAC}
                        </p>
                    </div>
                    <div style={{width: '20%', display: 'inline-block', verticalAlign: 'top', borderLeft: '1px solid #fff', textAlign: 'center'}}>
                        <div style={{color: '#fff', padding: '7pt', backgroundColor: '#023684', marginBottom: '4pt'}}>Total</div> 
                        <p style={{marginBottom: '2pt'}}>{<NumberFormat value={data?.TOTFAC} displayType={'text'} />}</p>
                    </div>
                </div>
            </div>

            <div style={{textAlign: 'right'}}>
                <b>Vencimiento</b> {moment(data?.FVEFAC).format('DD/MM/YYYY')}
            </div>
            
            {/* <div className="text-center mt-5" style={{fontSize: 10}}>
                servicios integral para pymes y autónomos <br/>
                <a href="www.gestapymes.es" target="_blank">www.gestapymes.es</a>
            </div> */}
        </div>
    )
}

export default Invoice;
