import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardHeader, CardTitle, CardSubtitle } from 'reactstrap';

export default function Auth() {
    const navigate = useNavigate();
    
    useEffect(() => {
      if(localStorage.token !== undefined) navigate('/customers');
    }, [])

    return (
      <div className="auth">
        <Container>
          <div className="d-flex justify-content-center">
            <Card style={{width: 480}}>
              <Outlet/>
            </Card>
          </div>
        </Container>
      </div>
    );
}