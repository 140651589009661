import axios from 'axios';
import * as React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Card, CardBody, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Label, Row, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Table, ListGroup, ListGroupItem, Alert } from 'reactstrap';
import Select from 'react-select'
import moment from 'moment';
import CustomerForm from './CustomerForm';
import Loading from '../components/Loading';
import EmptyData from '../components/EmptyData';
import { isEmpty, validEmail, validURL } from '../lib/validator';
import activities from '../global/activities';
import identificacionFiscal from '../global/identificacion_fiscal';
import tiposDeOperaciones from '../global/tipos_de_operaciones';
import clienteClaveOperacionHabitual from '../global/cliente_clave_operacion_habitual';
import tiposDeRetificaciones from '../global/tipos_de_retificaciones';
import countries from '../global/country-codes_json.json';
import NumberFormat from '../components/NumberFormat';


const initialValues = {
	MOVFEM: 'F', // [L=#0;Factura Emitida#1;Ingreso]Tipo de movimiento
	'347FEM': 1,
	'COPFEM': '0',
	'TRETFEM': '0',

	'BA0FEM': 0,

	'BA1FEM': 0,
	'PI1FEM': 0,
	'CI1FEM': 0,
	'PR1FEM': 0,
	'CR1FEM': 0,

	'BA2FEM': 0,
	'PI2FEM': 0,
	'CI2FEM': 0,
	'PR2FEM': 0,
	'CR2FEM': 0,

	'BA3FEM': 0,
	'PI3FEM': 0,
	'CI3FEM': 0,
	'PR3FEM': 0,
	'CR3FEM': 0,

	'BA4FEM': 0,
	'PI4FEM': 0,
	'CI4FEM': 0,
	'PR4FEM': 0,
	'CR4FEM': 0,

	'BA5FEM': 0,
	'PI5FEM': 0,
	'CI5FEM': 0,
	'PR5FEM': 0,
	'CR5FEM': 0,

	'PRETFEM': 0,
	'IRETFEM': 0,
	'TOPFEM': 0,
	'SUPFEM': 0,
	'TOTFEM': 0,
	
}

const initialValuesConcepto = {
	FICLCP: 'FEM',
	CODLCP: null,
	LINLCP: null,
	FECLCP: moment(),
	IMPLCP: 0,
	EFELCP: 0,
	CPTLCP: '',
}

const Cobros = (props) => {
	return (
		<Card className='border h-100' style={{boxShadow: 'none'}}>
			<CardBody>
				<div className='d-flex align-items-center mb-3'>
					<div>
						<h6>Cobros</h6>
					</div>
					<div className='ms-auto'>
						<div>
							<Button 
								color="none" 
								size="sm" 
								disabled={props.loading || props.loading2 || props.loading3 || props.loading4 || props.loading5 || props.loading6 || props.loading7} 
								onClick={() => {
									if(props.params.id) {
										props.setFieldsConcepto(initialValuesConcepto); 
										props.setModalConcepto(true);                                                            
									}
									else {
										toast('Debe guardar la factura primero', {type: 'warning'});
									}
								}}
							>
								<span className="material-icons">add</span>
							</Button>
						</div>
					</div>
				</div>
				<Table>
					<thead>
						<tr>
							<th>Fecha</th>
							<th>Importe</th>
							<th>&nbsp;</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td colSpan={5}>{props.loading5 ? <Loading /> : null}</td>
						</tr>
						{props.conceptos.map((item, key) => {
							return (
								<tr>
									<td>{moment(item.FECLCP).format('DD/MM/YYYY')}</td>
									<td>{<NumberFormat value={item.IMPLCP} displayType={'text'} />}</td>
									<td className="text-end pt-1 pb-0">
										<Button size="sm" outline className="pb-0 me-1" disabled={props.loading || props.loading2 || props.loading3 || props.loading4 || props.loading5 || props.loading6 || props.loading7} onClick={() => {props.setFieldsConcepto(item); props.setModalConcepto(true)}}><span className="material-icons fs-6">edit</span></Button>
										<Button size="sm" color="danger" outline className="pb-0 me-1" disabled={props.loading || props.loading2 || props.loading3 || props.loading4 || props.loading5 || props.loading6 || props.loading7} onClick={() => props.deleteConcepto(item.LINLCP, item.FICLCP, item.CODLCP)}>{props.loading7 === item.LINLCP ? <Spinner size="sm"/> : <span className="material-icons fs-6">delete</span>}</Button>
									</td>
								</tr>
							)
						})}
					</tbody>
				</Table>
			</CardBody>
		</Card>
	)
}

export default function InvoiceIssuedForm({ period, source }) {
  const [fields, setFields] = React.useState(initialValues);
  const [fieldsConcepto, setFieldsConcepto] = React.useState(initialValuesConcepto);
  const [errors, setErrors] = React.useState({});
  const [errorsConcepto, setErrorsConcepto] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [loading3, setLoading3] = React.useState(false);
  const [loading4, setLoading4] = React.useState(false);
  const [loading5, setLoading5] = React.useState(false);
  const [loading6, setLoading6] = React.useState(false);
  const [loading7, setLoading7] = React.useState(null);
  const [modalDelete, setModalDelete] = React.useState(false);
  const [conceptos, setConceptos] = React.useState([]);
  const [actividades, setActividades] = React.useState([]);
  const [tiposDeIngresos, setTiposDeIngresos] = React.useState([]);
  const [customers, setCustomers] = React.useState([]);
  const [customersFull, setCustomersFull] = React.useState([]);
  const [modalCliente, setModalCliente] = React.useState(false);
  const [modalSelectCliente, setModalSelectCliente] = React.useState(false);
  const [moreIVA, setMoreIVA] = React.useState(0);
  const [alert, setAlert] = React.useState('');
  const [modalConcepto, setModalConcepto] = React.useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const type = fields.MOVFEM === 'F' ? 'Factura emitida' : 'Registro de Ingreso';
  const genero = fields.MOVFEM === 'F' ? 'a' : 'o';	

  React.useEffect(() => {
	getData();
  }, [period, params.id])

  React.useEffect(() => {
	let count = (hasIVA4() ? 1 : 0) + (hasIVA5() ? 1 : 0);
	setMoreIVA(count > moreIVA ? count : moreIVA);
  }, [fields])

	React.useEffect(() => {
		if(!fields) return;
			
		let baseImponible = (parseFloat(fields.BA0FEM) + parseFloat(fields.BA1FEM) + parseFloat(fields.BA2FEM) + parseFloat(fields.BA3FEM) + parseFloat(fields.BA4FEM) + parseFloat(fields.BA5FEM))
		let iva = (parseFloat(fields.CI1FEM) + parseFloat(fields.CI2FEM) + parseFloat(fields.CI3FEM) + parseFloat(fields.CI4FEM) + parseFloat(fields.CI5FEM))
		let re = (parseFloat(fields.CR1FEM) + parseFloat(fields.CR2FEM) + parseFloat(fields.CR3FEM) + parseFloat(fields.CR4FEM) + parseFloat(fields.CR5FEM))
		let totalOperacion = baseImponible + iva + re
		let suplidos = parseFloat(fields.SUPFEM)
		let irpf = (parseFloat(fields.PRETFEM) / 100) * baseImponible
		let total = totalOperacion + suplidos - irpf

		let _totfem = fields.MOVFEM === 'F' ? total : fields.TOTFEM

		let _tirfem = fields?.TIRFRE

		if(parseInt(fields.COPFEM) === 4) {
			_tirfem = _totfem < 0 ? 9 : 4
		}

		setFields({
			...fields,
			CI1FEM: (parseFloat(fields.PI1FEM) / 100) * parseFloat(fields.BA1FEM),
			CI2FEM: (parseFloat(fields.PI2FEM) / 100) * parseFloat(fields.BA2FEM),
			CI3FEM: (parseFloat(fields.PI3FEM) / 100) * parseFloat(fields.BA3FEM),
			CI4FEM: (parseFloat(fields.PI4FEM) / 100) * parseFloat(fields.BA4FEM),
			CI5FEM: (parseFloat(fields.PI5FEM) / 100) * parseFloat(fields.BA5FEM),

			CR1FEM: (parseFloat(fields.PR1FEM) / 100) * parseFloat(fields.BA1FEM),
			CR2FEM: (parseFloat(fields.PR2FEM) / 100) * parseFloat(fields.BA2FEM),
			CR3FEM: (parseFloat(fields.PR3FEM) / 100) * parseFloat(fields.BA3FEM),
			CR4FEM: (parseFloat(fields.PR4FEM) / 100) * parseFloat(fields.BA4FEM),
			CR5FEM: (parseFloat(fields.PR5FEM) / 100) * parseFloat(fields.BA5FEM),

			TOPFEM: totalOperacion,

			IRETFEM: irpf,

			TOTFEM: _totfem,

			TIRFEM: _tirfem
		})
	}, [
		fields.BA0FEM, 
		fields.BA1FEM, fields.PI1FEM, fields.CI1FEM, fields.PR1FEM, fields.CR1FEM,
		fields.BA2FEM, fields.PI2FEM, fields.CI2FEM, fields.PR2FEM, fields.CR2FEM,
		fields.BA3FEM, fields.PI3FEM, fields.CI3FEM, fields.PR3FEM, fields.CR3FEM,
		fields.BA4FEM, fields.PI4FEM, fields.CI4FEM, fields.PR4FEM, fields.CR4FEM,
		fields.BA5FEM, fields.PI5FEM, fields.CI5FEM, fields.PR5FEM, fields.CR5FEM,
		fields.TOPFEM,
		fields.PRETFEM, fields.IRETFEM,
		fields.TOTFEM,
		fields.SUPFEM,
	])

//   React.useEffect(() => {
//     if(fields.CLIFEM && customersFull.length > 0) handleChangeNOMFEM({value: fields.CLIFEM})
//   }, [fields.CLIFEM, customersFull])

  const getData = () => {
	axios.get(`/api/issued-invoices/actividades/?ejercicio=${period}&filtro=CODACT>=0`, {
		cancelToken: source.token
	  })
		.then(res => {
			setActividades(res.data);
		})

	axios.get(`/api/issued-invoices/tipos_de_ingreso/?ejercicio=${period}&filtro=LIBTGI=1 ORDER BY CODTGI`, {
		cancelToken: source.token
		})
		.then(res => {
			setTiposDeIngresos(res.data);
		})

	getCustomers()

	if(params.id) {
		setLoading2(true);
		axios.get(`/api/issued-invoices/${params.id ? params.id +'/' : ''}?ejercicio=${period}&filtro=CODFEM=${params.id}`, {
			cancelToken: source.token
		  })
		  .then(res => {
			setLoading2(false);
			setFields(res.data.CODFEM ? res.data : null);

			initialValuesConcepto.CODLCP = res.data.CODFEM
			setFieldsConcepto({
				...fieldsConcepto,
				CODLCP: res.data.CODFEM,
			})
			getConceptos('FEM', res.data.CODFEM);
		  })
	} else {
		setFields({ ...fields });
		setLoading4(true)
		axios.get(`/api/issued-invoices/last_id/?ejercicio=${period}`, {
			cancelToken: source.token
		  })
			.then(res => {
				setLoading4(false);
				setFields({...fields, CODFEM: res.data, FACFEM: res.data});
			})
	}
  }

  const handleChangeConcepto = (field, value) => {
	setFieldsConcepto({ ...fieldsConcepto, [field]: value });
  }

  const getConceptos = (FICLCP='FEM', CODLCP, callback) => {
	axios.get(`/api/charges/?ejercicio=${period}&filtro= FICLCP='${FICLCP}' AND CODLCP=${CODLCP} ORDER BY LINLCP DESC`, {
		cancelToken: source.token
	  })
		.then(res => {
			setConceptos(res.data);
			setLoading5(false);
			if(callback) callback(res.data);
		})     
		.catch((err) => {
			setLoading5(false);
			if(callback) callback();
		})  
  }

  const saveConcepto = () => {
	setLoading6(true);

	axios.get(`/api/charges/last_id/?ejercicio=${period}`, {
		cancelToken: source.token
	  })
		.then(res => {
			axios[fieldsConcepto.LINLCP === null ? 'post' : 'put'](`/api/charges/${fieldsConcepto.LINLCP !== null ? fieldsConcepto.LINLCP +'/' : ''}?ejercicio=${period}`, {
				...fieldsConcepto, 
				FICLCP: 'FEM', 
				CODLCP: fields.CODFEM, 
				LINLCP: fieldsConcepto.LINLCP !== null ? fieldsConcepto.LINLCP : res.data
			})
			  .then(res => {
				toast('Cobro guardado con éxito', {type: 'success'});
				setModalConcepto(false);
				setFieldsConcepto(initialValuesConcepto);
		
				setLoading5(true);
		
				getConceptos('FEM', fields.CODFEM, () => {
					setLoading5(false);
					setLoading6(false);
				});
			  })
			  .catch((err) => {
				setLoading6(false);
				err.response.data.forEach((error, key) => {
					toast(error, {type: 'error'});
				})
			  })
		})
  }

  const deleteConcepto = (LINLCP, FICLCP, CODLCP) => {
	setLoading7(LINLCP);

	axios.delete(`/api/charges/${LINLCP}/?ejercicio=${period}&filtro=LINLCP=${LINLCP} AND FICLCP='${FICLCP}' AND CODLCP=${CODLCP}`)
	  .then(res => {
		getConceptos(FICLCP, CODLCP, () => {
			setLoading7(null);
			toast('Cobro eliminado con éxito', {type: 'success'});
		});
	  })
	  .catch((err) => {
		setLoading7(null);
		toast('Error al eliminar el cobro', {type: 'error'});
	  })
  }

  const setNewCustomer = (data) => {
	let TIPFEM = null;
	let TIPCLI = data.TIPCLI;
	
	switch(TIPCLI) {
		case 0:
			TIPFEM = 0;
			break;
		case 1:
			TIPFEM = 3;
			break;
		case 2:
			TIPFEM = 2;
			break;
		case 3:
			TIPFEM = 1;
			break;
		default:
			TIPFEM = null;
			break;
	}

    let CINCLI = parseInt(data.CINCLI)
    let _349FEM = null;

    switch(CINCLI) {
        case 1:
            _349FEM = 'A';
            break;
        case 8:
            _349FEM = 'C';
            break;
        case 9:
            _349FEM = 'D';
            break;
        case 2:
            _349FEM = 'E';
            break;
        case 3:
            _349FEM = 'H';
            break;
        case 4:
            _349FEM = 'I';
            break;
        case 5:
            _349FEM = 'M';
            break;
        case 6:
            _349FEM = 'S';
            break;
        case 7:
            _349FEM = 'T';
            break;
    }

	setFields({ 
		...fields,
		'NOMFEM': data.NOMCLI,
		'CLIFEM': data.CODCLI,
		'TIFFEM': data.IFICLI,
		'NIFFEM': data.CIFCLI,
		'COPFEM': data.COPCLI,

		'PI1FEM': data.TPICLI.toString() === '0' ? 0 : (data.TPICLI.toString() === '1' || data.TPICLI.toString() === '4' ? 21.00 : (data.TPICLI.toString() === '2' ? 10.00 : (data.TPICLI.toString() === '3' ? 4.00 : (data.TPICLI.toString() === '5' ? 10.00 : (data.TPICLI.toString() === '6' ? 4.00 : fields.PI1FEM))))),
		'PI2FEM': data.TPICLI.toString() === '0' ? 0 : fields.PI2FEM,
		'PI3FEM': data.TPICLI.toString() === '0' ? 0 : fields.PI3FEM,
		'PI4FEM': data.TPICLI.toString() === '0' ? 0 : fields.PI4FEM,
		'PI5FEM': data.TPICLI.toString() === '0' ? 0 : fields.PI5FEM,
		'PRETFEM': data.TPICLI.toString() === '0' ? 0 : fields.PRETFEM,
		'PR1FEM': data.TPICLI.toString() === '4' ? 5.20 : (data.TPICLI.toString() === '5' ? 1.40 : (data.TPICLI.toString() === '6' ? 0.5 : fields.PR1FEM)),

		ACTFEM: data.ACTCLI,
		TIPFEM: TIPFEM,
		'347FEM': data.TIPCLI !== 3 ? 1 : 0,
		'349FEM': _349FEM,
		'ayuda_en_el_calculo': data.ACPCLI,
		'TRETFEM': data.TRECLI,
		'PRETFEM': data.RETCLI,
	});
  }

  const getCustomers = (callback) => {
	axios.get(`/api/issued-invoices/customers/?ejercicio=${period}&filtro=CODCLI>=0 ORDER BY CODCLI DESC`, {
		cancelToken: source.token
		})
		.then(res => {
			let customers = [];
			res.data.forEach(customer => {
				customers.push({
					value: customer.CODCLI,
					label: customer.NOMCLI
				})
			})
			setCustomersFull(res.data);
			setCustomers(customers);
			if(callback) callback(res.data, customers);
		})
  }

  const handleChange = (field, value) => {
	setFields({ ...fields, [field]: value });
  }

  const handleValidation = () => {
	let errors = {};
	let formIsValid = true;

	if (isEmpty(fields['CODFEM'])) {
		formIsValid = false;
		errors['CODFEM'] = 'Campo obligatorio.';
	}

	if (isEmpty(fields['MOVFEM'])) {
		formIsValid = false;
		errors['MOVFEM'] = 'Campo obligatorio.';
	}

	if (isEmpty(fields['FECFEM'])) {
		formIsValid = false;
		errors['FECFEM'] = 'Campo obligatorio.';
	}

	if (isEmpty(fields['TIPFEM'])) {
		formIsValid = false;
		errors['TIPFEM'] = 'Campo obligatorio.';
	}

	

	setErrors(errors);
	return formIsValid;
  }

  const handleSubmit = (e) => {
	e.preventDefault();

	if (handleValidation()) {
	  setInvoiceIssued()
	}
  }

  const setInvoiceIssued = () => {
	setLoading(true);

	let _fields = setDefaultIfEmpty();

	delete _fields.ayuda_en_el_calculo;

	axios[!params.id ? 'post' : 'put'](`/api/issued-invoices/${params.id ? params.id +'/' : ''}?ejercicio=${period}`, {..._fields, CODFEM: fields.CODFEM})
	  .then(res => {
		setLoading(false);
		toast(!params.id ? type + ' creada con éxito' : type + ` actualizad${genero} con éxito`, {type: 'success'});
		if(!params.id) navigate(`/issued-invoices/${res.data.CODFEM}`)
	  })
	  .catch((err) => {
		err.response.data.forEach((error, key) => {
			setLoading(false);
			toast(error, {type: 'error'});
		})
	  })
  }

  const handleDelete = () => {
	setLoading3(true);

	axios.delete(`/api/issued-invoices/${params.id}/?ejercicio=${period}`)
	  .then(res => {
		setLoading3(false);
		toast(type + ` eliminad${genero} con éxito`, {type: 'success'});
		navigate(`/issued-invoices/`)
	  })
	  .catch((err) => {
		setLoading3(false);
		toast(`Error al eliminar ${fields.MOVFEM === 'F' ? 'la' : 'el'} ${type.toLocaleLowerCase()}`, {type: 'error'});
	  })
  }

  const setDefaultIfEmpty = () => {
		let newFields = { ...fields };
		for(let field in fields) {
			if(isEmpty(fields[field])) {
				newFields[field] = initialValues[field];
			}
		}
		setFields(newFields);
		return newFields;
  }

  const hasIVA4 = () => {
	if(
		(!isEmpty(fields['BA4FEM']) && fields.BA4FEM > 0) ||
		(!isEmpty(fields['PI4FEM']) && fields.PI4FEM > 0) ||
		(!isEmpty(fields['CI4FEM']) && fields.CI4FEM > 0) ||
		(!isEmpty(fields['PR4FEM']) && fields.PR4FEM > 0) ||
		(!isEmpty(fields['CR4FEM']) && fields.CR4FEM > 0)
	) {
		return true;
	}
	return false;
  }

  const hasIVA5 = () => {
	if(
		(!isEmpty(fields['BA5FEM']) && fields.BA5FEM > 0) ||
		(!isEmpty(fields['PI5FEM']) && fields.PI5FEM > 0) ||
		(!isEmpty(fields['CI5FEM']) && fields.CI5FEM > 0) ||
		(!isEmpty(fields['PR5FEM']) && fields.PR5FEM > 0) ||
		(!isEmpty(fields['CR5FEM']) && fields.CR5FEM > 0)
	) {
		return true;
	}
	return false;
  }

  const checkIVA = () => {
	if(
		(parseFloat(fields['PI1FEM']) > 0 && parseFloat(fields['PI1FEM']) !== 21 && parseFloat(fields['PI1FEM']) !== 10 && parseFloat(fields['PI1FEM']) !== 4) ||
		(parseFloat(fields['PI2FEM']) > 0 && parseFloat(fields['PI2FEM']) !== 21 && parseFloat(fields['PI2FEM']) !== 10 && parseFloat(fields['PI2FEM']) !== 4) ||
		(parseFloat(fields['PI3FEM']) > 0 && parseFloat(fields['PI3FEM']) !== 21 && parseFloat(fields['PI3FEM']) !== 10 && parseFloat(fields['PI3FEM']) !== 4) ||
		(parseFloat(fields['PI4FEM']) > 0 && parseFloat(fields['PI4FEM']) !== 21 && parseFloat(fields['PI4FEM']) !== 10 && parseFloat(fields['PI4FEM']) !== 4) ||
		(parseFloat(fields['PI5FEM']) > 0 && parseFloat(fields['PI5FEM']) !== 21 && parseFloat(fields['PI5FEM']) !== 10 && parseFloat(fields['PI5FEM']) !== 4)
	) {
		setAlert('El tipo de IVA debe ser 21, 10 o 4');
	} 
	else {
		setAlert('');
	}
  }

  return (
	<>
	  <Card>
		<CardBody>
			<ButtonGroup className='mb-3'>
				<Button
				color="secondary"
				active={fields.MOVFEM === 'F'}
				onClick={() => handleChange("MOVFEM", 'F')}
				disabled={loading || loading2 || loading3 || loading4 || loading5 || loading6 || loading7} 
				>
				Factura Emitida
				</Button>
				<Button
				color="secondary"
				active={fields.MOVFEM === 'I'}
				onClick={() => handleChange("MOVFEM", 'I')}
				disabled={loading || loading2 || loading3 || loading4 || loading5 || loading6 || loading7} 
				>
				Ingreso
				</Button>
			</ButtonGroup>
		
		  <div className="d-flex align-items-center">
			   {fields !== null ? <CardTitle>{!params.id ? 'Crear '+ type : 'Editar '+ type}</CardTitle> : null} 
			   {loading2 || loading4 ? <div className="mb-2 ms-2"><Loading text="Cargando..." /></div> : null}
			</div>
		  {fields !== null ?
		  <Form onSubmit={handleSubmit} noValidate>
			<Row>
				<Col md="6" style={{borderRight: '1px solid #d1d6d8'}}>
					<Row>
						<Col md="4">
							<FormGroup>
							<Label htmlFor="CODFEM">Nº de registro</Label>
							<Input 
								id="CODFEM" 
								type="number" 
								name="CODFEM"    
								onChange={(e) => handleChange("CODFEM", e.target.value)}
								value={fields["CODFEM"]}
								invalid={errors["CODFEM"] ? true : false}
								readOnly
							/>
							<FormFeedback>{errors["CODFEM"]}</FormFeedback>
							</FormGroup>
						</Col>
						<Col md="8">
							<FormGroup>
								<Label htmlFor="ACTFEM">Actividad</Label>
								<Input 
									id="ACTFEM" 
									type="select" 
									name="ACTFEM"    
									onChange={(e) => handleChange("ACTFEM", e.target.value)}
									value={fields["ACTFEM"]}
									invalid={errors["ACTFEM"] ? true : false}
								>
									<option value="0">[Sin predefinir]</option>
									{actividades.map((actividad, key) => {
										return <option key={key} value={actividad.CODACT}>{actividad.EPIACT} - {activities[actividad.EPIACT]}</option>
									})}
								</Input>
								<FormFeedback>{errors["ACTFEM"]}</FormFeedback>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col md="12">
							<FormGroup>
								<Label htmlFor="TREFEM">Tipo de ingreso</Label>
								<Input 
									id="TREFEM" 
									type="select" 
									name="TREFEM"    
									maxLength={3}
									onChange={(e) => handleChange("TREFEM", e.target.value)}
									value={fields["TREFEM"]}
									invalid={errors["TREFEM"] ? true : false}
								>
									<option value="">Seleccionar</option>
									{tiposDeIngresos.map((tipoDeIngreso, key) => {
										return <option key={key} value={tipoDeIngreso.CODTGI}>{tipoDeIngreso.DENTGI}</option>
									})}
								</Input>
								<FormFeedback>{errors["TREFEM"]}</FormFeedback>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col md="6">
							<FormGroup>
								<Label htmlFor="FECFEM">Fecha del registro</Label>
								<Input 
									id="FECFEM" 
									type="date" 
									name="FECFEM"    
									onChange={(e) => {
										handleChange("FECFEM", e.target.value)
										setFields({
											...fields,
											FECFEM: e.target.value,
											FEXFEM: e.target.value,
											FROFEM: e.target.value
										})
									}}
									value={fields["FECFEM"] ? moment(fields["FECFEM"]).format('yyyy-MM-DD') : ''}
									invalid={errors["FECFEM"] ? true : false}
								>
									<option value=""></option>
								</Input>
								<FormFeedback>{errors["FECFEM"]}</FormFeedback>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col md="6">
							<FormGroup>
								<Label htmlFor="FEXFEM">Fecha expedición</Label>
								<Input 
									id="FEXFEM" 
									type="date" 
									name="FEXFEM"    
									onChange={(e) => handleChange("FEXFEM", e.target.value)}
									value={fields["FEXFEM"] ? moment(fields["FEXFEM"]).format('yyyy-MM-DD') : ''}
									invalid={errors["FEXFEM"] ? true : false}
								>
									<option value=""></option>
								</Input>
								<FormFeedback>{errors["FEXFEM"]}</FormFeedback>
							</FormGroup>
						</Col>
						<Col md="6">
							<FormGroup>
								<Label htmlFor="FROFEM">Fecha registro operación</Label>
								<Input 
									id="FROFEM" 
									type="date" 
									name="FROFEM"    
									onChange={(e) => handleChange("FROFEM", e.target.value)}
									value={fields["FROFEM"] ? moment(fields["FROFEM"]).format('yyyy-MM-DD') : ''}
									invalid={errors["FROFEM"] ? true : false}
								>
									<option value=""></option>
								</Input>
								<FormFeedback>{errors["FROFEM"]}</FormFeedback>
							</FormGroup>
						</Col>
					</Row>
					<Row className="mt-4">
						<Col>
							<Row>
								<Col className="d-flex">
									<FormGroup>
										<Button color="dark" outline onClick={() => setModalSelectCliente(true)} className="me-2">Seleccionar cliente</Button>
										<Button color="dark" outline onClick={() => setModalCliente(true)}>Crear cliente</Button>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col md="9">
									<FormGroup>
										<Label htmlFor="NOMFEM">Nombre</Label>
										<Input 
											id="NOMFEM" 
											type="text" 
											name="NOMFEM"   
											maxLength={100} 
											value={fields["NOMFEM"]}
											invalid={errors["NOMFEM"] ? true : false}
											disabled
										>
											<option value=""></option>
										</Input>
										<FormFeedback>{errors["NOMFEM"]}</FormFeedback>
									</FormGroup>
								</Col>
								<Col md="3">
									<FormGroup>
										<Label htmlFor="CLIFEM">&nbsp;</Label>
										<Input 
											id="CLIFEM" 
											type="number" 
											name="CLIFEM"
											value={fields["CLIFEM"]}
											invalid={errors["CLIFEM"] ? true : false}
											readOnly
										/>
										<FormFeedback>{errors["CLIFEM"]}</FormFeedback>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col md="6">
									<FormGroup>
										<Label htmlFor="TIFFEM">Identificación Fiscal</Label>
										<Input
											id="TIFFEM" 
											type="text" 
											name="TIFFEM"
											value={identificacionFiscal[fields["TIFFEM"]]}
											invalid={errors["TIFFEM"] ? true : false}
											disabled
										/>
										<FormFeedback>{errors["TIFFEM"]}</FormFeedback>
									</FormGroup>
								</Col>
								<Col md="6">
									<FormGroup>
										<Label htmlFor="NIFFEM">N.I.F. / VAT number</Label>
										<Input
											id="NIFFEM" 
											type="text"
											name="NIFFEM"
											maxLength={18} 
											value={fields["NIFFEM"]}
											invalid={errors["NIFFEM"] ? true : false}
											disabled
										/>
										<FormFeedback>{errors["NIFFEM"]}</FormFeedback>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col md="12">
									<FormGroup>
										<Label htmlFor="COPFEM">Clave de operación habitual</Label>
										<Input 
											id="COPFEM" 
											type="select" 
											name="COPFEM"    
											onChange={(e) => {
												setFields({ 
													...fields, 
													COPFEM: e.target.value,
													NFRFEM: null,
													TIRFEM: null
												});
											}}
											value={fields["COPFEM"]}
											invalid={errors["COPFEM"] ? true : false}
										>
											{clienteClaveOperacionHabitual.map((claveOperacion, key) => {
												return <option value={key}>{claveOperacion}</option>
											})}
										</Input>
										<FormFeedback>{errors["COPFEM"]}</FormFeedback>
									</FormGroup>
								</Col>
							</Row>
							{
								parseInt(fields.COPFEM) === 4 ? 
									<Row>
										<Col md="6">
											<FormGroup>
												<Label htmlFor="NFRFEM">Factura Rectificada</Label>
												<Input 
													id="NFRFEM" 
													type="text" 
													name="NFRFEM"   
													maxLength={30} 
													onChange={(e) => handleChange("NFRFEM", e.target.value)}
													value={fields["NFRFEM"]}
													invalid={errors["NFRFEM"] ? true : false}
												/>
												<FormFeedback>{errors["NFRFEM"]}</FormFeedback>
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<Label htmlFor="TIRFEM">Tipo de rectificación</Label>
												<Input 
													id="TIRFEM" 
													type="select" 
													name="TIRFEM"    
													onChange={(e) => handleChange("TIRFEM", e.target.value)}
													value={fields["TIRFEM"]}
													invalid={errors["TIRFEM"] ? true : false}
												>
													<option value="">Seleccione</option>
													{tiposDeRetificaciones.map((tipoDeRetificacion, key) => {
														return <option value={key+1}>{key+1} - {tipoDeRetificacion}</option>
													})}
												</Input>
												<FormFeedback>{errors["TIRFEM"]}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>
								: null
							}
						</Col>
					</Row>
				</Col>
				<Col md={fields.MOVFEM === 'F' ? 6 : 3}>
					<Row>
						<Col md={fields.MOVFEM === 'F' ? 6 : 12}>
							<FormGroup>
								<Label htmlFor="FACFEM">{fields.MOVFEM === 'F' ? 'Número de Factura' : 'Nº de Justificante'}</Label>
								<Input 
									id="FACFEM" 
									type="text" 
									name="FACFEM"   
									maxLength={30} 
									onChange={(e) => handleChange("FACFEM", e.target.value)}
									value={fields["FACFEM"]}
									invalid={errors["FACFEM"] ? true : false}
								/>
								<FormFeedback>{errors["FACFEM"]}</FormFeedback>
							</FormGroup>
						</Col>
						{fields.MOVFEM === 'F' ? 
						<Col md="6">
							<FormGroup>
								<Label htmlFor="SERFEM">Serie</Label>
								<Input 
									id="SERFEM" 
									type="text" 
									name="SERFEM"  
									maxLength={255}  
									onChange={(e) => handleChange("SERFEM", e.target.value)}
									value={fields["SERFEM"]}
									invalid={errors["SERFEM"] ? true : false}
								/>
								<FormFeedback>{errors["SERFEM"]}</FormFeedback>
							</FormGroup>
						</Col>
						: null}
					</Row>

					{/* Si MOVFEM === "F" cambia de ubicación el campo */}
					{fields.MOVFEM === 'I' ?
					<Row className="mb-4">
						<Col>
							<Label htmlFor="DESFEM">Descripción</Label>
							<Input 
								id="DESFEM" 
								type="textarea" 
								name="DESFEM"   
								maxLength={50} 
								onChange={(e) => handleChange("DESFEM", e.target.value)}
								value={fields["DESFEM"]}
								invalid={errors["DESFEM"] ? true : false}
								style={{height: 100}}
							/>
							<FormFeedback>{errors["DESFEM"]}</FormFeedback>
						</Col>
					</Row>
					: null}

					{/* Si MOVFEM === "F" el campo cambia de ubicación */}
					{fields.MOVFEM === 'I' ?
					<Row>
						<Col md={fields.MOVFEM === 'F' ? 6 : 12}>
							<FormGroup>
								<Label htmlFor="TOTFEM">Importe</Label>
								<NumberFormat 
									id="TOTFEM" 
									type="number" 
									name="TOTFEM"    
									onChange={(e) => handleChange("TOTFEM", e.target.value)}
									onBlur={(e) => e.target.value === '' ? handleChange("TOTFEM", 0) : null}
									value={fields["TOTFEM"]}
									invalid={errors["TOTFEM"] ? true : false}
								/>
								<FormFeedback>{errors["TOTFEM"]}</FormFeedback>
							</FormGroup>
						</Col>
					</Row>
					: null}

					<Row> {/* <Row style={{marginTop: 166}}> */}
						<Col>
							<Row>
								{fields.MOVFEM === 'F' ? 
								<Col md="6">
									<FormGroup>
										<Label htmlFor="TIPFEM">Tipo de operación</Label>
										<Input 
											id="TIPFEM" 
											type="select" 
											name="TIPFEM"    
											onChange={(e) => {
												setFields({
													...fields,
													TIPFEM:  e.target.value,
													'347FEM': e.target.value === '2' ? 1 : ((e.target.value === '0' && fields?.TRETFEM === '0') || (e.target.value === '3' && fields?.TRETFEM === '0' ) ? 1 : 0),
													'IELFEM': e.target.value === '2' ? 1 : 0,
												})
											}}
											value={fields["TIPFEM"]}
											invalid={errors["TIPFEM"] ? true : false}
										>
											<option value="">Seleccionar</option>
											{tiposDeOperaciones.map((tipo, index) => <option value={index}>{tipo}</option> )}
										</Input>
										<FormFeedback>{errors["TIPFEM"]}</FormFeedback>
									</FormGroup>
								</Col>
								: null}

								{parseInt(fields.TIPFEM) === 2 ? 
                                    <Col md="6">
                                        <Label htmlFor="IELFEM">&nbsp;</Label>
                                        <FormGroup className="d-flex">
                                            <Input
                                                type="checkbox" 
                                                id="IELFEM" 
                                                name="IELFEM"
                                                defaultChecked={fields["IELFEM"]}
                                                onClick={(e) => handleChange("IELFEM", e.target.checked ? 1 : 0)}
                                                invalid={errors["IELFEM"] ? true : false}
                                            />
                                                <Label className="form-check-label" htmlFor="IELFEM">Incluir en la liquidación</Label>
                                            <FormFeedback>{errors["IELFEM"]}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    : null}
							</Row>

                            {parseInt(fields.TIPFEM) === 1 ? 
                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <Label htmlFor="EMIFEM">País</Label>
                                        <Input 
                                            id="EMIFEM" 
                                            type="select" 
                                            name="EMIFEM"
                                            maxLength={3}    
                                            onChange={(e) => [handleChange("EMIFEM", e.target.value)]}
                                            value={fields["EMIFEM"]}
                                            invalid={errors["EMIFEM"] ? true : false}
                                        >
                                            <option value="">Seleccione</option>
                                            {countries.map((country, index) => {
                                                return <option key={index} value={country['ISO3166-1-Alpha-2']}>{country['official_name_es'] ? country['official_name_es'] : country['CLDR display name']}</option>
                                            })}
                                        </Input>
                                        <FormFeedback>{errors["EMIFEM"]}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            : null}
							<Row>
								<Col md={fields.MOVFEM === 'F' ? 6 : 12}>
									<FormGroup className="d-flex">
										<Input
											type="checkbox" 
											id="347FEM" 
											name="347FEM"
											defaultChecked={fields["347FEM"]}
											checked={fields["347FEM"]}
											onClick={(e) => handleChange("347FEM", e.target.checked ? 1 : 0)}
											invalid={errors["347FEM"] ? true : false}
										/>
											<Label className="form-check-label" htmlFor="347FEM">Incluir en volumen de operaciones</Label>
										<FormFeedback>{errors["347FEM"]}</FormFeedback>
									</FormGroup>
								</Col>
								{fields.MOVFEM === 'F' ? 
								<Col md="6">
									<FormGroup className="d-flex">
										<Input
											type="checkbox" 
											id="VBIFEM" 
											name="VBIFEM"
											defaultChecked={fields["VBIFEM"]}
											onClick={(e) => handleChange("VBIFEM", e.target.checked ? 1 : 0)}
											invalid={errors["VBIFEM"] ? true : false}
										/>
											<Label className="form-check-label" htmlFor="VBIFEM">Venta de bien de inversión</Label>
										<FormFeedback>{errors["VBIFEM"]}</FormFeedback>
									</FormGroup>
								</Col>
								: null}
							</Row>
							<Row>
								{fields.MOVFEM === 'F' ? 
								<Col md="6">
									<FormGroup className="d-flex">
										<Input
											type="checkbox" 
											id="TINFEM" 
											name="TINFEM"
											defaultChecked={fields["TINFEM"]}
											onClick={(e) => handleChange("TINFEM", e.target.checked ? 1 : 0)}
											invalid={errors["TINFEM"] ? true : false}
										/>
											<Label className="form-check-label" htmlFor="TINFEM">Trans. de inmueble sujeta a IVA</Label>
										<FormFeedback>{errors["TINFEM"]}</FormFeedback>
									</FormGroup>
								</Col>
								: null}

								{fields.MOVFEM === 'F' ? 
								<Col md="6">
									<FormGroup className="d-flex">
										<Input
											type="checkbox" 
											id="NSUFEM" 
											name="NSUFEM"
											defaultChecked={fields["NSUFEM"]}
											onClick={(e) => handleChange("NSUFEM", e.target.checked ? 1 : 0)}
											invalid={errors["NSUFEM"] ? true : false}
										/>
											<Label className="form-check-label" htmlFor="NSUFEM">Operación no sujeta a IVA</Label>
										<FormFeedback>{errors["NSUFEM"]}</FormFeedback>
									</FormGroup>
								</Col>
								: null}
							</Row>
							<Row>
								{fields.MOVFEM === 'F' ? 
								<Col md="12">
									<FormGroup className="d-flex">
										<Input
											type="checkbox" 
											id="TDOFEM" 
											name="TDOFEM"
											defaultChecked={fields["TDOFEM"]}
											onClick={(e) => handleChange("TDOFEM", e.target.checked ? 1 : 0)}
											invalid={errors["TDOFEM"] ? true : false}
										/>
											<Label className="form-check-label" htmlFor="TDOFEM">Declarar en el sistema de ventanilla única</Label>
										<FormFeedback>{errors["TDOFEM"]}</FormFeedback>
									</FormGroup>
								</Col>
								: null}
							</Row>
							<Row>
								{fields.MOVFEM === 'F' ? 
								<Col md="12">
									<FormGroup>
										<Label htmlFor="ayuda_en_el_calculo">Ayuda en el cálculo</Label>
										<Input 
											id="ayuda_en_el_calculo" 
											type="select" 
											name="ayuda_en_el_calculo"    
											onChange={(e) => handleChange("ayuda_en_el_calculo", e.target.value)}
											value={fields["ayuda_en_el_calculo"]}
											invalid={errors["ayuda_en_el_calculo"] ? true : false}
										>
											<option value="">Seleccionar</option>
											<option value="0">Manual</option>
											<option value="1">Un tipo de IVA</option>
											<option value="2">Varios tipos de IVA</option>
											<option value="3">Exenta de IVA</option>
											<option value="4">Parte exenta, con un tipo de IVA</option>
											<option value="5">Parte exenta, con varios tipos de IVA</option>
											<option value="6">Un tipo de IVA con I.R.P.F.</option>
											<option value="7">Varios tipos de IVA con I.R.P.F.</option>
											<option value="8">Exenta de IVA con I.R.P.F.</option>
											<option value="9">Parte exenta, con un tipo de IVA con I.R.P.F.</option>
											<option value="10">Parte exenta, con varios tipos de IVA con I.R.P.F.</option>
										</Input>
										<FormFeedback>{errors["ayuda_en_el_calculo"]}</FormFeedback>
									</FormGroup>
								</Col>
								: null}
							</Row>
						</Col>
					</Row>
				</Col>

				{/* Si MOVFEM === "F" cambia de ubicación el campo */}
				{fields.MOVFEM === 'I' ?
				<Col md="3">
					<Cobros
						loading={loading}
						loading2={loading2}
						loading3={loading3}
						loading4={loading4}
						loading5={loading5}
						loading6={loading6}
						loading7={loading7}
						conceptos={conceptos}
						params={params}
						setFieldsConcepto={setFieldsConcepto}
						setModalConcepto={setModalConcepto}
						deleteConcepto={deleteConcepto}
					/>
				</Col>
				: null}
			</Row>

			{fields.MOVFEM === 'F' ? <hr /> : null}

			{alert ? <Alert color="warning">{alert}</Alert> : null}
			
			<Row className="mt-4">

				{fields.MOVFEM === 'F' ?
				<Col md="8">
					<Row className="mt-4">
						<Col md="3">
							<h6 className='mb-4'>Base Imponible</h6>
							<FormGroup className="d-flex">
								<Label htmlFor="BA0FEM" className="mt-2 me-2 text-end" style={{minWidth: 45}}>Exenta</Label>
								<NumberFormat 
									id="BA0FEM" 
									type="number"
									name="BA0FEM"
									onChange={(e) => handleChange("BA0FEM", e.target.value)}
									onBlur={(e) => e.target.value === '' ? handleChange("BA0FEM", 0) : null}  
									value={fields["BA0FEM"]}
									invalid={errors["BA0FEM"] ? true : false}
									readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "2" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7"}
								/>
							</FormGroup>
							<FormGroup className="d-flex">
								<Label htmlFor="BA1FEM" className="mt-2 me-2 text-end" style={{minWidth: 45}}>1</Label>
								<NumberFormat 
									id="BA1FEM" 
									type="number" 
									name="BA1FEM"    
									onChange={(e) => handleChange("BA1FEM", e.target.value)}
									onBlur={(e) => e.target.value === '' ? handleChange("BA1FEM", 0) : null}
									value={fields["BA1FEM"]}
									invalid={errors["BA1FEM"] ? true : false}
									readOnly={fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "8"}
								/>
								<FormFeedback>{errors["BA1FEM"]}</FormFeedback>
							</FormGroup>
							<FormGroup className="d-flex">
								<Label htmlFor="BA2FEM" className="mt-2 me-2 text-end" style={{minWidth: 45}}>2</Label>
								<NumberFormat 
									id="BA2FEM" 
									type="number" 
									name="BA2FEM"    
									onChange={(e) => handleChange("BA2FEM", e.target.value)}
									onBlur={(e) => e.target.value === '' ? handleChange("BA2FEM", 0) : null}
									value={fields["BA2FEM"]}
									invalid={errors["BA2FEM"] ? true : false}
									readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
								/>
								<FormFeedback>{errors["BA2FEM"]}</FormFeedback>
							</FormGroup>
							<FormGroup className="d-flex">
								<Label htmlFor="BA3FEM" className="mt-2 me-2 text-end" style={{minWidth: 45}}>3</Label>
								<NumberFormat 
									id="BA3FEM" 
									type="number" 
									name="BA3FEM"    
									onChange={(e) => handleChange("BA3FEM", e.target.value)}
									onBlur={(e) => e.target.value === '' ? handleChange("BA3FEM", 0) : null}
									value={fields["BA3FEM"]}
									invalid={errors["BA3FEM"] ? true : false}
									readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
								/>
								<FormFeedback>{errors["BA3FEM"]}</FormFeedback>
							</FormGroup>
							
							{hasIVA4() || moreIVA >= 1 ?
							<FormGroup className="d-flex">
								<Label htmlFor="BA4FEM" className="mt-2 me-2 text-end" style={{minWidth: 45}}>4</Label>
								<NumberFormat 
									id="BA4FEM" 
									type="number" 
									name="BA4FEM"    
									onChange={(e) => handleChange("BA4FEM", e.target.value)}
									onBlur={(e) => e.target.value === '' ? handleChange("BA4FEM", 0) : null}
									value={fields["BA4FEM"]}
									invalid={errors["BA4FEM"] ? true : false}
									readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
								/>
								<FormFeedback>{errors["BA4FEM"]}</FormFeedback>
							</FormGroup>
							: null}

							{hasIVA5() || moreIVA >= 2 ?
							<FormGroup className="d-flex">
								<Label htmlFor="BA5FEM" className="mt-2 me-2 text-end" style={{minWidth: 45}}>5</Label>
								<NumberFormat 
									id="BA5FEM" 
									type="number" 
									name="BA5FEM"    
									onChange={(e) => handleChange("BA5FEM", e.target.value)}
									onBlur={(e) => e.target.value === '' ? handleChange("BA5FEM", 0) : null}
									value={fields["BA5FEM"]}
									invalid={errors["BA5FEM"] ? true : false}
									readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
								/>
								<FormFeedback>{errors["BA5FEM"]}</FormFeedback>
							</FormGroup>
							: null}
						</Col>
						<Col md="3">
							<h6 className="d-flex mb-4">% IVA <span className='d-block ms-auto'>Cuota</span></h6>
							<div style={{height: 54}}></div>
							<div className="d-flex">
								<div className="me-1">
									<FormGroup>
										<NumberFormat 
											id="PI1FEM" 
											type="number" 
											name="PI1FEM"    
											onChange={(e) => handleChange("PI1FEM", e.target.value)}
											onBlur={(e) => {checkIVA(); if(e.target.value === '') handleChange("PI1FEM", 0)}}
											value={fields["PI1FEM"]}
											invalid={errors["PI1FEM"] ? true : false}
											readOnly={fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "8"}
										/>
										<FormFeedback>{errors["PI1FEM"]}</FormFeedback>
									</FormGroup>
								</div>
								<div className="ms-1">
									<FormGroup>
										<NumberFormat 
											id="CI1FEM" 
											type="number" 
											name="CI1FEM"    
											onChange={(e) => handleChange("CI1FEM", e.target.value)}
											onBlur={(e) => e.target.value === '' ? handleChange("CI1FEM", 0) : null}
											value={fields["CI1FEM"]}
											invalid={errors["CI1FEM"] ? true : false}
											readOnly={fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "8"}
										/>
										<FormFeedback>{errors["CI1FEM"]}</FormFeedback>
									</FormGroup>
								</div>
							</div>
							<div className="d-flex">
								<div className="me-1">
									<FormGroup>
										<NumberFormat 
											id="PI2FEM" 
											type="number" 
											name="PI2FEM"    
											onChange={(e) => handleChange("PI2FEM", e.target.value)}
											onBlur={(e) => {checkIVA(); if(e.target.value === '') handleChange("PI2FEM", 0)}}
											value={fields["PI2FEM"]}
											invalid={errors["PI2FEM"] ? true : false}
											readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
										/>
										<FormFeedback>{errors["PI2FEM"]}</FormFeedback>
									</FormGroup>
								</div>
								<div className="ms-1">
									<FormGroup>
										<NumberFormat 
											id="CI2FEM" 
											type="number" 
											name="CI2FEM"    
											onChange={(e) => handleChange("CI2FEM", e.target.value)}
											onBlur={(e) => e.target.value === '' ? handleChange("CI2FEM", 0) : null}
											value={fields["CI2FEM"]}
											invalid={errors["CI2FEM"] ? true : false}
											readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
										/>
										<FormFeedback>{errors["CI2FEM"]}</FormFeedback>
									</FormGroup>
								</div>
							</div>
							<div className="d-flex">
								<div className="me-1">
									<FormGroup>
										<NumberFormat 
											id="PI3FEM" 
											type="number" 
											name="PI3FEM"    
											onChange={(e) => handleChange("PI3FEM", e.target.value)}
											onBlur={(e) => {checkIVA(); if(e.target.value === '') handleChange("PI3FEM", 0)}}
											value={fields["PI3FEM"]}
											invalid={errors["PI3FEM"] ? true : false}
											readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
										/>
										<FormFeedback>{errors["PI3FEM"]}</FormFeedback>
									</FormGroup>
								</div>
								<div className="ms-1">
									<FormGroup>
										<NumberFormat 
											id="CI3FEM" 
											type="number" 
											name="CI3FEM"    
											onChange={(e) => handleChange("CI3FEM", e.target.value)}
											onBlur={(e) => e.target.value === '' ? handleChange("CI3FEM", 0) : null}
											value={fields["CI3FEM"]}
											invalid={errors["CI3FEM"] ? true : false}
											readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
										/>
										<FormFeedback>{errors["CI3FEM"]}</FormFeedback>
									</FormGroup>
								</div>
							</div>

							{hasIVA4() || moreIVA >= 1 ?
							<div className="d-flex">
								<div className="me-1">
									<FormGroup>
										<NumberFormat 
											id="PI4FEM" 
											type="number" 
											name="PI4FEM"    
											onChange={(e) => handleChange("PI4FEM", e.target.value)}
											onBlur={(e) => {checkIVA(); if(e.target.value === '') handleChange("PI4FEM", 0)}}
											value={fields["PI4FEM"]}
											invalid={errors["PI4FEM"] ? true : false}
											readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
										/>
										<FormFeedback>{errors["PI4FEM"]}</FormFeedback>
									</FormGroup>
								</div>
								<div className="ms-1">
									<FormGroup>
										<NumberFormat 
											id="CI4FEM" 
											type="number" 
											name="CI4FEM"    
											onChange={(e) => handleChange("CI4FEM", e.target.value)}
											onBlur={(e) => e.target.value === '' ? handleChange("CI4FEM", 0) : null}
											value={fields["CI4FEM"]}
											invalid={errors["CI4FEM"] ? true : false}
											readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
										/>
										<FormFeedback>{errors["CI4FEM"]}</FormFeedback>
									</FormGroup>
								</div>
							</div>
							: null}
							
							{hasIVA5() || moreIVA >= 2 ?
							<div className="d-flex">
								<div className="me-1">
									<FormGroup>
										<NumberFormat 
											id="PI5FEM" 
											type="number" 
											name="PI5FEM"    
											onChange={(e) => handleChange("PI5FEM", e.target.value)}
											onBlur={(e) => {checkIVA(); if(e.target.value === '') handleChange("PI5FEM", 0)}}
											value={fields["PI5FEM"]}
											invalid={errors["PI5FEM"] ? true : false}
											readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
										/>
										<FormFeedback>{errors["PI5FEM"]}</FormFeedback>
									</FormGroup>
								</div>
								<div className="ms-1">
									<FormGroup>
										<NumberFormat 
											id="CI5FEM" 
											type="number" 
											name="CI5FEM"    
											onChange={(e) => handleChange("CI5FEM", e.target.value)}
											onBlur={(e) => e.target.value === '' ? handleChange("CI5FEM", 0) : null}
											value={fields["CI5FEM"]}
											invalid={errors["CI5FEM"] ? true : false}
											readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
										/>
										<FormFeedback>{errors["CI5FEM"]}</FormFeedback>
									</FormGroup>
								</div>
							</div>
							: null}
						</Col>
						<Col md="3">
							<h6 className="d-flex mb-4">% RE <span className='d-block ms-auto'>Cuota</span></h6>
							<div style={{height: 54}}></div>
							<div className="d-flex">
								<div className="me-1">
									<FormGroup>
										<NumberFormat 
											id="PR1FEM" 
											type="number" 
											name="PR1FEM"    
											onChange={(e) => handleChange("PR1FEM", e.target.value)}
											onBlur={(e) => e.target.value === '' ? handleChange("PR1FEM", 0) : null}
											value={fields["PR1FEM"]}
											invalid={errors["PR1FEM"] ? true : false}
											readOnly={fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "8"}
										/>
										<FormFeedback>{errors["PR1FEM"]}</FormFeedback>
									</FormGroup>
								</div>
								<div className="ms-1">
									<FormGroup>
										<NumberFormat 
											id="CR1FEM" 
											type="number" 
											name="CR1FEM"    
											onChange={(e) => handleChange("CR1FEM", e.target.value)}
											onBlur={(e) => e.target.value === '' ? handleChange("CR1FEM", 0) : null}
											value={fields["CR1FEM"]}
											invalid={errors["CR1FEM"] ? true : false}
											readOnly={fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "8"}
										/>
										<FormFeedback>{errors["CR1FEM"]}</FormFeedback>
									</FormGroup>
								</div>
							</div>
							<div className="d-flex">
								<div className="me-1">
									<FormGroup>
										<NumberFormat 
											id="PR2FEM" 
											type="number" 
											name="PR2FEM"    
											onChange={(e) => handleChange("PR2FEM", e.target.value)}
											onBlur={(e) => e.target.value === '' ? handleChange("PR2FEM", 0) : null}
											value={fields["PR2FEM"]}
											invalid={errors["PR2FEM"] ? true : false}
											readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
										/>
										<FormFeedback>{errors["PR2FEM"]}</FormFeedback>
									</FormGroup>
								</div>
								<div className="ms-1">
									<FormGroup>
										<NumberFormat 
											id="CR2FEM" 
											type="number" 
											name="CR2FEM"    
											onChange={(e) => handleChange("CR2FEM", e.target.value)}
											onBlur={(e) => e.target.value === '' ? handleChange("CR2FEM", 0) : null}
											value={fields["CR2FEM"]}
											invalid={errors["CR2FEM"] ? true : false}
											readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
										/>
										<FormFeedback>{errors["CR2FEM"]}</FormFeedback>
									</FormGroup>
								</div>
							</div>
							<div className="d-flex">
								<div className="me-1">
									<FormGroup>
										<NumberFormat 
											id="PR3FEM" 
											type="number" 
											name="PR3FEM"    
											onChange={(e) => handleChange("PR3FEM", e.target.value)}
											onBlur={(e) => e.target.value === '' ? handleChange("PR3FEM", 0) : null}
											value={fields["PR3FEM"]}
											invalid={errors["PR3FEM"] ? true : false}
											readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
										/>
										<FormFeedback>{errors["PR3FEM"]}</FormFeedback>
									</FormGroup>
								</div>
								<div className="ms-1">
									<FormGroup>
										<NumberFormat 
											id="CR3FEM" 
											type="number" 
											name="CR3FEM"    
											onChange={(e) => handleChange("CR3FEM", e.target.value)}
											onBlur={(e) => e.target.value === '' ? handleChange("CR3FEM", 0) : null}
											value={fields["CR3FEM"]}
											invalid={errors["CR3FEM"] ? true : false}
											readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
										/>
										<FormFeedback>{errors["CR3FEM"]}</FormFeedback>
									</FormGroup>
								</div>
							</div>
							
							{hasIVA4() || moreIVA >= 1 ?
							<div className="d-flex">
								<div className="me-1">
									<FormGroup>
										<NumberFormat 
											id="PR4FEM" 
											type="number" 
											name="PR4FEM"    
											onChange={(e) => handleChange("PR4FEM", e.target.value)}
											onBlur={(e) => e.target.value === '' ? handleChange("PR4FEM", 0) : null}
											value={fields["PR4FEM"]}
											invalid={errors["PR4FEM"] ? true : false}
											readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
										/>
										<FormFeedback>{errors["PR4FEM"]}</FormFeedback>
									</FormGroup>
								</div>
								<div className="ms-1">
									<FormGroup>
										<NumberFormat 
											id="CR4FEM" 
											type="number" 
											name="CR4FEM"    
											onChange={(e) => handleChange("CR4FEM", e.target.value)}
											onBlur={(e) => e.target.value === '' ? handleChange("CR4FEM", 0) : null}
											value={fields["CR4FEM"]}
											invalid={errors["CR4FEM"] ? true : false}
											readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
										/>
										<FormFeedback>{errors["CR4FEM"]}</FormFeedback>
									</FormGroup>
								</div>
							</div>
							: null}
							
							{hasIVA5() || moreIVA >= 2 ?
							<div className="d-flex">
								<div className="me-1">
									<FormGroup>
										<NumberFormat 
											id="PR5FEM" 
											type="number" 
											name="PR5FEM"    
											onChange={(e) => handleChange("PR5FEM", e.target.value)}
											onBlur={(e) => e.target.value === '' ? handleChange("PR5FEM", 0) : null}
											value={fields["PR5FEM"]}
											invalid={errors["PR5FEM"] ? true : false}
											readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
										/>
										<FormFeedback>{errors["PR5FEM"]}</FormFeedback>
									</FormGroup>
								</div>
								<div className="ms-1">
									<FormGroup>
										<NumberFormat 
											id="CR5FEM" 
											type="number" 
											name="CR5FEM"    
											onChange={(e) => handleChange("CR5FEM", e.target.value)}
											onBlur={(e) => e.target.value === '' ? handleChange("CR5FEM", 0) : null}
											value={fields["CR5FEM"]}
											invalid={errors["CR5FEM"] ? true : false}
											readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
										/>
										<FormFeedback>{errors["CR5FEM"]}</FormFeedback>
									</FormGroup>
								</div>
							</div>
							: null}

						</Col>
						<Col md="3">
							<h6 className="d-flex mb-4">% IRPF <span className='d-block ms-auto'>Cuota</span></h6>
							<div style={{height: 54}}></div>
							<div className='d-flex'>
								<div className='me-1'>
									<FormGroup>
										<NumberFormat 
											id="PRETFEM" 
											type="number" 
											name="PRETFEM"    
											onChange={(e) => handleChange("PRETFEM", e.target.value)}
											onBlur={(e) => e.target.value === '' ? handleChange("PRETFEM", 0) : null}
											value={fields["PRETFEM"]}
											invalid={errors["PRETFEM"] ? true : false}
											readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "2" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "5" || fields.ayuda_en_el_calculo === "7"}
										/>
										<FormFeedback>{errors["PRETFEM"]}</FormFeedback>
									</FormGroup>
								</div>
								<div className='ms-1'>
									<FormGroup>
										<NumberFormat 
											id="IRETFEM" 
											type="number" 
											name="IRETFEM"    
											onChange={(e) => handleChange("IRETFEM", e.target.value)}
											onBlur={(e) => e.target.value === '' ? handleChange("IRETFEM", 0) : null}
											value={fields["IRETFEM"]}
											invalid={errors["IRETFEM"] ? true : false}
											readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "2" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "5" || fields.ayuda_en_el_calculo === "7"}
										/>
										<FormFeedback>{errors["IRETFEM"]}</FormFeedback>
									</FormGroup>
								</div>
							</div>
							<FormGroup>
								<Label htmlFor="TRETFEM">Tipo de retención</Label>
								<Input 
									id="TRETFEM" 
									type="select" 
									name="TRETFEM"    
									onChange={(e) => handleChange("TRETFEM", e.target.value)}
									value={fields["TRETFEM"]}
									invalid={errors["TRETFEM"] ? true : false}
								>
									<option value="0">Sin definir</option>
									<option value="1">Act. prof. dineraria</option>
									<option value="2">Act. prof. especie</option>
									<option value="3">Act. agr. dineraria</option>
									<option value="4">Act. agr. especie</option>
									<option value="5">Arred. dinerario</option>
									<option value="6">Arred. especie</option>
									<option value="7">Act. empresarial</option>
								</Input>
								<FormFeedback>{errors["TRETFEM"]}</FormFeedback>
							</FormGroup>
						</Col>
					</Row>

					<Button 
						color="dark" 
						outline 
						className="d-flex align-items-center"
						onClick={() => {
						   if(moreIVA < 2) setMoreIVA(moreIVA+1) 
						}}
					>
						<span className="material-icons">add</span> Añadir línea
					</Button>

					<h6 className="mt-4">Totales</h6>
					<Row>
						<Col md="4">
							<FormGroup>
								<Label htmlFor="TOPFEM">Total operación</Label>
								<NumberFormat 
									id="TOPFEM" 
									type="number" 
									name="TOPFEM"    
									onChange={(e) => handleChange("TOPFEM", e.target.value)}
									onBlur={(e) => e.target.value === '' ? handleChange("TOPFEM", 0) : null}
									value={fields["TOPFEM"]}
									invalid={errors["TOPFEM"] ? true : false}
								/>
								<FormFeedback>{errors["TOPFEM"]}</FormFeedback>
							</FormGroup>
						</Col>
						<Col md="4">
							<FormGroup>
								<Label htmlFor="SUPFEM">Suplidos</Label>
								<NumberFormat 
									id="SUPFEM" 
									type="number" 
									name="SUPFEM"    
									onChange={(e) => handleChange("SUPFEM", e.target.value)}
									onBlur={(e) => e.target.value === '' ? handleChange("SUPFEM", 0) : null}
									value={fields["SUPFEM"]}
									invalid={errors["SUPFEM"] ? true : false}
								/>
								<FormFeedback>{errors["SUPFEM"]}</FormFeedback>
							</FormGroup>
						</Col>

						{/* Si MOVFEM === "I" el campo cambia de ubicación */}
						<Col md="4">
							<FormGroup>
								<Label htmlFor="TOTFEM">Total factura</Label>
								<NumberFormat 
									id="TOTFEM" 
									type="number" 
									name="TOTFEM"    
									onChange={(e) => handleChange("TOTFEM", e.target.value)}
									onBlur={(e) => e.target.value === '' ? handleChange("TOTFEM", 0) : null}
									value={fields["TOTFEM"]}
									invalid={errors["TOTFEM"] ? true : false}
								/>
								<FormFeedback>{errors["TOTFEM"]}</FormFeedback>
							</FormGroup>
						</Col>
					</Row>

					{/* Si MOVFEM === "I" cambia de ubicación el campo */}
					{fields.MOVFEM === 'F' ?
					<Row>
						<Col>
							<Label htmlFor="DESFEM">Observaciones</Label>
							<Input 
								id="DESFEM" 
								type="textarea" 
								name="DESFEM"   
								maxLength={50} 
								onChange={(e) => handleChange("DESFEM", e.target.value)}
								value={fields["DESFEM"]}
								invalid={errors["DESFEM"] ? true : false}
								style={{height: 100}}
							/>
							<FormFeedback>{errors["DESFEM"]}</FormFeedback>
						</Col>
					</Row>
					: null}
					
				</Col>
				: null}

				{/* Si MOVFEM === "I" cambia de ubicación el campo */}
				{fields.MOVFEM === 'F' ?
				<Col md="4">
					<Cobros
						loading={loading}
						loading2={loading2}
						loading3={loading3}
						loading4={loading4}
						loading5={loading5}
						loading6={loading6}
						loading7={loading7}
						conceptos={conceptos}
						params={params}
						setFieldsConcepto={setFieldsConcepto}
						setModalConcepto={setModalConcepto}
						deleteConcepto={deleteConcepto}
					/>
				</Col>
				: null}
			</Row>

			<br />
			<div className="d-flex">
			  <Button type="submit" color="primary" disabled={loading || loading2 || loading3 || loading4}>{!params.id ? 'Crear' : 'Editar'} {loading ? <Spinner size="sm" className="ms-1" /> : null}</Button>
			  <Button type="button" color="dark" className="ms-2" outline disabled={loading || loading2 || loading3 || loading4} onClick={() => navigate('/issued-invoices')}>Cancelar</Button>
			  {params.id ? <Button color="danger" type="button" className="ms-auto" outline disabled={loading || loading2 || loading3 || loading4} onClick={() => setModalDelete(true)}>Eliminar{loading3 ? <Spinner size="sm" className="ms-1" /> : null}</Button> : null}
			</div>
		  </Form>
		  : 
			loading2 ?
				<Loading />  
			:
				<EmptyData
				title={`No existe ${type.toLocaleLowerCase()} con código ${params.id}`}
				btn={`Crear ${type.toLocaleLowerCase()}`}
				onClick={() => [setFields({}), navigate('/issued-invoices/new')]}
				/>
		  }
		</CardBody>
	  </Card>

	  <Modal isOpen={modalDelete} toggle={() => setModalDelete(false)}>
		<ModalHeader toggle={() => setModalDelete(false)}>Eliminar usuario</ModalHeader>
		<ModalBody>
		  ¿Está seguro de que desea eliminar {fields.MOVFEM === 'F' ? 'la' : 'el'} {type.toLocaleLowerCase()} con código{fields?.CODFEM}?
		</ModalBody>
		<ModalFooter>
		  <Button
			color="danger"
			disabled={loading || loading2 || loading3}
			onClick={() => handleDelete()}
		  >
			{loading3 ? <Spinner size="sm" className="me-1"/> : null}
			{!loading3 ? 'Eliminar' : 'Eliminando...'}
		  </Button>
		  {' '}
		  <Button outline disabled={loading || loading2 || loading3} onClick={() => setModalDelete(false)}>
			Cancel
		  </Button>
		</ModalFooter>
	  </Modal>

	  <Modal size="xl" isOpen={modalCliente} toggle={() => setModalCliente(false)}>
		<ModalHeader toggle={() => setModalCliente(false)}>CREAR CLIENTE</ModalHeader>
		<CustomerForm 
			period={period} 
			source={source} 
			isModal 
			closeModal={(e) => setModalCliente(false)} 
			save={setNewCustomer}  
		/>
	  </Modal>
	  
	  <Modal size="xl" isOpen={modalSelectCliente} toggle={() => setModalSelectCliente(false)}>
		<ModalHeader toggle={() => setModalSelectCliente(false)}>SELECCIONAR CLIENTE</ModalHeader>
		<ModalBody>
			<ListGroup>
				{customersFull.map((customer, key) => {
					return (
						<ListGroupItem 
							key={key} 
							action 
							onClick={() => {
								let TIPFEM = null;
								let TIPCLI = customer.TIPCLI;
								
								switch(TIPCLI) {
									case 0:
										TIPFEM = 0;
										break;
									case 1:
										TIPFEM = 3;
										break;
									case 2:
										TIPFEM = 2;
										break;
									case 3:
										TIPFEM = 1;
										break;
									default:
										TIPFEM = null;
										break;
								}

								let CINCLI = parseInt(customer.CINCLI)
								let _349FEM = null;
							
								switch(CINCLI) {
									case 1:
										_349FEM = 'A';
										break;
									case 8:
										_349FEM = 'C';
										break;
									case 9:
										_349FEM = 'D';
										break;
									case 2:
										_349FEM = 'E';
										break;
									case 3:
										_349FEM = 'H';
										break;
									case 4:
										_349FEM = 'I';
										break;
									case 5:
										_349FEM = 'M';
										break;
									case 6:
										_349FEM = 'S';
										break;
									case 7:
										_349FEM = 'T';
										break;
								}


								setFields({
									...fields,
									NOMFEM: customer.NOMCLI,
									CLIFEM: customer.CODCLI,
									TIFFEM: customer.IFICLI,
									NIFFEM: customer.CIFCLI,
									COPFEM: customer.COPCLI,

									PI1FEM: customer.TPICLI.toString() === '0' ? 0 : (customer.TPICLI.toString() === '1' || customer.TPICLI.toString() === '4' ? 21.00 : (customer.TPICLI.toString() === '2' ? 10.00 : (customer.TPICLI.toString() === '3' ? 4.00 : (customer.TPICLI.toString() === '5' ? 10.00 : (customer.TPICLI.toString() === '6' ? 4.00 : fields.PI1FEM))))),
									PI2FEM: customer.TPICLI.toString() === '0' ? 0 : fields.PI2FEM,
									PI3FEM: customer.TPICLI.toString() === '0' ? 0 : fields.PI3FEM,
									PI4FEM: customer.TPICLI.toString() === '0' ? 0 : fields.PI4FEM,
									PI5FEM: customer.TPICLI.toString() === '0' ? 0 : fields.PI5FEM,
									PRETFEM: customer.TPICLI.toString() === '0' ? 0 : fields.PRETFEM,
									PR1FEM: customer.TPICLI.toString() === '4' ? 5.20 : (customer.TPICLI.toString() === '5' ? 1.40 : (customer.TPICLI.toString() === '6' ? 0.5 : fields.PR1FEM)),

									ACTFEM: customer.ACTCLI,
									TIPFEM: TIPFEM,
									'347FEM': customer.TIPCLI !== 3 ? 1 : 0,
									'349FEM': _349FEM,
									'ayuda_en_el_calculo': customer.ACPCLI,
									TRETFEM: customer.TRECLI.toString(),
									PRETFEM: customer.RETCLI,
								})
								setModalSelectCliente(false)
							}}
						>
							<b>{customer.CODCLI}</b> - {customer.NOMCLI}
						</ListGroupItem>
					)
				})}
			</ListGroup>
		</ModalBody>
	  </Modal>

	  <Modal isOpen={modalConcepto} toggle={() => setModalConcepto(false)} backdrop={'static'}>
		<ModalHeader toggle={() => setModalConcepto(false)}>{fieldsConcepto.LINLCP === null ? 'CREAR' : 'EDITAR'} COBRO</ModalHeader>
		<ModalBody>
			<Row>
				<Col md="6">
					<FormGroup>
						<Label htmlFor="CODLCP">Nº de Registro</Label>
						<Input 
							id="CODLCP"
							type="number" 
							name="CODLCP"    
							onChange={(e) => handleChangeConcepto("CODLCP", e.target.value)}
							value={fieldsConcepto["CODLCP"]}
							invalid={errorsConcepto["CODLCP"] ? true : false}
							readOnly
						/>
						<FormFeedback>{errorsConcepto["CODLCP"]}</FormFeedback>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col md="6">
					<FormGroup>
						<Label htmlFor="FECLCP">Fecha de cobro</Label>
						<Input 
							id="FECLCP" 
							type="date" 
							name="FECLCP"    
							onChange={(e) => handleChangeConcepto("FECLCP", e.target.value)}
							value={moment(fieldsConcepto["FECLCP"]).format('yyyy-MM-DD')}
							invalid={errorsConcepto["FECLCP"] ? true : false}
						/>
						<FormFeedback>{errorsConcepto["FECLCP"]}</FormFeedback>
					</FormGroup>
				</Col>
				<Col md="6">
					<Label>&nbsp;</Label>
					<FormGroup className="d-flex">
						<Input
							type="checkbox" 
							id="EFELCP" 
							name="EFELCP"
							// defaultChecked={fieldsConcepto["EFELCP"]}
							checked={fieldsConcepto["EFELCP"] === 1 ? true : false}
							onClick={(e) => handleChangeConcepto("EFELCP", e.target.checked ? 1 : 0)}
							invalid={errorsConcepto["EFELCP"] ? true : false}
						/>
							<Label className="form-check-label" htmlFor="EFELCP">Cobro en efectivo</Label>
						<FormFeedback>{errorsConcepto["EFELCP"]}</FormFeedback>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col md="6">
					<FormGroup>
						<Label htmlFor="IMPLCP">Importe</Label>
						<NumberFormat 
							id="IMPLCP" 
							type="number" 
							name="IMPLCP"    
							onChange={(e) => handleChangeConcepto("IMPLCP", e.target.value)}
							value={fieldsConcepto["IMPLCP"]}
							invalid={errorsConcepto["IMPLCP"] ? true : false}
						/>
						<FormFeedback>{errorsConcepto["IMPLCP"]}</FormFeedback>
					</FormGroup>
				</Col>
			</Row>

			<br />
			<div className="d-flex justify-content-end">
			  <Button type="button" color="primary" disabled={loading || loading2 || loading3 || loading4} onClick={() => saveConcepto()}>Aceptar {loading6 ? <Spinner size="sm" className="ms-1" /> : null}</Button>
			  <Button type="button" color="dark" className="ms-2" outline disabled={loading || loading2 || loading3 || loading4} onClick={() => setModalConcepto(false)}>Cancelar</Button>
			</div>
		</ModalBody>
	  </Modal>
	</>
  );
}