import * as React from 'react';
import { Card, CardBody, CardTitle, CardHeader, Alert, Row, Col, Form, FormGroup, FormFeedback, Input, Label, Button, Spinner } from 'reactstrap';
import axios from 'axios';

export default function Profile() {
  const [fields, setFields] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [message, setMessage] = React.useState(null);

  React.useEffect(() => {
    setLoading2(true);
    axios.get('/api/auth/profile/')
      .then(res => {
        setLoading2(false);
        setFields(res.data);
      })
      .catch(err => {
        setLoading2(false);
        setMessage({color: 'danger', text: err.response.data.detail});
      })
  }, [])

  const handleChange = (field, value) => {
    setFields({ ...fields, [field]: value });
  }

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    //first_name
    if (!fields['first_name']) {
      formIsValid = false;
      errors['first_name'] = 'Campo obligatorio.';
    }

    //last_name
    if (!fields['last_name']) {
      formIsValid = false;
      errors['last_name'] = 'Campo obligatorio.';
    }

    //email
    if (!fields['email']) {
      formIsValid = false;
      errors['email'] = 'Campo obligatorio.';
    }

    setErrors(errors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      setProfile()
    }
  }

  const setProfile = () => {
    setMessage();
    setLoading(true);
    axios.put('/api/auth/profile/', {...fields, username: fields.email})
      .then(res => {
        setLoading(false);
        setMessage({color: 'success', text: 'Perfil actualizado con éxito'});
      })
      .catch(err => {
        setLoading(false);
        if(err.response.data.detail) {
          setMessage({color: 'danger', text: err.response.data.detail});
        } else {
          let errors = {};
          for(let key in err.response.data) {
            errors[key] = err.response.data[key];
          }
          setErrors(errors)
        }
      })
  }

  return (
    <Card>
      <CardBody>
        <CardTitle>Perfil</CardTitle>
        {message ? <Alert color={message.color}>{message.text}</Alert> : null}
        <Form onSubmit={handleSubmit} noValidate>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="first_name">Nombre</Label>
                <Input 
                  id="first_name" 
                  type="text" 
                  name="first_name"    
                  onChange={(e) => handleChange("first_name", e.target.value)}
                  value={fields["first_name"]}
                  invalid={errors["first_name"] ? true : false}
                />
                <FormFeedback>{errors["first_name"]}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="last_name">Apellido</Label>
                <Input 
                  id="last_name" 
                  type="text" 
                  name="last_name"    
                  onChange={(e) => handleChange("last_name", e.target.value)}
                  value={fields["last_name"]}
                  invalid={errors["last_name"] ? true : false}
                />
                <FormFeedback>{errors["last_name"]}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="email">Correo</Label>
                <Input 
                  id="email" 
                  type="text" 
                  name="email"    
                  onChange={(e) => handleChange("email", e.target.value)}
                  value={fields["email"]}
                  invalid={errors["email"] ? true : false}
                  
                />
                <FormFeedback>{errors["email"]}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Button type="submit" color="primary" disabled={loading2}>Guardar{loading ? <Spinner size="sm" className="ms-1" /> : null}</Button>
        </Form>
      </CardBody>
    </Card>
  );
}