import * as React from 'react';
import { Card, CardBody, CardTitle, CardHeader, Row, Col, Table, Badge, Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loading from '../components/Loading';
import EmptyData from '../components/EmptyData';
import Pagination from '../components/Pagination';

export default function User() {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [modalDelete, setModalDelete] = React.useState(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  React.useEffect(() => {
    getData();
  }, [searchParams])

  const getData = () => {
    setLoading(true);
    axios.get(`/api/users/?page=${searchParams.get('page') ? searchParams.get('page') : '1'}`)
      .then(res => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if(err.response.status === 404 && parseInt(searchParams.get('page')) > 1) setSearchParams({page: 1})
      })
  }

  const handleDelete = (e, id) => {
    e.preventDefault();
    setLoading2(true);

    axios.delete(`/api/users/${id}/`)
      .then(res => {
        setLoading2(false);
        setModalDelete(null);
        // const newData = data.filter(item => item.id !== id);
        getData();
        toast('Usuario eliminado con éxito', {type: 'success'});
      })
      .catch(err => {
        setLoading2(false);
        setModalDelete(null);
        toast(err.response.data.detail, {type: 'error'});
      })
  }

  return (
    <Card>
      <CardHeader className="border-0 pb-0">
        <Row>
            <Col><CardTitle>Usuarios</CardTitle></Col>
            <Col className="d-flex justify-content-end">
              <div>
                <Button size="sm" color="primary" className="d-flex align-items-center" onClick={() => navigate('/users/new')}><span className="material-icons">add</span> Crear usuario</Button>
              </div>
            </Col>
          </Row>
      </CardHeader>
      <CardBody>
        {!loading ?
          data.count > 0 ?
          <>
            <Table responsive>
              <thead>
                <tr>
                  <th>Imagen</th>
                  <th>Nombre</th>
                  <th>Apellido</th>
                  <th>Email</th>
                  <th align="center">Estado</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {data.results.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td valign="middle">
                        <div 
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 50,
                            height: 50,
                            borderRadius: '50%',
                            border: '1px solid #ced4da',
                            background: '#eee',
                            backgroundImage: `url(${item.profile?.image ? item.profile?.image : ''})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            position: 'relative'
                          }}
                        />
                      </td>
                      <td valign="middle">{item.first_name}</td>
                      <td valign="middle">{item.last_name}</td>
                      <td valign="middle">{item.email}</td>
                      <td valign="middle"><Badge color={item.is_active ? 'primary' : 'danger'} pill>{item.is_active ? 'Activo' : 'Desactivado'}</Badge></td>
                      <td valign="middle">
                        <Button size="sm" outline className="pb-0 me-1" onClick={() => navigate(`/users/${item.id}`)}><span className="material-icons fs-6">edit</span></Button>
                        <Button size="sm" color="danger" outline className="pb-0 me-1" onClick={() => setModalDelete(item.id)}><span className="material-icons fs-6">delete</span></Button>
                        
                        <Modal isOpen={modalDelete === item.id} toggle={() => setModalDelete(null)}>
                          <ModalHeader toggle={() => setModalDelete(null)}>Eliminar usuario</ModalHeader>
                          <ModalBody>
                            ¿Está seguro de que desea eliminar el usuario {item.email}?
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              type="button"
                              color="danger"
                              disabled={loading2}
                              onClick={(e) => handleDelete(e, item.id)}
                            >
                              {loading2 ? <Spinner size="sm" className="me-1"/> : null}
                              {!loading2 ? 'Eliminar' : 'Eliminando...'}
                            </Button>
                            {' '}
                            <Button outline disabled={loading2} onClick={() => setModalDelete(null)}>
                              Cancel
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            <Pagination data={data} />
          </>
          :
            <EmptyData
              title="No hay usuarios registrados"
              btn="Crear usuario"
              onClick={() => navigate('/users/new')}
            />
        : 
            <Loading />
        }
      </CardBody>
    </Card>
  );
}