import * as React from 'react';
import { Card, CardBody, CardTitle, Row, Col, Spinner } from 'reactstrap';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import NumberFormat from '../components/NumberFormat';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const labels = ['Ingresos acumulados', 'Gastos acumulados', 'Beneficio acumulado'];

const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem) {
                        return tooltipItem.yLabel;
                }
            }
        }
    },
};

const initialValues = {
    investments: '-',
    accumulated: {
        labels,
        datasets: [
            {
                label: 'Acumulados',
                data: [],
                backgroundColor: ['#023684', '#1351af', '#3074d9'],
            }
        ],
    },
    vat: [],
    income_tax_payment: [],
    income_tax: [],
    withholdings_supported: '-',
}

export default function Dashboard({ period }) {
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState(initialValues)
    

    React.useEffect(() => {
        if(!period) return;
        console.log('period', period)
        setLoading(true);
        axios.get(`/api/dashboard/?ejercicio=${period}`)
            .then((res) => {
                setData({
                    ...data,
                    investments: res.data.investments,
                    accumulated: {
                        labels,
                        datasets: [
                            {
                                label: 'Acumulados',
                                data: [res.data.accumulated_income, res.data.accumulated_expenses, res.data.accumulated_profit],
                                backgroundColor: ['#023684', '#1351af', '#3074d9'],
                            }
                        ],
                    },
                    vat: res.data.vat,
                    income_tax_payment: res.data.income_tax_payment,
                    income_tax: res.data.income_tax,
                    withholdings_supported: res.data.withholdings_supported,
                });
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            })
    }, [period])

  return (
    <React.Fragment>
        <Row>
            {/* <Col md="3">
                <Card>
                    <CardBody>
                        <div className="d-flex align-items-center">
                            <div>
                                <div className="d-flex justify-content-center align-items-center" style={{backgroundColor: '#023684', width: 50, height: 50, borderRadius: '50%'}}>
                                    <span className="material-icons text-white">attach_money</span>
                                </div>
                            </div>
                            <div className="ps-2">
                                <p className="text-uppercase m-0">Ingresos acumulados</p>
                                <h5 className="m-0">{data.accumulated_income !== null ? data.accumulated_income : '-'}</h5>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col> */}
            {/* <Col md="3">
                <Card>
                    <CardBody>
                        <div className="d-flex align-items-center">
                            <div>
                                <div className="d-flex justify-content-center align-items-center" style={{backgroundColor: '#1351af', width: 50, height: 50, borderRadius: '50%'}}>
                                    <span className="material-icons text-white">payments</span>
                                </div>
                            </div>
                            <div className="ps-2">
                                <p className="text-uppercase m-0">Gastos acumulados</p>
                                <h5 className="m-0">{data.accumulated_expenses !== null ? data.accumulated_expenses : '-'}</h5>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col> */}
            {/* <Col md="3">
                <Card>
                    <CardBody>
                        <div className="d-flex align-items-center">
                            <div>
                                <div className="d-flex justify-content-center align-items-center" style={{backgroundColor: '#3074d9', width: 50, height: 50, borderRadius: '50%'}}>
                                    <span className="material-icons text-white">savings</span>
                                </div>
                            </div>
                            <div className="ps-2">
                                <p className="text-uppercase m-0">Beneficio acumulado</p>
                                <h5 className="m-0">{data.accumulated_profit !== null ? data.accumulated_profit : '-'}</h5>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col> */}
            {/* <Col md="3">
                <Card>
                    <CardBody>
                        <div className="d-flex align-items-center">
                            <div>
                                <div className="d-flex justify-content-center align-items-center" style={{backgroundColor: '#599bff', width: 50, height: 50, borderRadius: '50%'}}>
                                    <span className="material-icons text-white">account_balance_wallet</span>
                                </div>
                            </div>
                            <div className="ps-2">
                                <p className="text-uppercase m-0">Inversiones</p>
                                <h5 className="m-0">{data.investments !== null ? data.investments : '-'}</h5>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col> */}
        </Row>
        <Row>
            <Col md="4">
                <Card>
                    <CardBody>
                        <div className="d-flex align-items-center mb-2">
                            <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-center align-items-center" style={{backgroundColor: '#023684', width: 50, height: 50, borderRadius: '50%'}}>
                                    <span className="material-icons text-white">currency_exchange</span>
                                </div>
                                <h5 className="m-0 ms-2">IVA</h5>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <div className="">
                                <p className="m-0"><small>1º trim.</small></p>
                                <p className="m-0 fw-bold">{data?.vat[0] !== null ? <NumberFormat value={data?.vat[0]} displayType={'text'} /> : '-'}</p>
                            </div>
                            <div className="">
                                <p className="m-0"><small>2º trim.</small></p>
                                <p className="m-0 fw-bold">{data?.vat[1] !== null ? <NumberFormat value={data?.vat[1]} displayType={'text'} /> : '-'}</p>
                            </div>
                            <div className="">
                                <p className="m-0"><small>3º trim.</small></p>
                                <p className="m-0 fw-bold">{data?.vat[2] !== null ? <NumberFormat value={data?.vat[2]} displayType={'text'} /> : '-'}</p>
                            </div>
                            <div className="">
                                <p className="m-0"><small>4º trim.</small></p>
                                <p className="m-0 fw-bold">{data?.vat[3] !== null ? <NumberFormat value={data?.vat[3]} displayType={'text'} /> : '-'}</p>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col md="4">
                <Card>
                    <CardBody>
                        <div className="d-flex align-items-center mb-2">
                            <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-center align-items-center" style={{backgroundColor: '#1351af', width: 50, height: 50, borderRadius: '50%'}}>
                                    <span className="material-icons text-white">payments</span>
                                </div>
                                <h5 className="m-0 ms-2">Pago a cuenta de IRPF</h5>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="">
                                <p className="m-0"><small>1º trim.</small></p>
                                <p className="m-0 fw-bold">{data?.income_tax_payment[0] !== null ? <NumberFormat value={data?.income_tax_payment[0]} displayType={'text'} /> : '-'}</p>
                            </div>
                            <div className="">
                                <p className="m-0"><small>2º trim.</small></p>
                                <p className="m-0 fw-bold">{data?.income_tax_payment[1] !== null ? <NumberFormat value={data?.income_tax_payment[1]} displayType={'text'} /> : '-'}</p>
                            </div>
                            <div className="">
                                <p className="m-0"><small>3º trim.</small></p>
                                <p className="m-0 fw-bold">{data?.income_tax_payment[2] !== null ? <NumberFormat value={data?.income_tax_payment[2]} displayType={'text'} /> : '-'}</p>
                            </div>
                            <div className="">
                                <p className="m-0"><small>4º trim.</small></p>
                                <p className="m-0 fw-bold">{data?.income_tax_payment[3] !== null ? <NumberFormat value={data?.income_tax_payment[3]} displayType={'text'} /> : '-'}</p>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col md="4">
                <Card>
                    <CardBody>
                        <div className="d-flex align-items-center mb-2">
                            <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-center align-items-center" style={{backgroundColor: '#3074d9', width: 50, height: 50, borderRadius: '50%'}}>
                                    <span className="material-icons text-white">attach_money</span>
                                </div>
                                <h5 className="m-0 ms-2">Retenciones practicadas</h5>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <div className="">
                                <p className="m-0"><small>1º trim.</small></p>
                                <p className="m-0 fw-bold">{data?.income_tax[0] !== null ? <NumberFormat value={data?.income_tax[0]} displayType={'text'} /> : '-'}</p>
                            </div>
                            <div className="">
                                <p className="m-0"><small>2º trim.</small></p>
                               <p className="m-0 fw-bold">{data?.income_tax[1] !== null ? <NumberFormat value={data?.income_tax[1]} displayType={'text'} /> : '-'}</p>
                            </div>
                            <div className="">
                                <p className="m-0"><small>3º trim.</small></p>
                               <p className="m-0 fw-bold">{data?.income_tax[2] !== null ? <NumberFormat value={data?.income_tax[2]} displayType={'text'} /> : '-'}</p>
                            </div>
                            <div className="">
                                <p className="m-0"><small>4º trim.</small></p>
                               <p className="m-0 fw-bold">{data?.income_tax[3] !== null ? <NumberFormat value={data?.income_tax[3]} displayType={'text'} /> : '-'}</p>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        <Row>
            <Col md="6">
                <Card>
                    <CardBody>
                        <div className="d-flex">
                            <div>
                                <CardTitle className="mb-0">Inversiones</CardTitle>
                                {/* <p>a mi favor</p> */}
                                <h2 className="m-0">{data.investments !== null ? <NumberFormat value={data.investments} displayType={'text'} /> : '-'}</h2>
                            </div>
                            <div className="d-flex justify-content-center align-items-center ms-auto" style={{backgroundColor: '#599bff', width: 100, height: 100, borderRadius: 15}}>
                                <span className="material-icons text-white" style={{fontSize: 60}}>account_balance_wallet</span>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col md="6">
                <Card>
                    <CardBody>
                        <div className="d-flex">
                            <div>
                                <CardTitle className="mb-0">Retenciones soportadas</CardTitle>
                                <p>a mi favor</p>
                                <h2 className="m-0">{data.withholdings_supported !== null ? <NumberFormat value={data.withholdings_supported} displayType={'text'} /> : '-'}</h2>
                            </div>
                            <div className="bg-primary d-flex justify-content-center align-items-center ms-auto" style={{width: 100, height: 100, borderRadius: 15}}>
                                <span className="material-icons text-white" style={{fontSize: 60}}>paid</span>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        <Row>
            <Col md="6">
                <Card>
                    <CardBody>
                        <CardTitle>INGRESOS, GASTOS y BENEFICIO ACUMULADO</CardTitle>
                        <Bar options={options} data={data.accumulated} />
                    </CardBody>
                </Card>
            </Col>
        </Row>
        
        {loading ? 
        <div className="d-flex justify-content-center align-items-center" style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1000, backgroundColor: '#00000040'}}>
            <Spinner color="white" />
        </div>
        : null}
    </React.Fragment>
  );
}