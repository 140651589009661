import * as React from "react";
import { Link } from "react-router-dom";
import { Spinner} from 'reactstrap';

export default function Loading({ text }) {
    return (
        <div className="d-flex justify-content-center align-items-center">
            <Spinner color="primary" size="sm" className="me-2"/>
            {!text ? 'Cargando...' : text}
        </div>
    );
}