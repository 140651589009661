export default [
    'Operación habitual',
    'A - Asiento resumen de facturas',
    'B - Asiento resumen de tiques',
    'C - Factura con varios asientos (varios tipos impositivos)',
    'D - Factura rectificativa',
    'E - IVA devengado pendiente de emitir factura',
    'F - Adquisiciones realizadas por las agencias de viajes directamente en interés del viajero',
    'G - Régimen especial de grupo de entidades en IVA / IGIC',
    'H - Régimen especial de oro de inversión',
    'I - Inversión del Sujeto pasivo (ISP)',
    'J - Tiques',
    'K - Rectificación anotaciones registrales',
    'L - Adquisiciones a comerciantes minoristas del IGIC',
    'M - IVA facturado pendiente de devengar (emitida factura)',
    'N - Facturación de las prestaciones de servicios de agencias de viaje que actúan como mediadoras en nombre y por cuenta ajena',
    'O - Factura emitida en sustitución de tiques facturados y declarados',
    'P - Adquisiciones intracomunitarias de bienes',
    'Q - Operaciones a las que se aplique el régimen especial de bienes usados',
    'R - Operación de arrendamiento de local de negocio',
    'S - Subvenciones, auxilios o ayudas satisfechas o recibidas, tanto por parte de Administraciones públicas como de entidades privadas',
    'T - Cobros por cuenta de terceros',
    'U - Operación de seguros',
    'V - Compras de agencias viajes: prestación de servicios de mediación en nombre y por cuenta ajena relativos a servicios de transporte',
    'W - Operación sujeta al Impuesto sobre la Producción, los Servicios y la Importación en las Ciudades de Ceuta y Melilla',
    'X - Oper. por las que los empresarios o prof. que satisfagan compensaciones agríc., ganad. y/o pesqueras hayan expedido recibo',
    'Z – Operación genérica de IVA de CAJA'
]