import axios from 'axios';
import * as React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Label, Row, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Table, ListGroup, ListGroupItem } from 'reactstrap';
import moment from 'moment';
import CustomerForm from './CustomerForm';
import Loading from '../components/Loading';
import EmptyData from '../components/EmptyData';
import Invoice from '../components/Invoice';
import { isEmpty, validEmail, validURL } from '../lib/validator';
import NumberFormat from '../components/NumberFormat';

const initialValues = {
    FECFAC: moment(),
    COBFAC: '0',

    NETFAC: 0,
    PDEFAC: 0,
    IDEFAC: 0,
    BASFAC: 0,
    IVPFAC: 0,
    IVCFAC: 0,
    REPFAC: 0,
    RECFAC: 0,
    IRPFAC: 0,
    IRCFAC: 0,
    SUPFAC: 0,
    TOTFAC: 0,
}

const initialValuesConcepto = {
    ORDLFA: null,
    CANLFA: '1',
    PUNLFA: 0,
    TOTLFA: 0,
    DESLFA: '',
}

export default function BillingForm({ period, source }) {
  const [fields, setFields] = React.useState(initialValues);
  const [fieldsConcepto, setFieldsConcepto] = React.useState(initialValuesConcepto);
  const [errors, setErrors] = React.useState({});
  const [errorsConcepto, setErrorsConcepto] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [loading3, setLoading3] = React.useState(false);
  const [loading4, setLoading4] = React.useState(false);
  const [loading5, setLoading5] = React.useState(false);
  const [loading6, setLoading6] = React.useState(false);
  const [loading7, setLoading7] = React.useState(null);
  const [loading8, setLoading8] = React.useState(null);
  const [loading9, setLoading9] = React.useState(null);
  const [modalDelete, setModalDelete] = React.useState(false);
  const [conceptos, setConceptos] = React.useState([]);
  const [tiposDeIngresos, setTiposDeIngresos] = React.useState([]);
  const [customers, setCustomers] = React.useState([]);
  const [customersFull, setCustomersFull] = React.useState([]);
  const [customer, setCustomer] = React.useState(null);
  const [modalCliente, setModalCliente] = React.useState(false);
  const [modalSelectCliente, setModalSelectCliente] = React.useState(false);
  const [modalConcepto, setModalConcepto] = React.useState(false);
  const [invoice, setInvoice] = React.useState(null);
  const [actividades, setActividades] = React.useState([]);

  const params = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    getData();
  }, [period, params.id])

  React.useEffect(() => {
    if(fieldsConcepto.CANLFA && fieldsConcepto.PUNLFA) setFieldsConcepto({...fieldsConcepto, TOTLFA: parseFloat(fieldsConcepto.CANLFA) * parseFloat(fieldsConcepto.PUNLFA)});
  }, [fieldsConcepto.CANLFA, fieldsConcepto.PUNLFA])

//   React.useEffect(() => {
//     if(fields.CLIFAC && customersFull.length > 0) handleChangeNOMFAC({value: fields.CLIFAC})
//   }, [fields.CLIFAC, customersFull])

  const getData = () => {
    getCustomers()

    if(params.id && params.serie) {
        setLoading2(true);
        setLoading5(true);
        axios.get(`/api/billings/${params.id ? params.id +'/' : ''}?ejercicio=${period}&filtro=CODFAC=${params.id} AND TIPFAC=${params.serie}`, {
            cancelToken: source.token
          })
          .then(res => {
            setLoading2(false);
            setFields(res.data.CODFAC ? res.data : null);

            getConceptos(res.data.TIPFAC, res.data.CODFAC);
          })

          getInvoice();
    } else {
        setFields({ ...fields });
        // setLoading4(true)
        // axios.get(`/api/billings/last_id/?ejercicio=${period}`, {
        //     cancelToken: source.token
        //   })
        //     .then(res => {
        //         setLoading4(false);
        //         setFields({...fields, CODFAC: res.data});
        //     })
    }

    axios.get(`/api/billings/actividades/?ejercicio=${period}&filtro=CODACT>=0`, {
        cancelToken: source.token
      })
        .then(res => {
            setActividades(res.data);
        })
  }

  const getInvoice = () => {
    setLoading9(true);
    axios.get(`/api/billings/${params.id}/invoice/?ejercicio=${period}&filtro=CODFAC=${params.id} AND TIPFAC=${params.serie}`, {
        cancelToken: source.token
      })
      .then(res => {
        setInvoice(res.data)
        setLoading9(false);
      })
      .catch(() => {
        setLoading9(false);
      });
  }

  const getLastId = (TIPFAC) => {
    setLoading4(true)
    axios.get(`/api/billings/last_id/?ejercicio=${period}&TIPFAC=${TIPFAC}`, {
        cancelToken: source.token
      })
        .then(res => {
            setLoading4(false);
            setFields({...fields, TIPFAC: TIPFAC, CODFAC: res.data});
        })
  }

  const setNewCustomer = (data) => {
    getCustomers()
    setFields({ 
        ...fields,
        'NOMFAC': data.NOMCLI,
        'CLIFAC': data.CODCLI,
        'TIFFAC': data.IFICLI,
        'NIFFAC': data.CIFCLI,
        'COPFAC': data.COPCLI,

        'IVPFAC': data.TPICLI.toString() === '0' ? 0 : (data.TPICLI.toString() === '1' ? 21.00 : (data.TPICLI.toString() === '2' ? 10.00 : (data.TPICLI.toString() === '3' ? 4.00 : (data.TPICLI.toString() === '5' ? 10.00 : (data.TPICLI.toString() === '6' ? 4.00 : fields.IVPFAC))))),
        // 'IRPFAC': data.TPICLI.toString() === '0' ? 0 : fields.IRPFAC,
        'IRPFAC': data.RETCLI,
        'REPFAC': data.TPICLI.toString() === '4' ? 5.20 : (data.TPICLI.toString() === '5' ? 1.40 : (data.TPICLI.toString() === '6' ? 0.5 : fields.REPFAC)),
    });
    setCustomers(data);
  }

  const getCustomers = (callback) => {
    axios.get(`/api/billings/customers/?ejercicio=${period}&filtro=CODCLI>=0 ORDER BY CODCLI DESC`, {
        cancelToken: source.token
        })
        .then(res => {
            let customers = [];
            res.data.forEach(customer => {
                customers.push({
                    value: customer.CODCLI,
                    label: customer.NOMCLI
                })
            })
            setCustomersFull(res.data);
            setCustomers(customers);
            if(callback) callback(res.data, customers);
        })
  }

  const getConceptos = (TIPLFA, FACLFA, callback) => {
    axios.get(`/api/concepts/?ejercicio=${period}&filtro= TIPLFA=${TIPLFA} AND FACLFA=${FACLFA} ORDER BY ORDLFA DESC`, {
        cancelToken: source.token
      })
        .then(res => {
            setConceptos(res.data);
            setLoading5(false);
            if(callback) callback(res.data);
        })     
        .catch((err) => {
            setLoading5(false);
            if(callback) callback();
        })  
  }

  const handleChange = (field, value) => {
    setFields({ ...fields, [field]: value });
  }

  const handleChangeConcepto = (field, value) => {
    setFieldsConcepto({ ...fieldsConcepto, [field]: value });
  }

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (isEmpty(fields['TIPFAC'])) {
        formIsValid = false;
        errors['TIPFAC'] = 'Campo obligatorio.';
    }

    if (isEmpty(fields['CODFAC'])) {
        formIsValid = false;
        errors['CODFAC'] = 'Campo obligatorio.';
    }

    if (isEmpty(fields['NETFAC'])) {
        formIsValid = false;
        errors['NETFAC'] = 'Campo obligatorio.';
    }

    if (isEmpty(fields['PDEFAC'])) {
        formIsValid = false;
        errors['PDEFAC'] = 'Campo obligatorio.';
    }

    if (isEmpty(fields['IDEFAC'])) {
        formIsValid = false;
        errors['IDEFAC'] = 'Campo obligatorio.';
    }

    if (isEmpty(fields['BASFAC'])) {
        formIsValid = false;
        errors['BASFAC'] = 'Campo obligatorio.';
    }

    if (isEmpty(fields['IVPFAC'])) {
        formIsValid = false;
        errors['IVPFAC'] = 'Campo obligatorio.';
    }
    if (isEmpty(fields['IVCFAC'])) {
        formIsValid = false;
        errors['IVCFAC'] = 'Campo obligatorio.';
    }

    if (isEmpty(fields['REPFAC'])) {
        formIsValid = false;
        errors['REPFAC'] = 'Campo obligatorio.';
    }

    if (isEmpty(fields['RECFAC'])) {
        formIsValid = false;
        errors['RECFAC'] = 'Campo obligatorio.';
    }

    if (isEmpty(fields['IRPFAC'])) {
        formIsValid = false;
        errors['IRPFAC'] = 'Campo obligatorio.';
    }

    if (isEmpty(fields['IRCFAC'])) {
        formIsValid = false;
        errors['IRCFAC'] = 'Campo obligatorio.';
    }

    if (isEmpty(fields['SUPFAC'])) {
        formIsValid = false;
        errors['SUPFAC'] = 'Campo obligatorio.';
    }

    if (isEmpty(fields['TOTFAC'])) {
        formIsValid = false;
        errors['TOTFAC'] = 'Campo obligatorio.';
    }

    setErrors(errors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      setInvoiceIssued()
    }
  }

  const setDefaultIfEmpty = () => {
    let newFields = { ...fields };
    for(let field in fields) {
        if(isEmpty(fields[field])) {
            newFields[field] = initialValues[field];
        }
    }
    setFields(newFields);
    return newFields;
  }

  const setInvoiceIssued = () => {
    setLoading(true);

    // let fields = setDefaultIfEmpty();

    axios[!params.id ? 'post' : 'put'](`/api/billings/${params.id ? params.id +'/' : ''}?ejercicio=${period}`, {...fields, CODFAC: fields.CODFAC})
      .then(res => {
        setLoading(false);
        toast(!params.id ? 'Factura creada con éxito' : 'Factura actualizada con éxito', {type: 'success'});
        getInvoice();
        if(!params.id) navigate(`/billings/${res.data.CODFAC}/${res.data.TIPFAC}`)
      })
      .catch((err) => {
        err.response.data.forEach((error, key) => {
            setLoading(false);
            toast(error, {type: 'error'});
        })
      })
  }

  const invoiceTransfer = () => {
    setLoading8(true);

    axios.get(`/api/billings/customers/?ejercicio=${period}&filtro=CODCLI=${fields.CLIFAC} ORDER BY CODCLI DESC`, {
        cancelToken: source.token
        })
        .then(res => {
            axios.get(`/api/issued-invoices/last_id/?ejercicio=${period}`, {
                cancelToken: source.token
              })
                .then(res2 => {
                    let CODFEM = res2.data;

                    let TIPFEM = null;
                    let TIPCLI = res.data[0]?.TIPCLI;
                    
                    switch(TIPCLI) {
                        case 0:
                            TIPFEM = 0;
                            break;
                        case 1:
                            TIPFEM = 3;
                            break;
                        case 2:
                            TIPFEM = 2;
                            break;
                        case 3:
                            TIPFEM = 1;
                            break;
                        default:
                            TIPFEM = null;
                            break;
                    }

                    console.clear()
                    let ___347FEM =  TIPFEM === 2 ? 1 : ((TIPFEM === 0 && res.data[0]?.TRECLI === 0) || (TIPFEM === 0 && res.data[0]?.TRECLI === 0 ) ? 1 : 0);
                    console.log('TIPFEM', TIPFEM)
                    console.log('res.data[0]?.TRECLI', res.data[0]?.TRECLI)
                    console.log('___347FEM', ___347FEM)
                    
                    let data = {
                        'MOVFEM': 'F',
        
                        'CODFEM': CODFEM,
                        'ACTFEM': fields.TIPFAC,
                        'CLIFEM': fields.CLIFAC,
                        'FECFEM': fields.FECFAC,
                        'FEXFEM': fields.FECFAC,
                        'RCCFEM': fields.RCCFAC,
                        'PI1FEM': fields.IVPFAC,
                        'CI1FEM': fields.IVCFAC,
                        'PR1FEM': fields.REPFAC,
                        'CR1FEM': fields.RECFAC,
                        'PRETFEM': fields.IRPFAC,
                        'IRETFEM': fields.IRCFAC,
                        'SUPFEM': fields.SUPFAC,
                        'TOTFEM': fields.TOTFAC,
                        'FACFEM': fields.CODFAC,
                        'SERFEM': fields.TIPFAC,
                
                
                        'NOMFEM': res.data[0]?.NOMCLI,
                        'TIFFEM': res.data[0]?.IFICLI,
                        'NIFFEM': res.data[0]?.CIFCLI,
                        'COPFEM': res.data[0]?.COPCLI,
                        // 'TIPFEM': res.data[0]?.TIPCLI,
                        'TIPFEM': TIPFEM,
                        'TOIFEM': res.data[0]?.CINCLI,
                        'TRETFEM': res.data[0]?.TRECLI,
                        'TREFEM': res.data[0]?.TGICLI,
                        '347FEM': TIPFEM === 2 ? 1 : ((TIPFEM === 0 && res.data[0]?.TRECLI === 0) || (TIPFEM === 0 && res.data[0]?.TRECLI === 0 ) ? 1 : 0),
                        'IELFEM': TIPFEM === 2 ? 1 : 0,
                    }
        
                    // Corregir, cuando cuota IVA = 0,00 entonces cuando se haga el traspaso de F_FAC a F_FEM el importe de la Base Imponible debe pasar a BA0FEM y no BA1FEM como hace hasta ahora. Esto es porque sería una factura EXENTA de IVA.
                    if(fields.IVPFAC === 0) {
                        data.BA0FEM = fields.BASFAC;
                    } else {
                        data.BA1FEM = fields.BASFAC;
                    }
        
                
                    axios[true ? 'post' : 'put'](`/api/issued-invoices/${false ? params.id +'/' : ''}?ejercicio=${period}`, data)
                      .then(res => {
                        setLoading8(false);
                        toast(!params.id ? 'Traspaso de factura éxitoso' : 'Traspaso de factura éxitoso', {type: 'success'});
                        navigate(`/issued-invoices/${res.data.CODFEM}/?period=${period}`)
                      })
                      .catch((err) => {
                        err.response.data.forEach((error, key) => {
                            setLoading8(false);
                            toast(error, {type: 'error'});
                        })
                      })
                })
        })
  }

  const handleDelete = () => {
    setLoading3(true);

    axios.delete(`/api/billings/${params.id}/?ejercicio=${period}&filtroDelete=TIPFAC=${fields.TIPFAC} AND CODFAC=${fields.CODFAC}&filtro=TIPLFA=${fields.TIPFAC} AND FACLFA=${fields.CODFAC}`)
      .then(res => {
        setLoading3(false);
        toast('Factura eliminada con éxito', {type: 'success'});
        navigate(`/billings/`)
      })
  }

  const saveConcepto = () => {
    setLoading6(true);

    axios[fieldsConcepto.ORDLFA === null ? 'post' : 'put'](`/api/concepts/${fieldsConcepto.ORDLFA !== null ? fieldsConcepto.ORDLFA +'/' : ''}?ejercicio=${period}`, {...fieldsConcepto, TIPLFA: fields.TIPFAC, FACLFA: fields.CODFAC, ORDLFA: fieldsConcepto.ORDLFA !== null ? fieldsConcepto.ORDLFA : (conceptos.length ? conceptos[0].ORDLFA+1 : 0)})
      .then(res => {
        toast('Concepto guardado con éxito', {type: 'success'});
        setModalConcepto(false);
        setFieldsConcepto(initialValuesConcepto);

        setLoading5(true);

        getConceptos(fields.TIPFAC, fields.CODFAC, () => {
            setLoading5(false);
            setLoading6(false);
        });
      })
      .catch((err) => {
        setLoading6(false);
        err.response.data.forEach((error, key) => {
            toast(error, {type: 'error'});
        })
      })
  }

  const deleteConcepto = (ORDLFA, TIPLFA, FACLFA) => {
    setLoading7(ORDLFA);

    axios.delete(`/api/concepts/${ORDLFA}/?ejercicio=${period}&filtro=ORDLFA=${ORDLFA} AND TIPLFA=${TIPLFA} AND FACLFA=${FACLFA}`)
      .then(res => {
        getConceptos(fields.TIPFAC, fields.CODFAC, () => {
            setLoading7(null);
            toast('Concepto eliminado con éxito', {type: 'success'});
        });
      })
      .catch((err) => {
        setLoading7(null);
        toast('Error al eliminar el concepto', {type: 'error'});
      })
  }

  React.useEffect(() => {
    let total_neto = 0;

    conceptos.forEach(concepto => {
        total_neto += concepto.TOTLFA;
    })

    let descuento_porcentage = parseFloat(fields.PDEFAC);
    let descuento_importe = parseFloat((descuento_porcentage / 100) * total_neto);
    let base_imponible = parseFloat(total_neto - descuento_importe);
    let iva_porcentage = parseFloat(fields.IVPFAC);
    let iva_importe = parseFloat((iva_porcentage / 100) * base_imponible);
    let re_porcentage = parseFloat(fields.REPFAC);
    let re_importe = parseFloat((re_porcentage / 100) * base_imponible);
    let irpf_porcentage = parseFloat(fields.IRPFAC);
    let irpf_importe = parseFloat((irpf_porcentage / 100) * base_imponible);
    let suplidos = parseFloat(fields.SUPFAC);

    setFields({
        ...fields,
        NETFAC: total_neto,
        BASFAC: base_imponible,
        IDEFAC: descuento_importe,
        IVCFAC: iva_importe,
        RECFAC: re_importe,
        IRCFAC: irpf_importe,
        TOTFAC: base_imponible + iva_importe + re_importe - irpf_importe + suplidos
    })
    
  }, [conceptos, fields.PDEFAC, fields.IVPFAC, fields.REPFAC, fields.IRPFAC, fields.SUPFAC])

  return (
    <>
      <Card>
        <CardBody>
          <div className="d-flex align-items-center mb-4">
              <div className="d-flex align-items-center">
                {fields !== null ? <CardTitle>{!params.id ? 'Crear factura' : 'Editar factura'}</CardTitle> : null} 
                {loading2 || loading4 ? <div className="mb-2 ms-2"><Loading text="Cargando..." /></div> : null}
              </div>
                {params.id ?
                    <div className="d-flex ms-auto">
                        <Button color="dark" outline className="d-flex align-items-center" disabled={loading || loading2 || loading3 || loading4 || loading5 || loading6 || loading7 || loading8 || loading9} onClick={() => invoiceTransfer()}><span className="material-icons fs-6 me-1">published_with_changes</span> Traspasar {loading8 ? <Spinner size="sm" className="ms-1" /> : null}</Button>
                        <Button color="primary" className="d-flex align-items-center ms-2" disabled={loading || loading2 || loading3 || loading4 || loading5 || loading6 || loading7 || loading8 || loading9} onClick={() => window.print()}><span className="material-icons fs-6 me-1">print</span> Imprimir</Button>
                    </div>
                : null}
          </div>
          {fields !== null ?
          <Form onSubmit={handleSubmit} noValidate>
            <Row>
                <Col md="12">
                    <Row>
                        <Col md="3">
                            <FormGroup>
                                <Label htmlFor="TIPFAC">Serie</Label>
                                <Input 
                                    id="TIPFAC" 
                                    type="select" 
                                    name="TIPFAC"    
                                    onChange={(e) => {
                                        getLastId(e.target.value);
                                    }}
                                    value={fields["TIPFAC"]}
                                    invalid={errors["TIPFAC"] ? true : false}
                                    disabled={loading4}
                                >
                                    <option value="">Seleccionar</option>
                                    {actividades.map((actividad, key) => {
                                        return <option key={key} value={actividad.CODACT}>{actividad.CODACT}</option>
                                    })}
                                </Input>
                                <FormFeedback>{errors["TIPFAC"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="3">
                            <FormGroup>
                            <Label htmlFor="CODFAC">Nº de factura</Label>
                            <Input 
                                id="CODFAC" 
                                type="number" 
                                name="CODFAC"    
                                onChange={(e) => handleChange("CODFAC", e.target.value)}
                                value={fields["CODFAC"]}
                                invalid={errors["CODFAC"] ? true : false}
                                readOnly={params.id ? true : false}
                            />
                            <FormFeedback>{errors["CODFAC"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="3">
                            <FormGroup>
                                <Label htmlFor="FECFAC">Fecha de Factura</Label>
                                <Input 
                                    id="FECFAC" 
                                    type="date" 
                                    name="FECFAC"    
                                    onChange={(e) => handleChange("FECFAC", e.target.value)}
                                    value={moment(fields["FECFAC"]).format('yyyy-MM-DD')}
                                    invalid={errors["FECFAC"] ? true : false}
                                />
                                <FormFeedback>{errors["FECFAC"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="3">
                            <FormGroup>
                                <Label htmlFor="COBFAC">Estado de Cobro</Label>
                                <Input 
                                    id="COBFAC" 
                                    type="select" 
                                    name="COBFAC"    
                                    onChange={(e) => handleChange("COBFAC", e.target.value)}
                                    value={fields["COBFAC"]}
                                    invalid={errors["COBFAC"] ? true : false}
                                >
                                    <option value="0">Pendiente</option>
                                    <option value="1">Pagado</option>
                                </Input>
                                <FormFeedback>{errors["COBFAC"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col md="12" className="d-flex">
                            <FormGroup>
                                <Button color="dark" outline onClick={() => setModalSelectCliente(true)}>Seleccionar cliente</Button>
                            </FormGroup>
                            <FormGroup>
                                <Button color="dark" outline onClick={() => setModalCliente(true)} className="ms-2">Crear cliente</Button>
                            </FormGroup>
                            <FormGroup className="ms-2">
                                <Input 
                                    id="CLIFAC" 
                                    type="number" 
                                    name="CLIFAC"
                                    value={fields["CLIFAC"]}
                                    invalid={errors["CLIFAC"] ? true : false}
                                    readOnly
                                />
                                <FormFeedback>{errors["NOMCLI"]}</FormFeedback>
                            </FormGroup>
                            <FormGroup className="ms-2">
                                <Input 
                                    id="NOMCLI" 
                                    type="text" 
                                    value={customersFull.find(customer => customer?.CODCLI === fields["CLIFAC"])?.NOMCLI}
                                    readOnly
                                />
                                <FormFeedback>{errors["NOMCLI"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Card className='border border-top-0' style={{boxShadow: 'none', minHeight: 95}}>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>Cantidad</th>
                                            <th>Concepto</th>
                                            <th>Precio. UD.</th>
                                            <th>Total</th>
                                            <th className="p-0 text-end">
                                                <Button 
                                                    color="none" 
                                                    size="sm" 
                                                    className="pb-0" 
                                                    disabled={loading || loading2 || loading3 || loading4 || loading5 || loading6 || loading7 || loading8 || loading9} 
                                                    onClick={() => {
                                                        if(params.id) {
                                                            setFieldsConcepto(initialValuesConcepto); 
                                                            setModalConcepto(true);                                                            
                                                        }
                                                        else {
                                                            toast('Debe guardar la factura primero', {type: 'warning'});
                                                        }
                                                    }}
                                                >
                                                    <span className="material-icons">add</span>
                                                </Button>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan={5}>{loading5 ? <Loading /> : null}</td>
                                        </tr>
                                        {conceptos.map((item, key) => {
                                            return (
                                                <tr>
                                                    <td>{item.CANLFA}</td>
                                                    <td>{item.DESLFA}</td>
                                                    <td>{<NumberFormat value={item.PUNLFA} displayType={'text'} />}</td>
                                                    <td>{<NumberFormat value={item.TOTLFA} displayType={'text'} />}</td>
                                                    <td className="text-end pt-1 pb-0">
                                                        <Button size="sm" outline className="pb-0 me-1" disabled={loading || loading2 || loading3 || loading4 || loading5 || loading6 || loading7 || loading8 || loading9} onClick={() => {setFieldsConcepto(item); setModalConcepto(true)}}><span className="material-icons fs-6">edit</span></Button>
                                                        <Button size="sm" color="danger" outline className="pb-0 me-1" disabled={loading || loading2 || loading3 || loading4 || loading5 || loading6 || loading7 || loading8 || loading9} onClick={() => deleteConcepto(item.ORDLFA, item.TIPLFA, item.FACLFA)}>{loading7 === item.ORDLFA ? <Spinner size="sm"/> : <span className="material-icons fs-6">delete</span>}</Button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                      <Col md="4">
                        <FormGroup>
                            <Label htmlFor="NETFAC">Total neto</Label>
                            <NumberFormat 
                                id="NETFAC" 
                                type="number" 
                                name="NETFAC"    
                                onChange={(e) => handleChange("NETFAC", e.target.value)}
                                onBlur={() => setDefaultIfEmpty()}
                                value={fields["NETFAC"]}
                                invalid={errors["NETFAC"] ? true : false}
                                readOnly
                            />
                            <FormFeedback>{errors["NETFAC"]}</FormFeedback>
                        </FormGroup>

                        <div>
                          <Label htmlFor="PDEFAC">[F3] Descuento</Label>
                          <div className="d-flex">
                            <div className="me-1" style={{width: 70, minWidth: 70}}>
                                <FormGroup>
                                    <NumberFormat 
                                        id="PDEFAC" 
                                        type="number" 
                                        name="PDEFAC"    
                                        onChange={(e) => handleChange("PDEFAC", e.target.value)}
                                        onBlur={() => setDefaultIfEmpty()}
                                        value={fields["PDEFAC"]}
                                        invalid={errors["PDEFAC"] ? true : false}
                                    />
                                    <FormFeedback>{errors["PDEFAC"]}</FormFeedback>
                                </FormGroup>
                            </div>
                            <div className="ms-1 w-100">
                                <FormGroup>
                                    <NumberFormat 
                                        id="IDEFAC" 
                                        type="number" 
                                        name="IDEFAC"    
                                        onChange={(e) => handleChange("IDEFAC", e.target.value)}
                                        onBlur={() => setDefaultIfEmpty()}
                                        value={fields["IDEFAC"]}
                                        invalid={errors["IDEFAC"] ? true : false}
                                    />
                                    <FormFeedback>{errors["IDEFAC"]}</FormFeedback>
                                </FormGroup>
                            </div>
                          </div>
                        </div>    

                        <FormGroup>
                            <Label htmlFor="BASFAC">Base Imponible</Label>
                            <NumberFormat 
                                id="BASFAC" 
                                type="number" 
                                name="BASFAC"    
                                onChange={(e) => handleChange("BASFAC", e.target.value)}
                                onBlur={() => setDefaultIfEmpty()}
                                value={fields["BASFAC"]}
                                invalid={errors["BASFAC"] ? true : false}
                                readOnly
                            />
                            <FormFeedback>{errors["BASFAC"]}</FormFeedback>
                        </FormGroup>                      
                      </Col>
                      <Col md="4">
                        <div>
                          <Label htmlFor="IVPFAC">[F2] IVA</Label>
                          <div className="d-flex">
                            <div className="me-1" style={{width: 70, minWidth: 70}}>
                                <FormGroup>
                                    <NumberFormat 
                                        id="IVPFAC" 
                                        type="number" 
                                        name="IVPFAC"    
                                        onChange={(e) => handleChange("IVPFAC", e.target.value)}
                                        onBlur={() => setDefaultIfEmpty()}
                                        value={fields["IVPFAC"]}
                                        invalid={errors["IVPFAC"] ? true : false}
                                    />
                                    <FormFeedback>{errors["IVPFAC"]}</FormFeedback>
                                </FormGroup>
                            </div>
                            <div className="ms-1 w-100">
                                <FormGroup>
                                    <NumberFormat 
                                        id="IVCFAC" 
                                        type="number" 
                                        name="IVCFAC"    
                                        onChange={(e) => handleChange("IVCFAC", e.target.value)}
                                        onBlur={() => setDefaultIfEmpty()}
                                        value={fields["IVCFAC"]}
                                        invalid={errors["IVCFAC"] ? true : false}
                                        readOnly
                                    />
                                    <FormFeedback>{errors["IVCFAC"]}</FormFeedback>
                                </FormGroup>
                            </div>
                          </div>
                        </div>

                        <div>
                          <Label htmlFor="REPFAC">Rec. Equival.</Label>
                          <div className="d-flex">
                            <div className="me-1" style={{width: 70, minWidth: 70}}>
                                <FormGroup>
                                    <NumberFormat 
                                        id="REPFAC" 
                                        type="number" 
                                        name="REPFAC"    
                                        onChange={(e) => handleChange("REPFAC", e.target.value)}
                                        onBlur={() => setDefaultIfEmpty()}
                                        value={fields["REPFAC"]}
                                        invalid={errors["REPFAC"] ? true : false}
                                    />
                                    <FormFeedback>{errors["REPFAC"]}</FormFeedback>
                                </FormGroup>
                            </div>
                            <div className="ms-1 w-100">
                                <FormGroup>
                                    <NumberFormat 
                                        id="RECFAC" 
                                        type="number" 
                                        name="RECFAC"    
                                        onChange={(e) => handleChange("RECFAC", e.target.value)}
                                        onBlur={() => setDefaultIfEmpty()}
                                        value={fields["RECFAC"]}
                                        invalid={errors["RECFAC"] ? true : false}
                                        readOnly
                                    />
                                    <FormFeedback>{errors["RECFAC"]}</FormFeedback>
                                </FormGroup>
                            </div>
                          </div>
                        </div>

                        <div>
                          <Label htmlFor="IRPFAC">IRPF</Label>
                          <div className="d-flex">
                            <div className="me-1" style={{width: 70, minWidth: 70}}>
                                <FormGroup>
                                    <NumberFormat 
                                        id="IRPFAC" 
                                        type="number" 
                                        name="IRPFAC"    
                                        onChange={(e) => handleChange("IRPFAC", e.target.value)}
                                        onBlur={() => setDefaultIfEmpty()}
                                        value={fields["IRPFAC"]}
                                        invalid={errors["IRPFAC"] ? true : false}
                                    />
                                    <FormFeedback>{errors["IRPFAC"]}</FormFeedback>
                                </FormGroup>
                            </div>
                            <div className="ms-1 w-100">
                                <FormGroup>
                                    <NumberFormat 
                                        id="IRCFAC" 
                                        type="number" 
                                        name="IRCFAC"    
                                        onChange={(e) => handleChange("IRCFAC", e.target.value)}
                                        onBlur={() => setDefaultIfEmpty()}
                                        value={fields["IRCFAC"]}
                                        invalid={errors["IRCFAC"] ? true : false}
                                        readOnly
                                    />
                                    <FormFeedback>{errors["IRCFAC"]}</FormFeedback>
                                </FormGroup>
                            </div>
                          </div>
                        </div>

                      </Col>
                      <Col md="4">
                        <FormGroup>
                            <Label htmlFor="SUPFAC">Suplidos</Label>
                            <NumberFormat 
                                id="SUPFAC" 
                                type="number" 
                                name="SUPFAC"    
                                onChange={(e) => handleChange("SUPFAC", e.target.value)}
                                onBlur={() => setDefaultIfEmpty()}
                                value={fields["SUPFAC"]}
                                invalid={errors["SUPFAC"] ? true : false}
                            />
                            <FormFeedback>{errors["SUPFAC"]}</FormFeedback>
                        </FormGroup>
                        <Card className="border" style={{boxShadow: 'none'}}>
                          <CardBody>
                            <FormGroup>
                                <Label className="fs-4" htmlFor="TOTFAC">TOTAL</Label>
                                <NumberFormat 
                                    id="TOTFAC" 
                                    type="number" 
                                    name="TOTFAC"    
                                    onChange={(e) => handleChange("TOTFAC", e.target.value)}
                                    onBlur={() => setDefaultIfEmpty()}
                                    value={fields["TOTFAC"]}
                                    invalid={errors["TOTFAC"] ? true : false}
                                />
                                <FormFeedback>{errors["TOTFAC"]}</FormFeedback>
                            </FormGroup>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    
                    <br />

                    <Row>
                      <Col md="8">
                        <FormGroup>
                          <Label htmlFor="OB1FAC">Observaciones</Label>
                            <Input 
                                id="OB1FAC" 
                                type="text" 
                                name="OB1FAC"
                                maxLength={60}    
                                onChange={(e) => handleChange("OB1FAC", e.target.value)}
                                value={fields["OB1FAC"]}
                                invalid={errors["OB1FAC"] ? true : false}
                            />
                            <FormFeedback>{errors["OB1FAC"]}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="OB2FAC">&nbsp;</Label>
                            <Input 
                                id="OB2FAC" 
                                type="text" 
                                name="OB2FAC"    
                                maxLength={60} 
                                onChange={(e) => handleChange("OB2FAC", e.target.value)}
                                value={fields["OB2FAC"]}
                                invalid={errors["OB2FAC"] ? true : false}
                            />
                            <FormFeedback>{errors["OB2FAC"]}</FormFeedback>
                        </FormGroup>
                        
                        {/* <FormGroup>
                          <Label htmlFor="CFEFAC">Forma de pago en Factura-e <span className="text-danger">{'<= (Cuales son las opciones y sus números?)'}</span></Label>
                            <Input 
                                id="CFEFAC" 
                                type="select" 
                                name="CFEFAC"    
                                maxLength={5} 
                                onChange={(e) => handleChange("CFEFAC", e.target.value)}
                                value={fields["CFEFAC"]}
                                invalid={errors["CFEFAC"] ? true : false}
                            >
                              <option value="0">Sin seleccionar</option>
                            </Input>
                            <FormFeedback>{errors["CFEFAC"]}</FormFeedback>
                        </FormGroup> */}

                        {/* <FormGroup>
                          <Label htmlFor="BANFAC">Banco para transferencia</Label>
                            <Input 
                                id="BANFAC" 
                                type="number" 
                                name="BANFAC"    
                                onChange={(e) => handleChange("BANFAC", e.target.value)}
                                value={fields["BANFAC"]}
                                invalid={errors["BANFAC"] ? true : false}
                                className="w-25"
                                disabled={fields["CFEFAC"] !== "1"}
                            />
                            <FormFeedback>{errors["BANFAC"]}</FormFeedback>
                        </FormGroup> */}
                      </Col>

                      <Col md="4">
                        <FormGroup>
                            <Label htmlFor="FVEFAC">Vencimiento</Label>
                            <Input 
                                id="FVEFAC" 
                                type="date" 
                                name="FVEFAC"    
                                onChange={(e) => handleChange("FVEFAC", e.target.value)}
                                value={moment(fields["FVEFAC"]).format('yyyy-MM-DD')}
                                invalid={errors["FVEFAC"] ? true : false}
                            />
                            <FormFeedback>{errors["FVEFAC"]}</FormFeedback>
                        </FormGroup>

                        {/* <FormGroup className="d-flex">
                            <Input
                                type="checkbox" 
                                id="---" 
                                name="---"
                                defaultChecked={fields["---"]}
                                onClick={(e) => handleChange("---", e.target.checked ? 1 : 0)}
                                invalid={errors["---"] ? true : false}
                                disabled
                            />
                                <Label className="form-check-label" htmlFor="---">Factura acogida a critério de caja <span className="text-danger">{'<= (Que columna sería esta en la tabla?)'}</span></Label>
                            <FormFeedback>{errors["---"]}</FormFeedback>
                        </FormGroup>  */}

                        {/* <FormGroup className="d-flex">
                            <Input
                                type="checkbox" 
                                id="TRAFAC" 
                                name="TRAFAC"
                                defaultChecked={fields["TRAFAC"]}
                                onClick={(e) => handleChange("TRAFAC", e.target.checked ? 1 : 0)}
                                invalid={errors["TRAFAC"] ? true : false}
                            />
                                <Label className="form-check-label" htmlFor="TRAFAC">Factura traspasada</Label>
                            <FormFeedback>{errors["TRAFAC"]}</FormFeedback>
                        </FormGroup> */}

                        {/* <FormGroup className="d-flex">
                            <Input
                                type="checkbox" 
                                id="REMFAC" 
                                name="REMFAC"
                                defaultChecked={fields["REMFAC"]}
                                onClick={(e) => handleChange("REMFAC", e.target.checked ? 1 : 0)}
                                invalid={errors["REMFAC"] ? true : false}
                            />
                                <Label className="form-check-label" htmlFor="REMFAC">Factura remesada</Label>
                            <FormFeedback>{errors["REMFAC"]}</FormFeedback>
                        </FormGroup> */}

                        <FormGroup>
                            <Label htmlFor="EMAFAC">E-mail</Label>
                            <Input 
                                id="EMAFAC" 
                                type="select" 
                                name="EMAFAC"    
                                onChange={(e) => handleChange("EMAFAC", e.target.value)}
                                value={fields["EMAFAC"]}
                                invalid={errors["EMAFAC"] ? true : false}
                            >
                                <option value="0">No enviado</option>
                                <option value="1">Enviado</option>
                            </Input>
                            <FormFeedback>{errors["EMAFAC"]}</FormFeedback>
                        </FormGroup>

                      </Col>
                    </Row>
                    
                </Col>
            </Row>

            <br />
            {/* <div className="d-flex">
              <Button type="submit" color="primary" disabled={loading || loading2 || loading3 || loading4 || loading5 || loading6 || loading7 || loading8 || loading9}>{!params.id ? 'Crear' : 'Editar'} {loading ? <Spinner size="sm" className="ms-1" /> : null}</Button>
              <Button type="button" color="dark" className="ms-2" outline disabled={loading || loading2 || loading3 || loading4 || loading5 || loading6 || loading7 || loading8 || loading9} onClick={() => navigate('/billings')}>Cancelar</Button>
              {params.id ? <Button color="danger" type="button" className="ms-auto" outline disabled={loading || loading2 || loading3 || loading4 || loading5 || loading6 || loading7 || loading8 || loading9} onClick={() => setModalDelete(true)}>Eliminar{loading3 ? <Spinner size="sm" className="ms-1" /> : null}</Button> : null}
            </div> */}
          </Form>
          : 
            loading2 ?
                <Loading />  
            :
                <EmptyData
                title={`No existe factura con código ${params.id}`}
                btn="Crear factura"
                onClick={() => [setFields({}), navigate('/billings/new')]}
                />
          }
        </CardBody>
      </Card>

      <Card className="ps-4 pe-4" style={{
        position: 'sticky',
        bottom: 0,
        left: 0,
        width: 'calc(100% + 50px)',
        marginLeft: -25,
        marginBottom: -25,
        boxShadow: '1px 1px 10px rgb(177 190 207)',
      }}>
        <CardBody className='pt-3 pb-3'>
            <div className="d-flex">
                <Button type="button" color="primary" disabled={loading || loading2 || loading3 || loading4 || loading5 || loading6 || loading7 || loading8 || loading9} onClick={handleSubmit}>{!params.id ? 'Crear' : 'Editar'} {loading ? <Spinner size="sm" className="ms-1" /> : null}</Button>
                <Button type="button" color="dark" className="ms-2" outline disabled={loading || loading2 || loading3 || loading4 || loading5 || loading6 || loading7 || loading8 || loading9} onClick={() => navigate('/billings')}>Cancelar</Button>
                {params.id ? <Button color="danger" type="button" className="ms-auto" outline disabled={loading || loading2 || loading3 || loading4 || loading5 || loading6 || loading7 || loading8 || loading9} onClick={() => setModalDelete(true)}>Eliminar{loading3 ? <Spinner size="sm" className="ms-1" /> : null}</Button> : null}
            </div>
        </CardBody>
      </Card>

      <Modal isOpen={modalDelete} toggle={() => setModalDelete(false)}>
        <ModalHeader toggle={() => setModalDelete(false)}>Eliminar usuario</ModalHeader>
        <ModalBody>
          ¿Está seguro de que desea eliminar la factura con código {fields?.CODFAC}?
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            disabled={loading || loading2 || loading3}
            onClick={() => handleDelete()}
          >
            {loading3 ? <Spinner size="sm" className="me-1"/> : null}
            {!loading3 ? 'Eliminar' : 'Eliminando...'}
          </Button>
          {' '}
          <Button outline disabled={loading || loading2 || loading3} onClick={() => setModalDelete(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="xl" isOpen={modalCliente} toggle={() => setModalCliente(false)}>
        <ModalHeader toggle={() => setModalCliente(false)}>CREAR CLIENTE</ModalHeader>
        <CustomerForm 
            period={period} 
            source={source} 
            isModal 
            closeModal={(e) => setModalCliente(false)} 
            save={setNewCustomer}  
        />
      </Modal>
      
      <Modal size="xl" isOpen={modalSelectCliente} toggle={() => setModalSelectCliente(false)}>
        <ModalHeader toggle={() => setModalSelectCliente(false)}>SELECCIONAR CLIENTE</ModalHeader>
        <ModalBody>
            <ListGroup>
                {customersFull.map((customer, key) => {
                    return (
                        <ListGroupItem 
                            key={key} 
                            action 
                            onClick={() => [
                                setCustomers(customer),
                                setFields({
                                    ...fields,
                                    CLIFAC: customer.CODCLI,

                                    IVPFAC: customer.TPICLI.toString() === '0' ? 0 : (customer.TPICLI.toString() === '1' || customer.TPICLI.toString() === '4' ? 21.00 : (customer.TPICLI.toString() === '2' ? 10.00 : (customer.TPICLI.toString() === '3' ? 4.00 : (customer.TPICLI.toString() === '5' ? 10.00 : fields.IVPFAC)))),
                                    // IRPFAC: customer.TPICLI.toString() === '0' ? 0 : fields.IRPFAC,
                                    IRPFAC: customer.RETCLI,
                                    REPFAC: customer.TPICLI.toString() === '4' ? 5.20 : (customer.TPICLI.toString() === '5' ? 1.40 : fields.REPFAC),
                                }),
                                setModalSelectCliente(false)
                            ]}
                        >
                            <b>{customer.CODCLI}</b> - {customer.NOMCLI}
                        </ListGroupItem>
                    )
                })}
            </ListGroup>
        </ModalBody>
      </Modal>

      <Modal isOpen={modalConcepto} toggle={() => setModalConcepto(false)} backdrop={'static'}>
        <ModalHeader toggle={() => setModalConcepto(false)}>{fieldsConcepto.ORDLFA === null ? 'CREAR' : 'EDITAR'} CONCEPTO</ModalHeader>
        <ModalBody>
            <Row>
                <Col md="4">
                    <FormGroup>
                        <Label htmlFor="CANLFA">Cantidad</Label>
                        <Input 
                            id="CANLFA" 
                            type="number" 
                            name="CANLFA"    
                            onChange={(e) => handleChangeConcepto("CANLFA", e.target.value)}
                            value={fieldsConcepto["CANLFA"]}
                            invalid={errorsConcepto["CANLFA"] ? true : false}
                        />
                        <FormFeedback>{errorsConcepto["CANLFA"]}</FormFeedback>
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <Label htmlFor="PUNLFA">Precio UD.</Label>
                        <NumberFormat 
                            id="PUNLFA" 
                            type="number" 
                            name="PUNLFA"    
                            onChange={(e) => handleChangeConcepto("PUNLFA", e.target.value)}
                            value={fieldsConcepto["PUNLFA"]}
                            invalid={errorsConcepto["PUNLFA"] ? true : false}
                        />
                        <FormFeedback>{errorsConcepto["PUNLFA"]}</FormFeedback>
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <Label htmlFor="TOTLFA">Total</Label>
                        <Input 
                            id="TOTLFA" 
                            type="number" 
                            name="TOTLFA"    
                            onChange={(e) => handleChangeConcepto("TOTLFA", e.target.value)}
                            value={fieldsConcepto["TOTLFA"]}
                            invalid={errorsConcepto["TOTLFA"] ? true : false}
                            readOnly
                        />
                        <FormFeedback>{errorsConcepto["TOTLFA"]}</FormFeedback>
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <Label htmlFor="DESLFA">Concepto</Label>
                <Input 
                    id="DESLFA" 
                    type="textarea" 
                    name="DESLFA"    
                    onChange={(e) => handleChangeConcepto("DESLFA", e.target.value)}
                    value={fieldsConcepto["DESLFA"]}
                    invalid={errorsConcepto["DESLFA"] ? true : false}
                    style={{"height": "100px"}}
                />
                <FormFeedback>{errorsConcepto["DESLFA"]}</FormFeedback>
            </FormGroup>

            <br />
            <div className="d-flex justify-content-end">
              <Button type="button" color="primary" disabled={loading || loading2 || loading3 || loading4 || loading5 || loading6 || loading7 || loading8 || loading9} onClick={() => saveConcepto()}>Aceptar {loading6 ? <Spinner size="sm" className="ms-1" /> : null}</Button>
              <Button type="button" color="dark" className="ms-2" outline disabled={loading || loading2 || loading3 || loading4 || loading5 || loading6 || loading7 || loading8 || loading9} onClick={() => setModalConcepto(false)}>Cancelar</Button>
            </div>
        </ModalBody>
      </Modal>

      <Invoice data={invoice} conceptos={conceptos} />
    </>
  );
}