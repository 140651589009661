import * as React from 'react';
import { Card, CardBody, CardTitle, CardHeader, Row, Col, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, FormGroup, Label, Input } from 'reactstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import Loading from '../components/Loading';
import EmptyData from '../components/EmptyData';
import Drawer from '../components/Drawer';
import Filter from '../components/Filter';
import NumberFormat from '../components/NumberFormat';

export default function Billings({ period, source, issuedInvoices, setIssuedInvoices }) {
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [modalDelete, setModalDelete] = React.useState(null);
  const [modalCopy, setModalCopy] = React.useState(null);
  const [drawer, setDrawer] = React.useState(false);
  const [actividades, setActividades] = React.useState([]);
  const [customers, setCustomers] = React.useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  React.useEffect(() => {
    getData();
  }, [period])

  const getData = (filter='') => {
    setLoading(true);
    axios.get(`/api/billings/?ejercicio=${period}&filtro=CODFAC>=0 ${filter ? filter : ''} ORDER BY CODFAC DESC`, {
      cancelToken: source.token
    })
      .then(res => {
        setIssuedInvoices(res.data);
        setLoading(false);
        setDrawer(false);

        setLoading2(false);
        setModalDelete(null);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setIssuedInvoices([]);
          setLoading(false);
          setDrawer(false);

          setLoading2(false);
          setModalDelete(null);
        }
      })

      axios.get(`/api/billings/customers/?ejercicio=${period}&filtro=CODCLI>=0 ORDER BY CODCLI DESC`, {
        cancelToken: source.token
        })
        .then(res => {
          setCustomers(res.data);
        })
  }

  const handleCopy = (e, item) => {
    e.preventDefault();
    setLoading2(true);

    axios.get(`/api/billings/last_id/?ejercicio=${period}&TIPFAC=${item.TIPFAC}`, {
      cancelToken: source.token
    })
      .then(res => {
        let data = {...item, CODFAC: res.data}
        axios.post(`/api/billings/?ejercicio=${period}`, data)
          .then(res => {
            setLoading2(false);
            setModalCopy(null);
            let _issuedInvoices = [...issuedInvoices]
            _issuedInvoices.unshift(data)
            setIssuedInvoices(_issuedInvoices);
            toast('Item copiado con éxito', {type: 'success'});
            navigate(`/billings/${data.CODFAC}/${data.TIPFAC}?period=${searchParams.get('period')}`)
          })
          .catch((err) => {
            err.response.data.forEach((error, key) => {
                setLoading(false);
                toast(error, {type: 'error'});
            })
          })
      })
      .catch(err => {
        setLoading2(false);
        setModalCopy(null);
        toast(JSON.stringify(err.response.data), {type: 'error'});
      })
  }

  const handleDelete = (e, id, TIPFAC, CODFAC, index) => {
    e.preventDefault();
    setLoading2(true);

    axios.delete(`/api/billings/${id}/?ejercicio=${period}&filtroDelete=TIPFAC=${TIPFAC} AND CODFAC=${CODFAC}&filtro=TIPLFA=${TIPFAC} AND FACLFA=${CODFAC}`)
      .then(res => {
        // setLoading2(false);
        // setModalDelete(null);
        // const newData = issuedInvoices.filter(item => !(item.CODFAC === CODFAC && item.TIPFAC === TIPFAC));
        // setIssuedInvoices(newData);
        getData();
        toast('Factura eliminado con éxito', {type: 'success'});
      })
      .catch(err => {
        setLoading2(false);
        setModalDelete(null);
        toast(JSON.stringify(err.response.data), {type: 'error'});
      })
  }

  return (
    <>
      <Card>
        <CardHeader className="border-0 pb-0">
          <Row>
              <Col className="d-flex align-items-center">
                <CardTitle>Facturación</CardTitle>
                { loading && issuedInvoices ? <div className="mb-2 ms-2"><Loading text="Actualizando..." /></div> : null }
              </Col>
              <Col className="d-flex justify-content-end">
                <div className="d-flex">
                  {/* <Button size="sm" color="dark" outline className="d-flex align-items-center" onClick={() => setDrawer(true)}><span className="material-icons">filter_list</span> Filtrar</Button> */}
                  <Button size="sm" color="primary" className="d-flex align-items-center ms-2" onClick={() => navigate(`/billings/new?period=${searchParams.get('period')}`)}><span className="material-icons">add</span> Crear factura</Button>
                </div>
              </Col>
            </Row>
        </CardHeader>
        <CardBody>
          {issuedInvoices && issuedInvoices.length >= 0  ?
            issuedInvoices.length > 0 ?
            <>
              <Table>
                <thead>
                  <tr>
                    <th>Serie</th>
                    <th>Número</th>
                    <th>Fecha</th>
                    <th>Nombre del Cliente</th>
                    <th>Código de Cliente</th>
                    <th>Base Imp.</th>
                    <th>IVA</th>
                    <th>RE</th>
                    <th>IRPF</th>
                    <th>Suplidos</th>
                    <th>Total</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {issuedInvoices.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>{item.TIPFAC}</td>
                        <td>{item.CODFAC}</td>
                        <td>{moment(item.FECFAC).format('DD/MM/YYYY')}</td>
                        <td>{customers.length > 0 ? customers.find(customer => customer.CODCLI === item.CLIFAC)?.NOMCLI : '...' }</td>
                        <td>{item.CLIFAC}</td>
                        <td>{<NumberFormat value={item.BASFAC} displayType={'text'} />}</td>
                        <td>{<NumberFormat value={item.IVCFAC} displayType={'text'} />}</td>
                        <td>{<NumberFormat value={item.RECFAC} displayType={'text'} />}</td>
                        <td>{<NumberFormat value={item.IRCFAC} displayType={'text'} />}</td>
                        <td>{<NumberFormat value={item.SUPFAC} displayType={'text'} />}</td>
                        <td>{<NumberFormat value={item.TOTFAC} displayType={'text'} />}</td>
                        <td className="d-flex">
                          <Button size="sm" outline className="pb-0 me-1" onClick={() => navigate(`/billings/${item.CODFAC}/${item.TIPFAC}?period=${searchParams.get('period')}`)}><span className="material-icons fs-6">edit</span></Button>
                          <Button size="sm" outline className="pb-0 me-1" onClick={() => setModalCopy(item)}><span className="material-icons fs-6">content_copy</span></Button>
                          <Button size="sm" color="danger" outline className="pb-0 me-1" onClick={() => setModalDelete(item.CODFAC)}><span className="material-icons fs-6">delete</span></Button>
                          
                          <Modal isOpen={modalCopy === item} toggle={() => setModalCopy(null)}>
                            <ModalHeader toggle={() => setModalCopy(null)}>Copiar factura</ModalHeader>
                            <ModalBody>
                              ¿Está seguro de que desea copiar la factura {item.CODFAC}?
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                type="button"
                                color="primary"
                                disabled={loading2}
                                onClick={(e) => handleCopy(e, item)}
                              >
                                {loading2 ? <Spinner size="sm" className="me-1"/> : null}
                                {!loading2 ? 'Copiar' : 'Copiando...'}
                              </Button>
                              {' '}
                              <Button outline disabled={loading2} onClick={() => setModalCopy(null)}>
                                Cancel
                              </Button>
                            </ModalFooter>
                          </Modal>

                          <Modal isOpen={modalDelete === item.CODFAC} toggle={() => setModalDelete(null)}>
                            <ModalHeader toggle={() => setModalDelete(null)}>Eliminar factura</ModalHeader>
                            <ModalBody>
                              ¿Está seguro de que desea eliminar la factura con número {item.CODFAC}?
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                type="button"
                                color="danger"
                                disabled={loading2}
                                onClick={(e) => handleDelete(e, item.CODFAC, item.TIPFAC, item.CODFAC)}
                              >
                                {loading2 ? <Spinner size="sm" className="me-1"/> : null}
                                {!loading2 ? 'Eliminar' : 'Eliminando...'}
                              </Button>
                              {' '}
                              <Button outline disabled={loading2} onClick={() => setModalDelete(null)}>
                                Cancel
                              </Button>
                            </ModalFooter>
                          </Modal>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
              {/* <Pagination data={data} /> */}
            </>
            :
              <EmptyData
                title="No hay facturas registrados"
                btn="Crear factura"
                onClick={() => navigate(`/billings/new?period=${searchParams.get('period')}`)}
              />
          : 
              <Loading />
          }
        </CardBody>
      </Card>

      <Drawer 
        isOpen={drawer} 
        direction={'right'}
        title="Filtrar"
        onClose={() => setDrawer(false)}
      >
        <Filter 
          source={source} 
          period={period}
          type="billings"
          loading={loading}
          onFilter={getData}
        />
      </Drawer>
    </>
  );
}