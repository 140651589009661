import axios from 'axios';
import * as React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Card, CardBody, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Label, Row, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Table, ListGroup, ListGroupItem, Alert } from 'reactstrap';
import Select from 'react-select'
import moment from 'moment';
import SupplierForm from './SupplierForm';
import Loading from '../components/Loading';
import EmptyData from '../components/EmptyData';
import { isEmpty, validEmail, validURL } from '../lib/validator';
import activities from '../global/activities';
import identificacionFiscal from '../global/identificacion_fiscal';
import clienteClaveOperacionHabitual from '../global/cliente_clave_operacion_habitual';
import tiposDeRetificaciones from '../global/tipos_de_retificaciones';
import countries from '../global/country-codes_json.json';
import NumberFormat from '../components/NumberFormat';

const initialValues = {
    MOVFRE: 'F', // [L=#0;Factura Recibida#1;Gasto]Tipo de movimiento
    DEDFRE: '0',
    PDEFRE: 100.00,
    DIRFRE: '0',
    PDIFRE: 100.00,
    COPFRE: '0',

    '347FRE': 1,

    'BA0FRE': 0,

    'BA1FRE': 0,
    'PI1FRE': 0,
    'CI1FRE': 0,
    'PR1FRE': 0,
    'CR1FRE': 0,

    'BA2FRE': 0,
    'PI2FRE': 0,
    'CI2FRE': 0,
    'PR2FRE': 0,
    'CR2FRE': 0,

    'BA3FRE': 0,
    'PI3FRE': 0,
    'CI3FRE': 0,
    'PR3FRE': 0,
    'CR3FRE': 0,

    'BA4FRE': 0,
    'PI4FRE': 0,
    'CI4FRE': 0,
    'PR4FRE': 0,
    'CR4FRE': 0,

    'BA5FRE': 0,
    'PI5FRE': 0,
    'CI5FRE': 0,
    'PR5FRE': 0,
    'CR5FRE': 0,

    'PRETFRE': 0,
    'IRETFRE': 0,
    'TOPFRE': 0,
    'SUPFRE': 0,
    'TOTFRE': 0,
}

const initialValuesConcepto = {
    FICLCP: 'FRE',
    CODLCP: null,
    LINLCP: null,
    FECLCP: moment(),
    IMPLCP: 0,
    EFELCP: 0,
    CPTLCP: '',
}

const Pagos = (props) => {
    return (
        <Card className='border h-100' style={{boxShadow: 'none'}}>
            <CardBody>
                <div className='d-flex align-items-center mb-3'>
                    <div>
                        <h6>Pagos</h6>
                    </div>
                    <div className='ms-auto'>
                        <div>
                            <Button 
                                color="none" 
                                size="sm" 
                                disabled={props.loading || props.loading2 || props.loading3 || props.loading4 || props.loading5 || props.loading6 || props.loading7} 
                                onClick={() => {
                                    if(props.params.id) {
                                        props.setFieldsConcepto(initialValuesConcepto); 
                                        props.setModalConcepto(true);                                                            
                                    }
                                    else {
                                        toast('Debe guardar la factura primero', {type: 'warning'});
                                    }
                                }}
                            >
                                <span className="material-icons">add</span>
                            </Button>
                        </div>
                    </div>
                </div>
                <Table>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Importe</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={5}>{props.loading5 ? <Loading /> : null}</td>
                        </tr>
                        {props.conceptos.map((item, key) => {
                            return (
                                <tr>
                                    <td>{moment(item.FECLCP).format('DD/MM/YYYY')}</td>
                                    <td>{<NumberFormat value={item.IMPLCP} displayType={'text'} />}</td>
                                    <td className="text-end pt-1 pb-0">
                                        <Button size="sm" outline className="pb-0 me-1" disabled={props.loading || props.loading2 || props.loading3 || props.loading4 || props.loading5 || props.loading6 || props.loading7} onClick={() => {props.setFieldsConcepto(item); props.setModalConcepto(true)}}><span className="material-icons fs-6">edit</span></Button>
                                        <Button size="sm" color="danger" outline className="pb-0 me-1" disabled={props.loading || props.loading2 || props.loading3 || props.loading4 || props.loading5 || props.loading6 || props.loading7} onClick={() => props.deleteConcepto(item.LINLCP, item.FICLCP, item.CODLCP)}>{props.loading7 === item.LINLCP ? <Spinner size="sm"/> : <span className="material-icons fs-6">delete</span>}</Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    )
}

export default function InvoiceReceivedForm({ period, source }) {
  const [fields, setFields] = React.useState(initialValues);
  const [fieldsConcepto, setFieldsConcepto] = React.useState(initialValuesConcepto);
  const [errors, setErrors] = React.useState({});
  const [errorsConcepto, setErrorsConcepto] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [loading3, setLoading3] = React.useState(false);
  const [loading4, setLoading4] = React.useState(false);
  const [loading5, setLoading5] = React.useState(false);
  const [loading6, setLoading6] = React.useState(false);
  const [loading7, setLoading7] = React.useState(null);
  const [modalDelete, setModalDelete] = React.useState(false);
  const [conceptos, setConceptos] = React.useState([]);
  const [actividades, setActividades] = React.useState([]);
  const [tiposDeIngresos, setTiposDeIngresos] = React.useState([]);
  const [suppliers, setSuppliers] = React.useState([]);
  const [modalProveedor, setModalProveedor] = React.useState(false);
  const [modalSelectProveedor, setModalSelectProveedor] = React.useState(false);
  const [moreIVA, setMoreIVA] = React.useState(0);
  const [alert, setAlert] = React.useState('');
  const [modalConcepto, setModalConcepto] = React.useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const type = fields.MOVFRE === 'F' ? 'Factura recibida' : 'Registro de Gasto';
  const genero = fields.MOVFRE === 'F' ? 'a' : 'o';

  React.useEffect(() => {
    getData();
  }, [period, params.id])

  React.useEffect(() => {
    let count = (hasIVA4() ? 1 : 0) + (hasIVA5() ? 1 : 0);
    setMoreIVA(count > moreIVA ? count : moreIVA);
  }, [fields])

  React.useEffect(() => {
    let baseImponible = (parseFloat(fields.BA0FRE) + parseFloat(fields.BA1FRE) + parseFloat(fields.BA2FRE) + parseFloat(fields.BA3FRE) + parseFloat(fields.BA4FRE) + parseFloat(fields.BA5FRE))
    let iva = (parseFloat(fields.CI1FRE) + parseFloat(fields.CI2FRE) + parseFloat(fields.CI3FRE) + parseFloat(fields.CI4FRE) + parseFloat(fields.CI5FRE))
    let re = (parseFloat(fields.CR1FRE) + parseFloat(fields.CR2FRE) + parseFloat(fields.CR3FRE) + parseFloat(fields.CR4FRE) + parseFloat(fields.CR5FRE))
    let totalOperacion = baseImponible + iva + re
    let suplidos = parseFloat(fields.SUPFRE)
    let irpf = (parseFloat(fields.PRETFRE) / 100) * baseImponible
    let total = totalOperacion + suplidos - irpf

    let _totfre = fields.MOVFRE === 'F' ? total : fields.TOTFRE

    let _tirfre = fields?.TIRFRE

    if(parseInt(fields.COPFRE) === 4) {
        _tirfre = _totfre < 0 ? 9 : 4
    }

    setFields({
        ...fields,
        CI1FRE: (parseFloat(fields.PI1FRE) / 100) * parseFloat(fields.BA1FRE),
        CI2FRE: (parseFloat(fields.PI2FRE) / 100) * parseFloat(fields.BA2FRE),
        CI3FRE: (parseFloat(fields.PI3FRE) / 100) * parseFloat(fields.BA3FRE),
        CI4FRE: (parseFloat(fields.PI4FRE) / 100) * parseFloat(fields.BA4FRE),
        CI5FRE: (parseFloat(fields.PI5FRE) / 100) * parseFloat(fields.BA5FRE),

        CR1FRE: (parseFloat(fields.PR1FRE) / 100) * parseFloat(fields.BA1FRE),
        CR2FRE: (parseFloat(fields.PR2FRE) / 100) * parseFloat(fields.BA2FRE),
        CR3FRE: (parseFloat(fields.PR3FRE) / 100) * parseFloat(fields.BA3FRE),
        CR4FRE: (parseFloat(fields.PR4FRE) / 100) * parseFloat(fields.BA4FRE),
        CR5FRE: (parseFloat(fields.PR5FRE) / 100) * parseFloat(fields.BA5FRE),

        TOPFRE: totalOperacion,

        IRETFRE: irpf,

        TOTFRE: _totfre,
        
        TIRFRE: _tirfre
    })


}, [
    fields.BA0FRE, 
    fields.BA1FRE, fields.PI1FRE, fields.CI1FRE, fields.PR1FRE, fields.CR1FRE,
    fields.BA2FRE, fields.PI2FRE, fields.CI2FRE, fields.PR2FRE, fields.CR2FRE,
    fields.BA3FRE, fields.PI3FRE, fields.CI3FRE, fields.PR3FRE, fields.CR3FRE,
    fields.BA4FRE, fields.PI4FRE, fields.CI4FRE, fields.PR4FRE, fields.CR4FRE,
    fields.BA5FRE, fields.PI5FRE, fields.CI5FRE, fields.PR5FRE, fields.CR5FRE,
    fields.TOPFRE,
    fields.PRETFRE, fields.IRETFRE,
    fields.TOTFRE,
    fields.SUPFRE,
])

//   React.useEffect(() => {
//     if(fields.CLIFRE && suppliers.length > 0) handleChangeNOMFRE({value: fields.CLIFRE})
//   }, [fields.CLIFRE, suppliers])

  const getData = () => {
    axios.get(`/api/invoices-received/actividades/?ejercicio=${period}&filtro=CODACT>=0`, {
        cancelToken: source.token
      })
        .then(res => {
            setActividades(res.data);
        })

    axios.get(`/api/invoices-received/tipos_de_ingreso/?ejercicio=${period}&filtro=LIBTGI=0 ORDER BY CODTGI`, {
        cancelToken: source.token
        })
        .then(res => {
            setTiposDeIngresos(res.data);
        })

    getSuppliers()

    if(params.id) {
        setLoading2(true);
        axios.get(`/api/invoices-received/${params.id ? params.id +'/' : ''}?ejercicio=${period}&filtro=CODFRE=${params.id}`, {
            cancelToken: source.token
          })
          .then(res => {
            setLoading2(false);
            setFields(res.data.CODFRE ? res.data : null);

            initialValuesConcepto.CODLCP = res.data.CODFRE
            setFieldsConcepto({
                ...fieldsConcepto,
                CODLCP: res.data.CODFRE,
            })
            getConceptos('FRE', res.data.CODFRE);
          })
    } else {
        setFields({ ...fields });
        setLoading4(true)
        axios.get(`/api/invoices-received/last_id/?ejercicio=${period}`, {
            cancelToken: source.token
          })
            .then(res => {
                setLoading4(false);
                setFields({...fields, CODFRE: res.data});
            })
    }
  }

  const handleChangeConcepto = (field, value) => {
    setFieldsConcepto({ ...fieldsConcepto, [field]: value });
  }

  const getConceptos = (FICLCP='FRE', CODLCP, callback) => {
    axios.get(`/api/charges/?ejercicio=${period}&filtro= FICLCP='${FICLCP}' AND CODLCP=${CODLCP} ORDER BY LINLCP DESC`, {
        cancelToken: source.token
      })
        .then(res => {
            setConceptos(res.data);
            setLoading5(false);
            if(callback) callback(res.data);
        })     
        .catch((err) => {
            setLoading5(false);
            if(callback) callback();
        })  
  }

  const saveConcepto = () => {
    setLoading6(true);

    axios.get(`/api/charges/last_id/?ejercicio=${period}`, {
        cancelToken: source.token
      })
        .then(res => {
            axios[fieldsConcepto.LINLCP === null ? 'post' : 'put'](`/api/charges/${fieldsConcepto.LINLCP !== null ? fieldsConcepto.LINLCP +'/' : ''}?ejercicio=${period}`, {
                ...fieldsConcepto, 
                FICLCP: 'FRE', 
                CODLCP: fields.CODFRE, 
                LINLCP: fieldsConcepto.LINLCP !== null ? fieldsConcepto.LINLCP : res.data
            })
              .then(res => {
                toast('Cobro guardado con éxito', {type: 'success'});
                setModalConcepto(false);
                setFieldsConcepto(initialValuesConcepto);
        
                setLoading5(true);
        
                getConceptos('FRE', fields.CODFRE, () => {
                    setLoading5(false);
                    setLoading6(false);
                });
              })
              .catch((err) => {
                setLoading6(false);
                err.response.data.forEach((error, key) => {
                    toast(error, {type: 'error'});
                })
              })
        })
  }

  const deleteConcepto = (LINLCP, FICLCP, CODLCP) => {
    setLoading7(LINLCP);

    axios.delete(`/api/charges/${LINLCP}/?ejercicio=${period}&filtro=LINLCP=${LINLCP} AND FICLCP='${FICLCP}' AND CODLCP=${CODLCP}`)
      .then(res => {
        getConceptos(FICLCP, CODLCP, () => {
            setLoading7(null);
            toast('Cobro eliminado con éxito', {type: 'success'});
        });
      })
      .catch((err) => {
        setLoading7(null);
        toast('Error al eliminar el cobro', {type: 'error'});
      })
  }

  const setNewSupplier = (data) => {
    let TIPFRE = null;
    let TIPPRO = data.TIPPRO;

    switch(TIPPRO) {
        case 0:
            TIPFRE = 0;
            break;
        case 1:
            TIPFRE = 2;
            break;
        case 2:
            TIPFRE = 1;
            break;
        case 3:
            TIPFRE = 3;
            break;
        default:
            TIPFRE = null;
            break;
    };
    
    let CINPRO = parseInt(data.CINPRO)
    let _349FRE = null;

    switch(CINPRO) {
        case 1:
            _349FRE = 'A';
            break;
        case 8:
            _349FRE = 'C';
            break;
        case 9:
            _349FRE = 'D';
            break;
        case 2:
            _349FRE = 'E';
            break;
        case 3:
            _349FRE = 'H';
            break;
        case 4:
            _349FRE = 'I';
            break;
        case 5:
            _349FRE = 'M';
            break;
        case 6:
            _349FRE = 'S';
            break;
        case 7:
            _349FRE = 'T';
            break;
    }

    let TDEPRO = parseInt(data.TDEPRO)
    let DEDFRE = null;

    switch(TDEPRO) {
        case 1:
            DEDFRE = 0;
            break;
        case 2:
            DEDFRE = 1;
            break;
        case 3:
            DEDFRE = 2;
            break;
        case 4:
            DEDFRE = 3;
            break;
    }

    let TDIPRO = parseInt(data.TDIPRO)
    let DIRFRE = null;

    switch(TDIPRO) {
        case 1:
            DIRFRE = 0;
            break;
        case 2:
            DIRFRE = 1;
            break;
        case 3:
            DIRFRE = 2;
            break;
        case 4:
            DIRFRE = 3;
            break;
    }
    
    setFields({ 
        ...fields,
        NOMFRE: data.NOMPRO,
        PROFRE: data.CODPRO,
        TIRFRE: data.IFIPRO,
        NIFFRE: data.CIFPRO,
        COPFRE: data.COPPRO,

        ACTFRE: data.ACTPRO,
        COPFRE: data.COPPRO,
        TIPFRE: TIPFRE,
        ayuda_en_el_calculo: data.ACPPRO,
        TRETFRE: data.TREPRO,
        PRETFRE: data.RETPRO,

        DEDFRE: DEDFRE,
        PDEFRE: data.DEDPRO,
        // TDIFRE: data.TDIPRO,
        DIRFRE: DIRFRE,
        PDIFRE: data.DEIPRO,
        '349FRE': _349FRE,
    });
  }

  const getSuppliers = (callback) => {
    axios.get(`/api/invoices-received/suppliers/?ejercicio=${period}&filtro=CODPRO>=0 ORDER BY CODPRO DESC`, {
        cancelToken: source.token
        })
        .then(res => {
            let customers = [];
            res.data.forEach(customer => {
                customers.push({
                    value: customer.CODCLI,
                    label: customer.NOMCLI
                })
            })
            setSuppliers(res.data);
            if(callback) callback(res.data, customers);
        })
  }

  const handleChange = (field, value) => {
    setFields({ ...fields, [field]: value });
  }

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (isEmpty(fields['CODFRE'])) {
        formIsValid = false;
        errors['CODFRE'] = 'Campo obligatorio.';
    }

    if (isEmpty(fields['MOVFRE'])) {
        formIsValid = false;
        errors['MOVFRE'] = 'Campo obligatorio.';
    }

    if (isEmpty(fields['FECFRE'])) {
        formIsValid = false;
        errors['FECFRE'] = 'Campo obligatorio.';
    }

    setErrors(errors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      setInvoiceIssued()
    }
  }

  const setInvoiceIssued = () => {
    setLoading(true);

    let _fields = setDefaultIfEmpty();
    
    delete _fields.ayuda_en_el_calculo;

    axios[!params.id ? 'post' : 'put'](`/api/invoices-received/${params.id ? params.id +'/' : ''}?ejercicio=${period}`, {..._fields, CODFRE: fields.CODFRE})
      .then(res => {
        setLoading(false);
        toast(!params.id ? type +` cread${genero} con éxito` : type + ` actualizad${genero} con éxito`, {type: 'success'});
        if(!params.id) navigate(`/invoices-received/${res.data.CODFRE}`)
      })
      .catch((err) => {
        err.response.data.forEach((error, key) => {
            setLoading(false);
            toast(error, {type: 'error'});
        })
      })
  }

  const handleDelete = () => {
    setLoading3(true);

    axios.delete(`/api/invoices-received/${params.id}/?ejercicio=${period}`)
      .then(res => {
        setLoading3(false);
        toast(type + ` eliminad${genero} con éxito`, {type: 'success'});
        navigate(`/invoices-received/`)
      })
      .catch((err) => {
		setLoading3(false);
		toast(`Error al eliminar ${fields.MOVFRE === 'F' ? 'la' : 'el'} ${type.toLocaleLowerCase()}`, {type: 'error'});
	  })
  }

  const setDefaultIfEmpty = () => {
    let newFields = { ...fields };
    for(let field in fields) {
        if(isEmpty(fields[field])) {
            newFields[field] = initialValues[field];
        }
    }
    setFields(newFields);
    return newFields;
}

const hasIVA4 = () => {
if(
    (!isEmpty(fields['BA4FRE']) && fields.BA4FRE > 0) ||
    (!isEmpty(fields['PI4FRE']) && fields.PI4FRE > 0) ||
    (!isEmpty(fields['CI4FRE']) && fields.CI4FRE > 0) ||
    (!isEmpty(fields['PR4FRE']) && fields.PR4FRE > 0) ||
    (!isEmpty(fields['CR4FRE']) && fields.CR4FRE > 0)
) {
    return true;
}
return false;
}

const hasIVA5 = () => {
if(
    (!isEmpty(fields['BA5FRE']) && fields.BA5FRE > 0) ||
    (!isEmpty(fields['PI5FRE']) && fields.PI5FRE > 0) ||
    (!isEmpty(fields['CI5FRE']) && fields.CI5FRE > 0) ||
    (!isEmpty(fields['PR5FRE']) && fields.PR5FRE > 0) ||
    (!isEmpty(fields['CR5FRE']) && fields.CR5FRE > 0)
) {
    return true;
}
return false;
}

const checkIVA = () => {
if(
    (parseFloat(fields['PI1FRE']) > 0 && parseFloat(fields['PI1FRE']) !== 21 && parseFloat(fields['PI1FRE']) !== 10 && parseFloat(fields['PI1FRE']) !== 4) ||
    (parseFloat(fields['PI2FRE']) > 0 && parseFloat(fields['PI2FRE']) !== 21 && parseFloat(fields['PI2FRE']) !== 10 && parseFloat(fields['PI2FRE']) !== 4) ||
    (parseFloat(fields['PI3FRE']) > 0 && parseFloat(fields['PI3FRE']) !== 21 && parseFloat(fields['PI3FRE']) !== 10 && parseFloat(fields['PI3FRE']) !== 4) ||
    (parseFloat(fields['PI4FRE']) > 0 && parseFloat(fields['PI4FRE']) !== 21 && parseFloat(fields['PI4FRE']) !== 10 && parseFloat(fields['PI4FRE']) !== 4) ||
    (parseFloat(fields['PI5FRE']) > 0 && parseFloat(fields['PI5FRE']) !== 21 && parseFloat(fields['PI5FRE']) !== 10 && parseFloat(fields['PI5FRE']) !== 4)
) {
    setAlert('El tipo de IVA debe ser 21, 10 o 4');
} 
else {
    setAlert('');
}
}

  return (
    <>
      <Card>
        <CardBody>
            <ButtonGroup className='mb-3'>
				<Button
				color="secondary"
				active={fields.MOVFRE === 'F'}
				onClick={() => handleChange("MOVFRE", 'F')}
				disabled={loading || loading2 || loading3 || loading4 || loading5 || loading6 || loading7} 
				>
				Factura Recibida
				</Button>
				<Button
				color="secondary"
				active={fields.MOVFRE === 'G'}
				onClick={() => handleChange("MOVFRE", 'G')}
				disabled={loading || loading2 || loading3 || loading4 || loading5 || loading6 || loading7} 
				>
				Gasto
				</Button>
			</ButtonGroup>
            
          <div className="d-flex align-items-center">
               {fields !== null ? <CardTitle>{!params.id ? 'Crear '+ type : 'Editar '+ type}</CardTitle> : null} 
               {loading2 || loading4 ? <div className="mb-2 ms-2"><Loading text="Cargando..." /></div> : null}
            </div>
          {fields !== null ?
          <Form onSubmit={handleSubmit} noValidate>
            <Row>
                <Col md="6" style={{borderRight: '1px solid #d1d6d8'}}>
                    <Row>
                        <Col md="4">
                            <FormGroup>
                            <Label htmlFor="CODFRE">Nº de registro</Label>
                            <Input 
                                id="CODFRE" 
                                type="number" 
                                name="CODFRE"    
                                onChange={(e) => handleChange("CODFRE", e.target.value)}
                                value={fields["CODFRE"]}
                                invalid={errors["CODFRE"] ? true : false}
                                readOnly
                            />
                            <FormFeedback>{errors["CODFRE"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="8">
                            <FormGroup>
                                <Label htmlFor="ACTFRE">Actividad</Label>
                                <Input 
                                    id="ACTFRE" 
                                    type="select" 
                                    name="ACTFRE"    
                                    onChange={(e) => handleChange("ACTFRE", e.target.value)}
                                    value={fields["ACTFRE"]}
                                    invalid={errors["ACTFRE"] ? true : false}
                                >
                                    <option value="0">[Sin predefinir]</option>
                                    {actividades.map((actividad, key) => {
                                        return <option key={key} value={actividad.CODACT}>{actividad.EPIACT} - {activities[actividad.EPIACT]}</option>
                                    })}
                                </Input>
                                <FormFeedback>{errors["ACTFRE"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="TREFRE">Tipo de gasto</Label>
                                <Input 
                                    id="TREFRE" 
                                    type="select" 
                                    name="TREFRE"   
                                    maxLength={3} 
                                    onChange={(e) => handleChange("TREFRE", e.target.value)}
                                    value={fields["TREFRE"]}
                                    invalid={errors["TREFRE"] ? true : false}
                                >
                                    <option value="">Seleccionar</option>
                                    {tiposDeIngresos.map((tipoDeIngreso, key) => {
                                        return <option key={key} value={tipoDeIngreso.CODTGI}>{tipoDeIngreso.DENTGI}</option>
                                    })}
                                </Input>
                                <FormFeedback>{errors["TREFRE"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="FECFRE">Fecha del registro</Label>
                                <Input 
                                    id="FECFRE" 
                                    type="date" 
                                    name="FECFRE"    
                                    onChange={(e) => {
										handleChange("FECFRE", e.target.value)
										setFields({
											...fields,
											FECFRE: e.target.value,
											FEXFRE: e.target.value,
											FROFRE: e.target.value
										})
									}}
                                    value={fields["FECFRE"] ? moment(fields["FECFRE"]).format('yyyy-MM-DD') : ''}
                                    invalid={errors["FECFRE"] ? true : false}
                                >
                                    <option value=""></option>
                                </Input>
                                <FormFeedback>{errors["FECFRE"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="FEXFRE">Fecha expedición</Label>
                                <Input 
                                    id="FEXFRE" 
                                    type="date" 
                                    name="FEXFRE"    
                                    onChange={(e) => handleChange("FEXFRE", e.target.value)}
                                    value={fields["FEXFRE"] ? moment(fields["FEXFRE"]).format('yyyy-MM-DD') : ''}
                                    invalid={errors["FEXFRE"] ? true : false}
                                >
                                    <option value=""></option>
                                </Input>
                                <FormFeedback>{errors["FEXFRE"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="FROFRE">Fecha registro operación</Label>
                                <Input 
                                    id="FROFRE" 
                                    type="date" 
                                    name="FROFRE"    
                                    onChange={(e) => handleChange("FROFRE", e.target.value)}
                                    value={fields["FROFRE"] ? moment(fields["FROFRE"]).format('yyyy-MM-DD') : ''}
                                    invalid={errors["FROFRE"] ? true : false}
                                >
                                    <option value=""></option>
                                </Input>
                                <FormFeedback>{errors["FROFRE"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <Row>
                                <Col className="d-flex">
                                    <FormGroup>
                                        <Button color="dark" outline onClick={() => setModalSelectProveedor(true)} className="me-2">Seleccionar proveedor</Button>
                                        <Button color="dark" outline onClick={() => setModalProveedor(true)}>Crear proveedor</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="9">
                                    <FormGroup>
                                        <Label htmlFor="NOMFRE">Nombre</Label>
                                        <Input 
                                            id="NOMFRE" 
                                            type="text" 
                                            name="NOMFRE"
                                            maxLength={100}
                                            value={fields["NOMFRE"]}
                                            invalid={errors["NOMFRE"] ? true : false}
                                            disabled
                                        >
                                            <option value=""></option>
                                        </Input>
                                        <FormFeedback>{errors["NOMFRE"]}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <Label htmlFor="PROFRE">&nbsp;</Label>
                                        <Input 
                                            id="PROFRE" 
                                            type="number" 
                                            name="PROFRE"
                                            value={fields["PROFRE"]}
                                            invalid={errors["PROFRE"] ? true : false}
                                            readOnly
                                        />
                                        <FormFeedback>{errors["PROFRE"]}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <Label htmlFor="TIRFRE">Identificación Fiscal</Label>
                                        <Input
                                            id="TIRFRE" 
                                            type="text" 
                                            name="TIRFRE"
                                            value={identificacionFiscal[fields["TIRFRE"]]}
                                            invalid={errors["TIRFRE"] ? true : false}
                                            disabled
                                        />
                                        <FormFeedback>{errors["TIRFRE"]}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Label htmlFor="NIFFRE">N.I.F. / VAT number</Label>
                                        <Input
                                            id="NIFFRE" 
                                            type="text"
                                            name="NIFFRE"
                                            maxLength={18}
                                            value={fields["NIFFRE"]}
                                            invalid={errors["NIFFRE"] ? true : false}
                                            disabled
                                        />
                                        <FormFeedback>{errors["NIFFRE"]}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <Label htmlFor="COPFRE">Clave de operación habitual</Label>
                                        <Input 
                                            id="COPFRE" 
                                            type="select" 
                                            name="COPFRE"    
                                            onChange={(e) => {
												setFields({ 
													...fields, 
													COPFRE: e.target.value,
													NFRFRE: null,
													TIRFRE: null
												});
											}}
                                            value={fields["COPFRE"]}
                                            invalid={errors["COPFRE"] ? true : false}
                                        >
                                            {/* <option value="0">[Sin predefinir]</option> */}
                                            {clienteClaveOperacionHabitual.map((claveOperacion, key) => {
                                                return <option value={key}>{claveOperacion}</option>
                                            })}
                                        </Input>
                                        <FormFeedback>{errors["COPFRE"]}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {
								parseInt(fields.COPFRE) === 4 ? 
									<Row>
										<Col md="6">
											<FormGroup>
												<Label htmlFor="NFRFRE">Factura Rectificada</Label>
												<Input 
													id="NFRFRE" 
													type="text" 
													name="NFRFRE"   
													maxLength={30} 
													onChange={(e) => handleChange("NFRFRE", e.target.value)}
													value={fields["NFRFRE"]}
													invalid={errors["NFRFRE"] ? true : false}
												/>
												<FormFeedback>{errors["NFRFRE"]}</FormFeedback>
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<Label htmlFor="TIRFRE">Tipo de rectificación</Label>
												<Input 
													id="TIRFRE" 
													type="select" 
													name="TIRFRE"    
													onChange={(e) => handleChange("TIRFRE", e.target.value)}
													value={fields["TIRFRE"]}
													invalid={errors["TIRFRE"] ? true : false}
												>
													<option value="">Seleccione</option>
													{tiposDeRetificaciones.map((tipoDeRetificacion, key) => {
														return <option value={key+1}>{key+1} - {tipoDeRetificacion}</option>
													})}
												</Input>
												<FormFeedback>{errors["TIRFRE"]}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>
								: null
							}
                        </Col>
                    </Row>
                </Col>
                <Col md={fields.MOVFRE === 'F' ? 6 : 3}>
                    <Row>
                        <Col md={fields.MOVFRE === 'F' ? 6 : 12}>
                            <FormGroup>
                                <Label htmlFor="FACFRE">{fields.MOVFRE === 'F' ? 'Número de Factura' : 'Nº de Justificante'}</Label>
                                <Input 
                                    id="FACFRE" 
                                    type="text" 
                                    name="FACFRE"    
                                    maxLength={30}
                                    onChange={(e) => handleChange("FACFRE", e.target.value)}
                                    value={fields["FACFRE"]}
                                    invalid={errors["FACFRE"] ? true : false}
                                />
                                <FormFeedback>{errors["FACFRE"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        {fields.MOVFRE === 'F' ? 
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="SERFRE">Serie</Label>
                                <Input 
                                    id="SERFRE" 
                                    type="text" 
                                    name="SERFRE"    
                                    maxLength={255}
                                    onChange={(e) => handleChange("SERFRE", e.target.value)}
                                    value={fields["SERFRE"]}
                                    invalid={errors["SERFRE"] ? true : false}
                                />
                                <FormFeedback>{errors["SERFRE"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        : null}
                    </Row>

                    {/* Si MOVFRE === "F" cambia de ubicación el campo */}
					{fields.MOVFRE === 'G' ?
                    <Row>
                        <Col>
                            <Label htmlFor="DESFRE">Observaciones</Label>
                            <Input 
                                id="DESFRE" 
                                type="textarea" 
                                name="DESFRE"   
                                maxLength={50} 
                                onChange={(e) => handleChange("DESFRE", e.target.value)}
                                value={fields["DESFRE"]}
                                invalid={errors["DESFRE"] ? true : false}
                                style={{height: 100}}
                            />
                            <FormFeedback>{errors["DESFRE"]}</FormFeedback>
                        </Col>
                    </Row>
                    : null}

                    <Row> {/* <Row style={{marginTop: 166}}> */}
                        <Col>
                            <Row>
                                {fields.MOVFRE === 'F' ? 
                                <>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label htmlFor="TIPFRE">Tipo de operación</Label>
                                            <Input 
                                                id="TIPFRE" 
                                                type="select" 
                                                name="TIPFRE"    
                                                onChange={(e) => {
                                                    setFields({
                                                        ...fields,
                                                        TIPFRE:  e.target.value,
                                                        '347FRE': e.target.value !== '1' ? 1 : 0,
                                                        'IELFRE': e.target.value === '2' ? 1 : 0,
                                                    })
                                                }}
                                                value={fields["TIPFRE"]}
                                                invalid={errors["TIPFRE"] ? true : false}
                                            >
                                                <option value="">Seleccionar</option>
                                                <option value="0">General</option>
                                                <option value="1">Intracomunitaria</option>
                                                <option value="2">Importación</option>
                                                <option value="3">Agric. Gan. y Pesca</option>
                                            </Input>
                                            <FormFeedback>{errors["TIPFRE"]}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    {fields.TIPFRE === '2' ? 
                                    <Col md="6">
                                        <Label htmlFor="IELFRE">&nbsp;</Label>
                                        <FormGroup className="d-flex">
                                            <Input
                                                type="checkbox" 
                                                id="IELFRE" 
                                                name="IELFRE"
                                                defaultChecked={fields["IELFRE"]}
                                                onClick={(e) => handleChange("IELFRE", e.target.checked ? 1 : 0)}
                                                invalid={errors["IELFRE"] ? true : false}
                                            />
                                                <Label className="form-check-label" htmlFor="IELFRE">Incluir en la liquidación</Label>
                                            <FormFeedback>{errors["IELFRE"]}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    : null}
                                </>
                                : null}
                            </Row>

                            {parseInt(fields.TIPFRE) === 1 ? 
                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <Label htmlFor="EMIFRE">País</Label>
                                        <Input 
                                            id="EMIFRE" 
                                            type="select" 
                                            name="EMIFRE"
                                            maxLength={3}    
                                            onChange={(e) => [handleChange("EMIFRE", e.target.value)]}
                                            value={fields["EMIFRE"]}
                                            invalid={errors["EMIFRE"] ? true : false}
                                        >
                                            <option value="">Seleccione</option>
                                            {countries.map((country, index) => {
                                                return <option key={index} value={country['ISO3166-1-Alpha-2']}>{country['official_name_es'] ? country['official_name_es'] : country['CLDR display name']}</option>
                                            })}
                                        </Input>
                                        <FormFeedback>{errors["EMIFRE"]}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            : null}

                            <Row>
                                {fields.MOVFRE === 'F' ? 
                                <Col md="9">
                                    <FormGroup>
                                        <Label htmlFor="DEDFRE">Dedución</Label>
                                        <Input 
                                            id="DEDFRE" 
                                            type="select" 
                                            name="DEDFRE"    
                                            onChange={(e) => handleChange("DEDFRE", e.target.value)}
                                            value={fields["DEDFRE"]}
                                            invalid={errors["DEDFRE"] ? true : false}
                                        >
                                            <option value="0">Deducible</option>
                                            <option value="1">No deducible</option>
                                            <option value="2">Prorrata</option>
                                            <option value="3">Prorrata de la deducción</option>
                                        </Input>
                                        <FormFeedback>{errors["DEDFRE"]}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                : null}

                                
                                <Col md={fields.MOVFRE === 'F' ? 3 : 6}>
                                    <FormGroup>
                                        <Label htmlFor="PDEFRE">{fields.MOVFRE === 'F' ? '% Deducible' : '% Deduc.'}</Label>
                                        <NumberFormat 
                                            id="PDEFRE" 
                                            type="number" 
                                            name="PDEFRE"    
                                            onChange={(e) => handleChange("PDEFRE", e.target.value)}
                                            value={fields["PDEFRE"]}
                                            invalid={errors["PDEFRE"] ? true : false}
                                        />
                                    <FormFeedback>{errors["PDEFRE"]}</FormFeedback>
                                    </FormGroup>
                                </Col>

                                {/* Si MOVFEM === "F" el campo cambia de ubicación */}
                                {fields.MOVFRE === 'G' ? 
                                <Col md="6">
                                    <FormGroup>
                                        <Label htmlFor="TOTFRE">Importe</Label>
                                        <NumberFormat 
                                            id="TOTFRE" 
                                            type="number" 
                                            name="TOTFRE"    
                                            onChange={(e) => handleChange("TOTFRE", e.target.value)}
                                            onBlur={(e) => e.target.value === '' ? handleChange("TOTFRE", 0) : null}
                                            value={fields["TOTFRE"]}
                                            invalid={errors["TOTFRE"] ? true : false}
                                        />
                                        <FormFeedback>{errors["TOTFRE"]}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                : null}
                            </Row>
                            <Row>
                                {fields.MOVFRE === 'F' ? 
                                <Col md="9">
                                    <FormGroup>
                                        <Label htmlFor="DIRFRE">Dedución en I.R.P.F.</Label>
                                        <Input 
                                            id="DIRFRE" 
                                            type="select" 
                                            name="DIRFRE"    
                                            onChange={(e) => handleChange("DIRFRE", e.target.value)}
                                            value={fields["DIRFRE"]}
                                            invalid={errors["DIRFRE"] ? true : false}
                                        >
                                            <option value="0">Deducible</option>
                                            <option value="1">No deducible</option>
                                            <option value="2">Prorrata</option>
                                            <option value="3">Prorrata de la deducción</option>
                                        </Input>
                                        <FormFeedback>{errors["DIRFRE"]}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                : null}

                                {fields.MOVFRE === 'F' ? 
                                <Col md="3">
                                    <FormGroup>
                                        <Label htmlFor="PDIFRE">% Deducible</Label>
                                        <NumberFormat 
                                            id="PDIFRE" 
                                            type="number" 
                                            name="PDIFRE"    
                                            onChange={(e) => handleChange("PDIFRE", e.target.value)}
                                            value={fields["PDIFRE"]}
                                            invalid={errors["PDIFRE"] ? true : false}
                                        />
                                    <FormFeedback>{errors["PDIFRE"]}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                : null}
                            </Row>

                            {fields.MOVFRE === 'F' ? 
                            <Row>
                                <Col md="12">
                                    <FormGroup className="d-flex">
                                        <Input
                                            type="checkbox" 
                                            id="NSUFRE" 
                                            name="NSUFRE"
                                            defaultChecked={fields["NSUFRE"]}
                                            onClick={(e) => handleChange("NSUFRE", e.target.checked ? 1 : 0)}
                                            invalid={errors["NSUFRE"] ? true : false}
                                        />
                                            <Label className="form-check-label" htmlFor="NSUFRE">Operaciones no sujetas</Label>
                                        <FormFeedback>{errors["NSUFRE"]}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            : null }

                            <Row>
                                <Col md={fields.MOVFRE === 'F' ? 6 : 12}>
                                    <FormGroup className="d-flex">
                                        <Input
                                            type="checkbox" 
                                            id="347FRE" 
                                            name="347FRE"
                                            defaultChecked={fields["347FRE"]}
                                            checked={fields["347FRE"]}
                                            onClick={(e) => handleChange("347FRE", e.target.checked ? 1 : 0)}
                                            invalid={errors["347FRE"] ? true : false}
                                        />
                                            <Label className="form-check-label" htmlFor="347FRE">Incluir en volumen de operaciones</Label>
                                        <FormFeedback>{errors["347FRE"]}</FormFeedback>
                                    </FormGroup>
                                </Col>

                                {fields.MOVFRE === 'F' ? 
                                <Col md="6">
                                    <FormGroup className="d-flex">
                                        <Input
                                            type="checkbox" 
                                            id="BIEFRE" 
                                            name="BIEFRE"
                                            defaultChecked={fields["BIEFRE"]}
                                            onClick={(e) => handleChange("BIEFRE", e.target.checked ? 1 : 0)}
                                            invalid={errors["BIEFRE"] ? true : false}
                                        />
                                            <Label className="form-check-label" htmlFor="BIEFRE">Bien de inversión</Label>
                                        <FormFeedback>{errors["BIEFRE"]}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                : null}
                            </Row>

                            {fields.MOVFRE === 'F' ? 
                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <Label htmlFor="ayuda_en_el_calculo">Ayuda en el cálculo</Label>
                                        <Input 
                                            id="ayuda_en_el_calculo" 
                                            type="select" 
                                            name="ayuda_en_el_calculo"    
                                            onChange={(e) => handleChange("ayuda_en_el_calculo", e.target.value)}
                                            value={fields["ayuda_en_el_calculo"]}
                                            invalid={errors["ayuda_en_el_calculo"] ? true : false}
                                        >
                                            <option value="">Seleccionar</option>
                                            <option value="0">Manual</option>
                                            <option value="1">Un tipo de IVA</option>
                                            <option value="2">Varios tipos de IVA</option>
                                            <option value="3">Exenta de IVA</option>
                                            <option value="4">Parte exenta, con un tipo de IVA</option>
                                            <option value="5">Parte exenta, con varios tipos de IVA</option>
                                            <option value="6">Un tipo de IVA con I.R.P.F.</option>
                                            <option value="7">Varios tipos de IVA con I.R.P.F.</option>
                                            <option value="8">Exenta de IVA con I.R.P.F.</option>
                                            <option value="9">Parte exenta, con un tipo de IVA con I.R.P.F.</option>
                                            <option value="10">Parte exenta, con varios tipos de IVA con I.R.P.F.</option>
                                        </Input>
                                        <FormFeedback>{errors["ayuda_en_el_calculo"]}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            : null}

                        </Col>
                    </Row>
                </Col>

                {fields.MOVFRE === 'G' ? 
                <Col md="3">
                    <Pagos
                        loading={loading}
                        loading2={loading2}
                        loading3={loading3}
                        loading4={loading4}
                        loading5={loading5}
                        loading6={loading6}
                        loading7={loading7}
                        conceptos={conceptos}
                        params={params}
                        setFieldsConcepto={setFieldsConcepto}
                        setModalConcepto={setModalConcepto}
                        deleteConcepto={deleteConcepto}
                    />
                </Col>
                : null}
            </Row>

            <hr />

            {alert ? <Alert color="warning">{alert}</Alert> : null}
            
            <Row className="mt-4">
                {fields.MOVFRE === 'F' ? 
                <Col md="8">
                    <Row className="mt-4">
                        <Col md="3">
                            <h6 className='mb-4'>Base Imponible</h6>
                            <FormGroup className="d-flex">
                                <Label htmlFor="BA0FRE" className="mt-2 me-2 text-end" style={{minWidth: 45}}>Exenta</Label>
                                <NumberFormat 
                                    id="BA0FRE" 
                                    type="number" 
                                    name="BA0FRE"    
                                    onChange={(e) => handleChange("BA0FRE", e.target.value)}
                                    onBlur={(e) => e.target.value === '' ? handleChange("BA0FRE", 0) : null}  
                                    value={fields["BA0FRE"]}
                                    invalid={errors["BA0FRE"] ? true : false}
                                    readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "2" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7"}
                                />
                                <FormFeedback>{errors["BA0FRE"]}</FormFeedback>
                            </FormGroup>
                            <FormGroup className="d-flex">
                                <Label htmlFor="BA1FRE" className="mt-2 me-2 text-end" style={{minWidth: 45}}>1</Label>
                                <NumberFormat 
                                    id="BA1FRE" 
                                    type="number" 
                                    name="BA1FRE"    
                                    onChange={(e) => handleChange("BA1FRE", e.target.value)}
                                    onBlur={(e) => e.target.value === '' ? handleChange("BA1FRE", 0) : null}
                                    value={fields["BA1FRE"]}
                                    invalid={errors["BA1FRE"] ? true : false}
                                    readOnly={fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "8"}
                                />
                                <FormFeedback>{errors["BA1FRE"]}</FormFeedback>
                            </FormGroup>
                            <FormGroup className="d-flex">
                                <Label htmlFor="BA2FRE" className="mt-2 me-2 text-end" style={{minWidth: 45}}>2</Label>
                                <NumberFormat 
                                    id="BA2FRE" 
                                    type="number" 
                                    name="BA2FRE"    
                                    onChange={(e) => handleChange("BA2FRE", e.target.value)}
                                    onBlur={(e) => e.target.value === '' ? handleChange("BA2FRE", 0) : null}
                                    value={fields["BA2FRE"]}
                                    invalid={errors["BA2FRE"] ? true : false}
                                    readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
                                />
                                <FormFeedback>{errors["BA2FRE"]}</FormFeedback>
                            </FormGroup>
                            <FormGroup className="d-flex">
                                <Label htmlFor="BA3FRE" className="mt-2 me-2 text-end" style={{minWidth: 45}}>3</Label>
                                <NumberFormat 
                                    id="BA3FRE" 
                                    type="number" 
                                    name="BA3FRE"    
                                    onChange={(e) => handleChange("BA3FRE", e.target.value)}
                                    onBlur={(e) => e.target.value === '' ? handleChange("BA3FRE", 0) : null}
                                    value={fields["BA3FRE"]}
                                    invalid={errors["BA3FRE"] ? true : false}
                                    readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
                                />
                                <FormFeedback>{errors["BA3FRE"]}</FormFeedback>
                            </FormGroup>
                            
                            {hasIVA4() || moreIVA >= 1 ?
                            <FormGroup className="d-flex">
                                <Label htmlFor="BA4FRE" className="mt-2 me-2 text-end" style={{minWidth: 45}}>4</Label>
                                <NumberFormat 
                                    id="BA4FRE" 
                                    type="number" 
                                    name="BA4FRE"    
                                    onChange={(e) => handleChange("BA4FRE", e.target.value)}
                                    onBlur={(e) => e.target.value === '' ? handleChange("BA4FRE", 0) : null}
                                    value={fields["BA4FRE"]}
                                    invalid={errors["BA4FRE"] ? true : false}
                                    readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
                                />
                                <FormFeedback>{errors["BA4FRE"]}</FormFeedback>
                            </FormGroup>
                            : null}

                            {hasIVA5() || moreIVA >= 2 ?
                            <FormGroup className="d-flex">
                                <Label htmlFor="BA5FRE" className="mt-2 me-2 text-end" style={{minWidth: 45}}>5</Label>
                                <NumberFormat 
                                    id="BA5FRE" 
                                    type="number" 
                                    name="BA5FRE"    
                                    onChange={(e) => handleChange("BA5FRE", e.target.value)}
                                    onBlur={(e) => e.target.value === '' ? handleChange("BA5FRE", 0) : null}
                                    value={fields["BA5FRE"]}
                                    invalid={errors["BA5FRE"] ? true : false}
                                    readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
                                />
                                <FormFeedback>{errors["BA5FRE"]}</FormFeedback>
                            </FormGroup>
                            : null}
                        </Col>
                        <Col md="3">
                            <h6 className="d-flex mb-4">% IVA <span className='d-block ms-auto'>Cuota</span></h6>
                            <div style={{height: 54}}></div>
                            <div className="d-flex">
                                <div className="me-1">
                                    <FormGroup>
                                        <NumberFormat 
                                            id="PI1FRE" 
                                            type="number" 
                                            name="PI1FRE"    
                                            onChange={(e) => handleChange("PI1FRE", e.target.value)}
                                            onBlur={(e) => {checkIVA(); if(e.target.value === '') handleChange("PI1FRE", 0)}}
                                            value={fields["PI1FRE"]}
                                            invalid={errors["PI1FRE"] ? true : false}
                                            readOnly={fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "8"}
                                        />
                                        <FormFeedback>{errors["PI1FRE"]}</FormFeedback>
                                    </FormGroup>
                                </div>
                                <div className="ms-1">
                                    <FormGroup>
                                        <NumberFormat 
                                            id="CI1FRE" 
                                            type="number" 
                                            name="CI1FRE"    
                                            onChange={(e) => handleChange("CI1FRE", e.target.value)}
                                            onBlur={(e) => e.target.value === '' ? handleChange("CI1FRE", 0) : null}
                                            value={fields["CI1FRE"]}
                                            invalid={errors["CI1FRE"] ? true : false}
                                            readOnly={fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "8"}
                                        />
                                        <FormFeedback>{errors["CI1FRE"]}</FormFeedback>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="me-1">
                                    <FormGroup>
                                        <NumberFormat 
                                            id="PI2FRE" 
                                            type="number" 
                                            name="PI2FRE"    
                                            onChange={(e) => handleChange("PI2FRE", e.target.value)}
                                            onBlur={(e) => {checkIVA(); if(e.target.value === '') handleChange("PI2FRE", 0)}}
                                            value={fields["PI2FRE"]}
                                            invalid={errors["PI2FRE"] ? true : false}
                                            readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
                                        />
                                        <FormFeedback>{errors["PI2FRE"]}</FormFeedback>
                                    </FormGroup>
                                </div>
                                <div className="ms-1">
                                    <FormGroup>
                                        <NumberFormat 
                                            id="CI2FRE" 
                                            type="number" 
                                            name="CI2FRE"    
                                            onChange={(e) => handleChange("CI2FRE", e.target.value)}
                                            onBlur={(e) => e.target.value === '' ? handleChange("CI2FRE", 0) : null}
                                            value={fields["CI2FRE"]}
                                            invalid={errors["CI2FRE"] ? true : false}
                                            readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
                                        />
                                        <FormFeedback>{errors["CI2FRE"]}</FormFeedback>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="me-1">
                                    <FormGroup>
                                        <NumberFormat 
                                            id="PI3FRE" 
                                            type="number" 
                                            name="PI3FRE"    
                                            onChange={(e) => handleChange("PI3FRE", e.target.value)}
                                            onBlur={(e) => {checkIVA(); if(e.target.value === '') handleChange("PI3FRE", 0)}}
                                            value={fields["PI3FRE"]}
                                            invalid={errors["PI3FRE"] ? true : false}
                                            readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
                                        />
                                        <FormFeedback>{errors["PI3FRE"]}</FormFeedback>
                                    </FormGroup>
                                </div>
                                <div className="ms-1">
                                    <FormGroup>
                                        <NumberFormat 
                                            id="CI3FRE" 
                                            type="number" 
                                            name="CI3FRE"    
                                            onChange={(e) => handleChange("CI3FRE", e.target.value)}
                                            onBlur={(e) => e.target.value === '' ? handleChange("CI3FRE", 0) : null}
                                            value={fields["CI3FRE"]}
                                            invalid={errors["CI3FRE"] ? true : false}
                                            readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
                                        />
                                        <FormFeedback>{errors["CI3FRE"]}</FormFeedback>
                                    </FormGroup>
                                </div>
                            </div>

                            {hasIVA4() || moreIVA >= 1 ?
                            <div className="d-flex">
                                <div className="me-1">
                                    <FormGroup>
                                        <NumberFormat 
                                            id="PI4FRE" 
                                            type="number" 
                                            name="PI4FRE"    
                                            onChange={(e) => handleChange("PI4FRE", e.target.value)}
                                            onBlur={(e) => {checkIVA(); if(e.target.value === '') handleChange("PI4FRE", 0)}}
                                            value={fields["PI4FRE"]}
                                            invalid={errors["PI4FRE"] ? true : false}
                                            readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
                                        />
                                        <FormFeedback>{errors["PI4FRE"]}</FormFeedback>
                                    </FormGroup>
                                </div>
                                <div className="ms-1">
                                    <FormGroup>
                                        <NumberFormat 
                                            id="CI4FRE" 
                                            type="number" 
                                            name="CI4FRE"    
                                            onChange={(e) => handleChange("CI4FRE", e.target.value)}
                                            onBlur={(e) => e.target.value === '' ? handleChange("CI4FRE", 0) : null}
                                            value={fields["CI4FRE"]}
                                            invalid={errors["CI4FRE"] ? true : false}
                                            readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
                                        />
                                        <FormFeedback>{errors["CI4FRE"]}</FormFeedback>
                                    </FormGroup>
                                </div>
                            </div>
                            : null}
                            
                            {hasIVA5() || moreIVA >= 2 ?
                            <div className="d-flex">
                                <div className="me-1">
                                    <FormGroup>
                                        <NumberFormat 
                                            id="PI5FRE" 
                                            type="number" 
                                            name="PI5FRE"    
                                            onChange={(e) => handleChange("PI5FRE", e.target.value)}
                                            onBlur={(e) => {checkIVA(); if(e.target.value === '') handleChange("PI5FRE", 0)}}
                                            value={fields["PI5FRE"]}
                                            invalid={errors["PI5FRE"] ? true : false}
                                            readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
                                        />
                                        <FormFeedback>{errors["PI5FRE"]}</FormFeedback>
                                    </FormGroup>
                                </div>
                                <div className="ms-1">
                                    <FormGroup>
                                        <NumberFormat 
                                            id="CI5FRE" 
                                            type="number" 
                                            name="CI5FRE"    
                                            onChange={(e) => handleChange("CI5FRE", e.target.value)}
                                            onBlur={(e) => e.target.value === '' ? handleChange("CI5FRE", 0) : null}
                                            value={fields["CI5FRE"]}
                                            invalid={errors["CI5FRE"] ? true : false}
                                            readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
                                        />
                                        <FormFeedback>{errors["CI5FRE"]}</FormFeedback>
                                    </FormGroup>
                                </div>
                            </div>
                            : null}
                        </Col>
                        <Col md="3">
                            <h6 className="d-flex mb-4">% RE <span className='d-block ms-auto'>Cuota</span></h6>
                            <div style={{height: 54}}></div>
                            <div className="d-flex">
                                <div className="me-1">
                                    <FormGroup>
                                        <NumberFormat 
                                            id="PR1FRE" 
                                            type="number" 
                                            name="PR1FRE"    
                                            onChange={(e) => handleChange("PR1FRE", e.target.value)}
                                            onBlur={(e) => e.target.value === '' ? handleChange("PR1FRE", 0) : null}
                                            value={fields["PR1FRE"]}
                                            invalid={errors["PR1FRE"] ? true : false}
                                            readOnly={fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "8"}
                                        />
                                        <FormFeedback>{errors["PR1FRE"]}</FormFeedback>
                                    </FormGroup>
                                </div>
                                <div className="ms-1">
                                    <FormGroup>
                                        <NumberFormat 
                                            id="CR1FRE" 
                                            type="number" 
                                            name="CR1FRE"    
                                            onChange={(e) => handleChange("CR1FRE", e.target.value)}
                                            onBlur={(e) => e.target.value === '' ? handleChange("CR1FRE", 0) : null}
                                            value={fields["CR1FRE"]}
                                            invalid={errors["CR1FRE"] ? true : false}
                                            readOnly={fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "8"}
                                        />
                                        <FormFeedback>{errors["CR1FRE"]}</FormFeedback>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="me-1">
                                    <FormGroup>
                                        <NumberFormat 
                                            id="PR2FRE" 
                                            type="number" 
                                            name="PR2FRE"    
                                            onChange={(e) => handleChange("PR2FRE", e.target.value)}
                                            onBlur={(e) => e.target.value === '' ? handleChange("PR2FRE", 0) : null}
                                            value={fields["PR2FRE"]}
                                            invalid={errors["PR2FRE"] ? true : false}
                                            readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
                                        />
                                        <FormFeedback>{errors["PR2FRE"]}</FormFeedback>
                                    </FormGroup>
                                </div>
                                <div className="ms-1">
                                    <FormGroup>
                                        <NumberFormat 
                                            id="CR2FRE" 
                                            type="number" 
                                            name="CR2FRE"    
                                            onChange={(e) => handleChange("CR2FRE", e.target.value)}
                                            onBlur={(e) => e.target.value === '' ? handleChange("CR2FRE", 0) : null}
                                            value={fields["CR2FRE"]}
                                            invalid={errors["CR2FRE"] ? true : false}
                                            readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
                                        />
                                        <FormFeedback>{errors["CR2FRE"]}</FormFeedback>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="me-1">
                                    <FormGroup>
                                        <NumberFormat 
                                            id="PR3FRE" 
                                            type="number" 
                                            name="PR3FRE"    
                                            onChange={(e) => handleChange("PR3FRE", e.target.value)}
                                            onBlur={(e) => e.target.value === '' ? handleChange("PR3FRE", 0) : null}
                                            value={fields["PR3FRE"]}
                                            invalid={errors["PR3FRE"] ? true : false}
                                            readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
                                        />
                                        <FormFeedback>{errors["PR3FRE"]}</FormFeedback>
                                    </FormGroup>
                                </div>
                                <div className="ms-1">
                                    <FormGroup>
                                        <NumberFormat 
                                            id="CR3FRE" 
                                            type="number" 
                                            name="CR3FRE"    
                                            onChange={(e) => handleChange("CR3FRE", e.target.value)}
                                            onBlur={(e) => e.target.value === '' ? handleChange("CR3FRE", 0) : null}
                                            value={fields["CR3FRE"]}
                                            invalid={errors["CR3FRE"] ? true : false}
                                            readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
                                        />
                                        <FormFeedback>{errors["CR3FRE"]}</FormFeedback>
                                    </FormGroup>
                                </div>
                            </div>
                            
                            {hasIVA4() || moreIVA >= 1 ?
                            <div className="d-flex">
                                <div className="me-1">
                                    <FormGroup>
                                        <NumberFormat 
                                            id="PR4FRE" 
                                            type="number" 
                                            name="PR4FRE"    
                                            onChange={(e) => handleChange("PR4FRE", e.target.value)}
                                            onBlur={(e) => e.target.value === '' ? handleChange("PR4FRE", 0) : null}
                                            value={fields["PR4FRE"]}
                                            invalid={errors["PR4FRE"] ? true : false}
                                            readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
                                        />
                                        <FormFeedback>{errors["PR4FRE"]}</FormFeedback>
                                    </FormGroup>
                                </div>
                                <div className="ms-1">
                                    <FormGroup>
                                        <NumberFormat 
                                            id="CR4FRE" 
                                            type="number" 
                                            name="CR4FRE"    
                                            onChange={(e) => handleChange("CR4FRE", e.target.value)}
                                            onBlur={(e) => e.target.value === '' ? handleChange("CR4FRE", 0) : null}
                                            value={fields["CR4FRE"]}
                                            invalid={errors["CR4FRE"] ? true : false}
                                            readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
                                        />
                                        <FormFeedback>{errors["CR4FRE"]}</FormFeedback>
                                    </FormGroup>
                                </div>
                            </div>
                            : null}
                            
                            {hasIVA5() || moreIVA >= 2 ?
                            <div className="d-flex">
                                <div className="me-1">
                                    <FormGroup>
                                        <NumberFormat 
                                            id="PR5FRE" 
                                            type="number" 
                                            name="PR5FRE"    
                                            onChange={(e) => handleChange("PR5FRE", e.target.value)}
                                            onBlur={(e) => e.target.value === '' ? handleChange("PR5FRE", 0) : null}
                                            value={fields["PR5FRE"]}
                                            invalid={errors["PR5FRE"] ? true : false}
                                            readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
                                        />
                                        <FormFeedback>{errors["PR5FRE"]}</FormFeedback>
                                    </FormGroup>
                                </div>
                                <div className="ms-1">
                                    <FormGroup>
                                        <NumberFormat 
                                            id="CR5FRE" 
                                            type="number" 
                                            name="CR5FRE"    
                                            onChange={(e) => handleChange("CR5FRE", e.target.value)}
                                            onBlur={(e) => e.target.value === '' ? handleChange("CR5FRE", 0) : null}
                                            value={fields["CR5FRE"]}
                                            invalid={errors["CR5FRE"] ? true : false}
                                            readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "6" || fields.ayuda_en_el_calculo === "7" || fields.ayuda_en_el_calculo === "8" || fields.ayuda_en_el_calculo === "9"}
                                        />
                                        <FormFeedback>{errors["CR5FRE"]}</FormFeedback>
                                    </FormGroup>
                                </div>
                            </div>
                            : null}

                        </Col>
                        <Col md="3">
                            <h6 className="d-flex mb-4">% IRPF <span className='d-block ms-auto'>Cuota</span></h6>
                            <div style={{height: 54}}></div>
                            <div className='d-flex'>
                                <div className='me-1'>
                                    <FormGroup>
                                        <NumberFormat 
                                            id="PRETFRE" 
                                            type="number" 
                                            name="PRETFRE"    
                                            onChange={(e) => handleChange("PRETFRE", e.target.value)}
                                            onBlur={(e) => e.target.value === '' ? handleChange("PRETFRE", 0) : null}
                                            value={fields["PRETFRE"]}
                                            invalid={errors["PRETFRE"] ? true : false}
                                            readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "2" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "5" || fields.ayuda_en_el_calculo === "7"}
                                        />
                                        <FormFeedback>{errors["PRETFRE"]}</FormFeedback>
                                    </FormGroup>
                                </div>
                                <div className='ms-1'>
                                    <FormGroup>
                                        <NumberFormat 
                                            id="IRETFRE" 
                                            type="number" 
                                            name="IRETFRE"    
                                            onChange={(e) => handleChange("IRETFRE", e.target.value)}
                                            onBlur={(e) => e.target.value === '' ? handleChange("IRETFRE", 0) : null}
                                            value={fields["IRETFRE"]}
                                            invalid={errors["IRETFRE"] ? true : false}
                                            readOnly={fields.ayuda_en_el_calculo === "1" || fields.ayuda_en_el_calculo === "2" || fields.ayuda_en_el_calculo === "3" || fields.ayuda_en_el_calculo === "4" || fields.ayuda_en_el_calculo === "5" || fields.ayuda_en_el_calculo === "7"}
                                        />
                                        <FormFeedback>{errors["IRETFRE"]}</FormFeedback>
                                    </FormGroup>
                                </div>
                            </div>
                            <FormGroup>
                                <Label htmlFor="TRETFRE">Tipo de retención</Label>
                                <Input 
                                    id="TRETFRE" 
                                    type="select" 
                                    name="TRETFRE"    
                                    onChange={(e) => handleChange("TRETFRE", e.target.value)}
                                    value={fields["TRETFRE"]}
                                    invalid={errors["TRETFRE"] ? true : false}
                                >
                                    <option value="0">Sin definir</option>
                                    <option value="1">Act. prof. dineraria</option>
                                    <option value="2">Act. prof. especie</option>
                                    <option value="3">Act. agr. dineraria</option>
                                    <option value="4">Act. agr. especie</option>
                                    <option value="5">Arred. dinerario</option>
                                    <option value="6">Arred. especie</option>
                                    <option value="7">Act. empresarial</option>
                                </Input>
                                <FormFeedback>{errors["TRETFRE"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Button 
                        color="dark" 
                        outline 
                        className="d-flex align-items-center"
                        onClick={() => {
                           if(moreIVA < 2) setMoreIVA(moreIVA+1) 
                        }}
                    >
                        <span className="material-icons">add</span> Añadir línea
                    </Button>

                    <h6 className="mt-4">Totales</h6>
                    <Row>
                        <Col md="4">
                            <FormGroup>
                                <Label htmlFor="TOPFRE">Total operación</Label>
                                <NumberFormat 
                                    id="TOPFRE" 
                                    type="number" 
                                    name="TOPFRE"    
                                    onChange={(e) => handleChange("TOPFRE", e.target.value)}
                                    onBlur={(e) => e.target.value === '' ? handleChange("TOPFRE", 0) : null}
                                    value={fields["TOPFRE"]}
                                    invalid={errors["TOPFRE"] ? true : false}
                                />
                                <FormFeedback>{errors["TOPFRE"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label htmlFor="SUPFRE">Suplidos</Label>
                                <NumberFormat 
                                    id="SUPFRE" 
                                    type="number" 
                                    name="SUPFRE"    
                                    onChange={(e) => handleChange("SUPFRE", e.target.value)}
                                    onBlur={(e) => e.target.value === '' ? handleChange("SUPFRE", 0) : null}
                                    value={fields["SUPFRE"]}
                                    invalid={errors["SUPFRE"] ? true : false}
                                />
                                <FormFeedback>{errors["SUPFRE"]}</FormFeedback>
                            </FormGroup>
                        </Col>

                        {/* Si MOVFEM === "G" el campo cambia de ubicación */}
                        {fields.MOVFRE === 'F' ? 
                        <Col md="4">
                            <FormGroup>
                                <Label htmlFor="TOTFRE">Total factura</Label>
                                <NumberFormat 
                                    id="TOTFRE" 
                                    type="number" 
                                    name="TOTFRE"    
                                    onChange={(e) => handleChange("TOTFRE", e.target.value)}
                                    onBlur={(e) => e.target.value === '' ? handleChange("TOTFRE", 0) : null}
                                    value={fields["TOTFRE"]}
                                    invalid={errors["TOTFRE"] ? true : false}
                                />
                                <FormFeedback>{errors["TOTFRE"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        : null}
                    </Row>

                    {/* Si MOVFRE === "G" cambia de ubicación el campo */}
					{fields.MOVFRE === 'F' ?
                    <Row>
                        <Col>
                            <Label htmlFor="DESFRE">Observaciones</Label>
                            <Input 
                                id="DESFRE" 
                                type="textarea" 
                                name="DESFRE"   
                                maxLength={50} 
                                onChange={(e) => handleChange("DESFRE", e.target.value)}
                                value={fields["DESFRE"]}
                                invalid={errors["DESFRE"] ? true : false}
                                style={{height: 100}}
                            />
                            <FormFeedback>{errors["DESFRE"]}</FormFeedback>
                        </Col>
                    </Row>
                    : null}
                </Col>
                : null}
            
                {fields.MOVFRE === 'F' ? 
                <Col md="4">
                    <Pagos
                        loading={loading}
                        loading2={loading2}
                        loading3={loading3}
                        loading4={loading4}
                        loading5={loading5}
                        loading6={loading6}
                        loading7={loading7}
                        conceptos={conceptos}
                        params={params}
                        setFieldsConcepto={setFieldsConcepto}
                        setModalConcepto={setModalConcepto}
                        deleteConcepto={deleteConcepto}
                    />
                </Col>
                : null}
            </Row>

            <br />
            <div className="d-flex">
              <Button type="submit" color="primary" disabled={loading || loading2 || loading3 || loading4 || loading5 || loading6 || loading7}>{!params.id ? 'Crear' : 'Editar'} {loading ? <Spinner size="sm" className="ms-1" /> : null}</Button>
              <Button type="button" color="dark" className="ms-2" outline disabled={loading || loading2 || loading3 || loading4 || loading5 || loading6 || loading7} onClick={() => navigate('/invoices-received')}>Cancelar</Button>
              {params.id ? <Button color="danger" type="button" className="ms-auto" outline disabled={loading || loading2 || loading3 || loading4 || loading5 || loading6 || loading7} onClick={() => setModalDelete(true)}>Eliminar{loading3 ? <Spinner size="sm" className="ms-1" /> : null}</Button> : null}
            </div>
          </Form>
          : 
            loading2 ?
                <Loading />  
            :
                <EmptyData
                title={`No existe ${type.toLowerCase()} con código ${params.id}`}
                btn={`Crear ${type.toLowerCase()}`}
                onClick={() => [setFields({}), navigate('/invoices-received/new')]}
                />
          }
        </CardBody>
      </Card>

      <Modal isOpen={modalDelete} toggle={() => setModalDelete(false)}>
        <ModalHeader toggle={() => setModalDelete(false)}>Eliminar usuario</ModalHeader>
        <ModalBody>
          ¿Está seguro de que desea eliminar {fields.MOVFRE === 'F' ? 'la' : 'el'} {type.toLowerCase()} con código{fields?.CODFRE}?
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            disabled={loading || loading2 || loading3}
            onClick={() => handleDelete()}
          >
            {loading3 ? <Spinner size="sm" className="me-1"/> : null}
            {!loading3 ? 'Eliminar' : 'Eliminando...'}
          </Button>
          {' '}
          <Button outline disabled={loading || loading2 || loading3} onClick={() => setModalDelete(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="xl" isOpen={modalProveedor} toggle={() => setModalProveedor(false)}>
        <ModalHeader toggle={() => setModalProveedor(false)}>CREAR PROVEEDOR</ModalHeader>
        <SupplierForm 
            period={period} 
            source={source} 
            isModal 
            closeModal={(e) => setModalProveedor(false)} 
            save={setNewSupplier}  
        />
      </Modal>
      
      <Modal size="xl" isOpen={modalSelectProveedor} toggle={() => setModalSelectProveedor(false)}>
        <ModalHeader toggle={() => setModalSelectProveedor(false)}>SELECCIONAR PROVEEDOR</ModalHeader>
        <ModalBody>
            <ListGroup>
                {suppliers.map((supply, key) => {
                    return (
                        <ListGroupItem 
                            key={key} 
                            action 
                            onClick={() => {
                                let TIPFRE = null;
								let TIPPRO = supply.TIPPRO;

                                switch(TIPPRO) {
									case 0:
										TIPFRE = 0;
										break;
									case 1:
										TIPFRE = 2;
										break;
									case 2:
										TIPFRE = 1;
										break;
									case 3:
										TIPFRE = 3;
										break;
									default:
										TIPFRE = null;
										break;
								};

                                let CINPRO = parseInt(supply.CINPRO)
                                let _349FRE = null;

                                switch(CINPRO) {
                                    case 1:
                                        _349FRE = 'A';
                                        break;
                                    case 8:
                                        _349FRE = 'C';
                                        break;
                                    case 9:
                                        _349FRE = 'D';
                                        break;
                                    case 2:
                                        _349FRE = 'E';
                                        break;
                                    case 3:
                                        _349FRE = 'H';
                                        break;
                                    case 4:
                                        _349FRE = 'I';
                                        break;
                                    case 5:
                                        _349FRE = 'M';
                                        break;
                                    case 6:
                                        _349FRE = 'S';
                                        break;
                                    case 7:
                                        _349FRE = 'T';
                                        break;
                                }

                                let TDEPRO = parseInt(supply.TDEPRO)
                                let DEDFRE = null;

                                switch(TDEPRO) {
                                    case 1:
                                        DEDFRE = 0;
                                        break;
                                    case 2:
                                        DEDFRE = 1;
                                        break;
                                    case 3:
                                        DEDFRE = 2;
                                        break;
                                    case 4:
                                        DEDFRE = 3;
                                        break;
                                }

                                let TDIPRO = parseInt(supply.TDIPRO)
                                let DIRFRE = null;
                            
                                switch(TDIPRO) {
                                    case 1:
                                        DIRFRE = 0;
                                        break;
                                    case 2:
                                        DIRFRE = 1;
                                        break;
                                    case 3:
                                        DIRFRE = 2;
                                        break;
                                    case 4:
                                        DIRFRE = 3;
                                        break;
                                }

                                setFields({
                                    ...fields,
                                    NOMFRE: supply.NOMPRO,
                                    PROFRE: supply.CODPRO,
                                    TIRFRE: supply.IFIPRO,
                                    NIFFRE: supply.CIFPRO,
                                    COPFRE: supply.COPPRO,

                                    ACTFRE: supply.ACTPRO,
                                    COPFRE: supply.COPPRO,
                                    TIPFRE: TIPFRE,
                                    // ayuda_en_el_calculo: supply.ACPPRO,
                                    TRETFRE: supply.TREPRO,
                                    PRETFRE: supply.RETPRO,
                                    DEDFRE: DEDFRE,

                                    PDEFRE: supply.DEDPRO,
                                    // TDIFRE: supply.TDIPRO,
                                    DIRFRE: DIRFRE,
                                    PDIFRE: supply.DEIPRO,
                                    '349FRE': _349FRE,

                                });
                                setModalSelectProveedor(false);
                            }}
                        >
                            <b>{supply.CODPRO}</b> - {supply.NOMPRO}
                        </ListGroupItem>
                    )
                })}
            </ListGroup>
        </ModalBody>
      </Modal>

      <Modal isOpen={modalConcepto} toggle={() => setModalConcepto(false)} backdrop={'static'}>
        <ModalHeader toggle={() => setModalConcepto(false)}>{fieldsConcepto.LINLCP === null ? 'CREAR' : 'EDITAR'} COBRO</ModalHeader>
        <ModalBody>
            <Row>
                <Col md="6">
                    <FormGroup>
                        <Label htmlFor="CODLCP">Nº de Registro</Label>
                        <Input 
                            id="CODLCP"
                            type="number" 
                            name="CODLCP"    
                            onChange={(e) => handleChangeConcepto("CODLCP", e.target.value)}
                            value={fieldsConcepto["CODLCP"]}
                            invalid={errorsConcepto["CODLCP"] ? true : false}
                            readOnly
                        />
                        <FormFeedback>{errorsConcepto["CODLCP"]}</FormFeedback>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <FormGroup>
                        <Label htmlFor="FECLCP">Fecha de cobro</Label>
                        <Input 
                            id="FECLCP" 
                            type="date" 
                            name="FECLCP"    
                            onChange={(e) => handleChangeConcepto("FECLCP", e.target.value)}
                            value={moment(fieldsConcepto["FECLCP"]).format('yyyy-MM-DD')}
                            invalid={errorsConcepto["FECLCP"] ? true : false}
                        />
                        <FormFeedback>{errorsConcepto["FECLCP"]}</FormFeedback>
                    </FormGroup>
                </Col>
                <Col md="6">
                    <Label>&nbsp;</Label>
                    <FormGroup className="d-flex">
                        <Input
                            type="checkbox" 
                            id="EFELCP" 
                            name="EFELCP"
                            // defaultChecked={fieldsConcepto["EFELCP"]}
                            checked={fieldsConcepto["EFELCP"] === 1 ? true : false}
                            onClick={(e) => handleChangeConcepto("EFELCP", e.target.checked ? 1 : 0)}
                            invalid={errorsConcepto["EFELCP"] ? true : false}
                        />
                            <Label className="form-check-label" htmlFor="EFELCP">Cobro en efectivo</Label>
                        <FormFeedback>{errorsConcepto["EFELCP"]}</FormFeedback>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <FormGroup>
                        <Label htmlFor="IMPLCP">Importe</Label>
                        <NumberFormat 
                            id="IMPLCP" 
                            type="number" 
                            name="IMPLCP"    
                            onChange={(e) => handleChangeConcepto("IMPLCP", e.target.value)}
                            value={fieldsConcepto["IMPLCP"]}
                            invalid={errorsConcepto["IMPLCP"] ? true : false}
                        />
                        <FormFeedback>{errorsConcepto["IMPLCP"]}</FormFeedback>
                    </FormGroup>
                </Col>
            </Row>

            <br />
            <div className="d-flex justify-content-end">
              <Button type="button" color="primary" disabled={loading || loading2 || loading3 || loading4} onClick={() => saveConcepto()}>Aceptar {loading6 ? <Spinner size="sm" className="ms-1" /> : null}</Button>
              <Button type="button" color="dark" className="ms-2" outline disabled={loading || loading2 || loading3 || loading4} onClick={() => setModalConcepto(false)}>Cancelar</Button>
            </div>
        </ModalBody>
      </Modal>
    </>
  );
}