import * as React from "react";
import {
  Routes,
  Route
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import Auth from './layouts/Auth';
import App2 from './layouts/App';
import Login from './containers/Login';
import ChangePassword from './containers/ChangePassword';
import Profile from './containers/Profile';
import Users from './containers/Users';
import UserForm from './containers/UserForm';
import Customers from './containers/Customers';
import CustomerForm from './containers/CustomerForm';
import Suppliers from './containers/Suppliers';
import SupplierForm from './containers/SupplierForm';
import IssuedInvoices from './containers/IssuedInvoices';
import InvoiceIssuedForm from './containers/InvoiceIssuedForm';
import InvoicesReceived from './containers/InvoicesReceived';
import InvoiceReceivedForm from './containers/InvoiceReceivedForm';
import InvestmentAssets from './containers/InvestmentAssets';
import InvestmentAssetForm from './containers/InvestmentAssetForm';
import Billings from './containers/Billings';
import BillingForm from './containers/BillingForm';
import Invoice from './components/Invoice';
import Dashboard from './containers/Dashboard';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

export default function App() {
  const [period, setPeriod] = React.useState(null);

  const [customers, setCustomers] = React.useState(null);
  const [suppliers, setSuppliers] = React.useState(null);
  const [issuedInvoices, setIssuedInvoices] = React.useState(null);
  const [invoicesReceived, setInvoicesReceived] = React.useState(null);
  const [investmentAssets, setInvestmentAssets] = React.useState(null);
  const [billings, setBillings] = React.useState(null);
  
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  return (
    // <Invoice />
    <React.Fragment>
      <Routes>
        <Route element={<Auth />}>
          <Route index element={<Login />} />
          <Route path="/login" element={<Login />} />
        </Route>
        {/* <Route path="/clientes" element={<h1>Estás conectado</h1>} /> */}
        <Route element={<App2 period={period} source={source} changePeriod={setPeriod} />}>
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/users/:id" element={<UserForm />} />
          <Route path="/users/new" element={<UserForm />} />
          <Route path="/users" element={<Users />} />
          <Route path="/customers/:id" element={<CustomerForm period={period} source={source} />} />
          <Route path="/customers/new" element={<CustomerForm period={period} source={source} />} />
          <Route path="/customers" element={<Customers period={period} source={source} customers={customers} setCustomers={setCustomers} />} />
          <Route path="/suppliers/:id" element={<SupplierForm period={period} source={source} />} />
          <Route path="/suppliers/new" element={<SupplierForm period={period} source={source} />} />
          <Route path="/suppliers" element={<Suppliers period={period} source={source} suppliers={suppliers} setSuppliers={setSuppliers} />} />
          <Route path="/issued-invoices/:id" element={<InvoiceIssuedForm period={period} source={source} />} />
          <Route path="/issued-invoices/new" element={<InvoiceIssuedForm period={period} source={source} />} />
          <Route path="/issued-invoices" element={<IssuedInvoices period={period} source={source} issuedInvoices={issuedInvoices} setIssuedInvoices={setIssuedInvoices} />} />
          <Route path="/invoices-received/:id" element={<InvoiceReceivedForm period={period} source={source} />} />
          <Route path="/invoices-received/new" element={<InvoiceReceivedForm period={period} source={source} />} />
          <Route path="/invoices-received" element={<InvoicesReceived period={period} source={source} issuedInvoices={invoicesReceived} setIssuedInvoices={setInvoicesReceived} />} />
          <Route path="/investment-assets/:id" element={<InvestmentAssetForm period={period} source={source} />} />
          <Route path="/investment-assets/new" element={<InvestmentAssetForm period={period} source={source} />} />
          <Route path="/investment-assets" element={<InvestmentAssets period={period} source={source} issuedInvoices={investmentAssets} setIssuedInvoices={setInvestmentAssets} />} />
          <Route path="/billings/:id/:serie" element={<BillingForm period={period} source={source} />} />
          <Route path="/billings/new" element={<BillingForm period={period} source={source} />} />
          <Route path="/billings" element={<Billings period={period} source={source} issuedInvoices={billings} setIssuedInvoices={setBillings} />} />
          <Route path="/dashboard" element={<Dashboard period={period} source={source} />} />
          
          {/* <Route path="/clientes" element={<Clientes />} /> */}
          {/* <Route path="/products" element={<Products />} />
          <Route path="/categories" element={<Products />} /> */}
        </Route>
        <Route path="*" element={<h1>Page Not Found 404</h1>}/>
      </Routes>
      <ToastContainer />
    </React.Fragment>
  );
}
