import * as React from "react";

export default function Drawer({ children, title, style, direction, width, isOpen, onClose }) {
    const _width = width ? width : 300;

    return (
        <>
            <div 
                style={{
                    backgroundColor: '#00000050',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 9998,
                    visibility: isOpen ? 'visible' : 'hidden',
                    opacity: isOpen ? 1 : 0,
                    transition: 'all 0.3s ease-in-out'
                }}
                onClick={onClose}
            />
            <div 
                style={{
                    position: 'fixed',
                    top: 0,
                    left: direction === 'left' ? (isOpen ? 0 : -_width) : null,
                    right: direction === 'right' ? (isOpen ? 0 : -_width) : null,
                    backgroundColor: '#fff',
                    width: _width,
                    maxWidth: '100%',
                    height: '100%',
                    zIndex: 9999,
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
                    transition: 'all 0.3s ease-in-out',
                    ...style,
                }}
            >
                <div
                    className="d-flex justify-content-between align-items-center"
                    style={{

                    }}
                >
                    <h5 className="p-3">{title}</h5>
                    <div className="p-3" style={{cursor: 'pointer'}} onClick={onClose}><span className="material-icons">close</span></div>
                </div>
                <div className="p-3 pt-0">
                    {children}
                </div>
            </div>
        </>
    );
}