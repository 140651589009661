import NumberFormat from 'react-number-format';

export default function (props) {
    const { type, className } = props;
    return (
        <NumberFormat 
            {...props} 
            type='text'
            className={`${type === 'number' ? ' form-control ' : ' '} ${className}`}
            thousandSeparator={'.'} 
            decimalSeparator={','} 
            decimalScale={2} 
            fixedDecimalScale={true}
            onChange={() => {}}
            onValueChange={(e) => props.onChange ? props.onChange({target: {value: e.floatValue}}) : null}
        />
    )
}