import * as React from "react";
import { Button } from 'reactstrap';

export default function EmptyData({ title, btn, onClick }) {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center">
            <h6>{title}</h6>
            <div>
                <Button color="primary" className="d-flex align-items-center" onClick={() => onClick()}><span className="material-icons">add</span> {btn}</Button>
            </div>
        </div>
    );
}