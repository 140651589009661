import * as React from 'react';
import { Card, CardBody, CardTitle, CardHeader, Row, Col, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import Loading from '../components/Loading';
import EmptyData from '../components/EmptyData';
import Drawer from '../components/Drawer';
import Filter from '../components/Filter';
import activities from '../global/activities';
import NumberFormat from '../components/NumberFormat';

export default function InvestmentAssets({ period, source, issuedInvoices, setIssuedInvoices }) {
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [modalDelete, setModalDelete] = React.useState(null);
  const [modalCopy, setModalCopy] = React.useState(null);
  const [drawer, setDrawer] = React.useState(false);
  const [actividades, setActividades] = React.useState([]);
  const [suppliers, setSuppliers] = React.useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  React.useEffect(() => {
    getData();
  }, [period])

  const getData = (filter='') => {
    setLoading(true);
    axios.get(`/api/investment-assets/?ejercicio=${period}&filtro=CODINM>=0 ${filter ? filter : ''} ORDER BY CODINM DESC`, {
      cancelToken: source.token
    })
      .then(res => {
        setIssuedInvoices(res.data);
        setLoading(false);
        setDrawer(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setIssuedInvoices([]);
          setLoading(false);
          setDrawer(false);
        }
      })

    axios.get(`/api/issued-invoices/actividades/?ejercicio=${period}&filtro=CODACT>=0`, {
      cancelToken: source.token
      })
      .then(res => {
        console.log('res', res.data)
        setActividades(res.data);
      })

    axios.get(`/api/investment-assets/suppliers/?ejercicio=${period}&filtro=CODPRO>=0 ORDER BY CODPRO DESC`, {
        cancelToken: source.token
        })
        .then(res => {
            setSuppliers(res.data);
        })
  }

  const handleCopy = (e, item) => {
    e.preventDefault();
    setLoading2(true);

    axios.get(`/api/investment-assets/last_id/?ejercicio=${period}`, {
      cancelToken: source.token
    })
      .then(res => {
        let data = {...item, CODINM: res.data}
        axios.post(`/api/investment-assets/?ejercicio=${period}`, data)
          .then(res => {
            setLoading2(false);
            setModalCopy(null);
            let _issuedInvoices = [...issuedInvoices]
            _issuedInvoices.unshift(data)
            setIssuedInvoices(_issuedInvoices);
            toast('Item copiado con éxito', {type: 'success'});
            navigate(`/investment-assets/${data.CODINM}?period=${searchParams.get('period')}`)
          })
          .catch((err) => {
            err.response.data.forEach((error, key) => {
                setLoading(false);
                toast(error, {type: 'error'});
            })
          })
      })
      .catch(err => {
        setLoading2(false);
        setModalCopy(null);
        toast(JSON.stringify(err.response.data), {type: 'error'});
      })
  }

  const handleDelete = (e, id) => {
    e.preventDefault();
    setLoading2(true);

    axios.delete(`/api/investment-assets/${id}/?ejercicio=${period}`)
      .then(res => {
        setLoading2(false);
        setModalDelete(null);
        const newData = issuedInvoices.filter(item => item.CODINM !== id);
        setIssuedInvoices(newData);
        toast('Bien de inversión eliminado con éxito', {type: 'success'});
      })
      .catch(err => {
        setLoading2(false);
        setModalDelete(null);
        toast(JSON.stringify(err.response.data), {type: 'error'});
      })
  }

  return (
    <>
      <Card>
        <CardHeader className="border-0 pb-0">
          <Row>
              <Col className="d-flex align-items-center">
                <CardTitle>Bienes de Inversión</CardTitle>
                { loading && issuedInvoices ? <div className="mb-2 ms-2"><Loading text="Actualizando..." /></div> : null }
              </Col>
              <Col className="d-flex justify-content-end">
                <div className="d-flex">
                  {/* <Button size="sm" color="dark" outline className="d-flex align-items-center" onClick={() => setDrawer(true)}><span className="material-icons">filter_list</span> Filtrar</Button> */}
                  <Button size="sm" color="primary" className="d-flex align-items-center ms-2" onClick={() => navigate(`/investment-assets/new?period=${searchParams.get('period')}`)}><span className="material-icons">add</span> Crear bien de inversión</Button>
                </div>
              </Col>
            </Row>
        </CardHeader>
        <CardBody>
          {issuedInvoices && issuedInvoices.length >= 0  ?
            issuedInvoices.length > 0 ?
            <>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Código</th>
                    <th>Act.</th>
                    <th>Descripción</th>
                    <th>Nº serie 1</th>
                    <th>Nº serie 2</th>
                    <th>Fecha</th>
                    <th>Precio</th>
                    <th>Nº Factura</th>
                    <th>Proveedor</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {issuedInvoices.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>{item.CODINM}</td>
                        <td>{actividades.find(actividad => actividad.CODACT === item?.ACTINM)?.EPIACT} - {activities[actividades.find(actividad => actividad.CODACT === item?.ACTINM)?.EPIACT]}</td>
                        <td>{item.NOMINM}</td>
                        <td>{item.NS1INM}</td>
                        <td>{item.NS2INM}</td>
                        <td>{moment(item.FCOINM).format('DD/MM/YYYY')}</td>
                        <td>{<NumberFormat value={item.PCOINM} displayType={'text'} />}</td>
                        <td>{item.NFAINM}</td>
                        <td>{suppliers.find(supply => supply.CODPRO === item.PROINM)?.NOMPRO}</td>
                        <td className="d-flex">
                          <Button size="sm" outline className="pb-0 me-1" onClick={() => navigate(`/investment-assets/${item.CODINM}?period=${searchParams.get('period')}`)}><span className="material-icons fs-6">edit</span></Button>
                          <Button size="sm" outline className="pb-0 me-1" onClick={() => setModalCopy(item)}><span className="material-icons fs-6">content_copy</span></Button>
                          <Button size="sm" color="danger" outline className="pb-0 me-1" onClick={() => setModalDelete(item.CODINM)}><span className="material-icons fs-6">delete</span></Button>
                          
                          <Modal isOpen={modalCopy === item} toggle={() => setModalCopy(null)}>
                            <ModalHeader toggle={() => setModalCopy(null)}>Copiar bien de inversión</ModalHeader>
                            <ModalBody>
                              ¿Está seguro de que desea copiar el bien de inversión {item.CODINM}?
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                type="button"
                                color="primary"
                                disabled={loading2}
                                onClick={(e) => handleCopy(e, item)}
                              >
                                {loading2 ? <Spinner size="sm" className="me-1"/> : null}
                                {!loading2 ? 'Copiar' : 'Copiando...'}
                              </Button>
                              {' '}
                              <Button outline disabled={loading2} onClick={() => setModalCopy(null)}>
                                Cancel
                              </Button>
                            </ModalFooter>
                          </Modal>
                          
                          <Modal isOpen={modalDelete === item.CODINM} toggle={() => setModalDelete(null)}>
                            <ModalHeader toggle={() => setModalDelete(null)}>Eliminar bien de inversión</ModalHeader>
                            <ModalBody>
                              ¿Está seguro de que desea eliminar la bien de inversión con código {item.CODINM}?
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                type="button"
                                color="danger"
                                disabled={loading2}
                                onClick={(e) => handleDelete(e, item.CODINM)}
                              >
                                {loading2 ? <Spinner size="sm" className="me-1"/> : null}
                                {!loading2 ? 'Eliminar' : 'Eliminando...'}
                              </Button>
                              {' '}
                              <Button outline disabled={loading2} onClick={() => setModalDelete(null)}>
                                Cancel
                              </Button>
                            </ModalFooter>
                          </Modal>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
              {/* <Pagination data={data} /> */}
            </>
            :
              <EmptyData
                title="No hay bienes de inversión registrados"
                btn="Crear bien de inversión"
                onClick={() => navigate(`/investment-assets/new?period=${searchParams.get('period')}`)}
              />
          : 
              <Loading />
          }
        </CardBody>
      </Card>

      <Drawer 
        isOpen={drawer} 
        direction={'right'}
        title="Filtrar"
        onClose={() => setDrawer(false)}
      >
        <Filter 
          source={source} 
          period={period}
          type="investment-assets"
          loading={loading}
          onFilter={getData}
        />
      </Drawer>
    </>
  );
}