export default [
    'Factura Rectificativa (Error fundado en derecho y Art. 80 Uno Dos y Seis LIVA)',
    'Factura Rectificativa (Art. 80.3)',
    'Factura Rectificativa (Art. 80.4)',
    'Factura Rectificativa (Resto)',
    'Factura Rectificativa en facturas simplificadas',
    'Factura Rectificativa Por Diferencias (Error fundado en derecho y Art. 80 Uno...',
    'Factura Rectificativa Por Diferencias (Art. 80.3)',
    'Factura Rectificativa Por Diferencias (Art. 80.4)',
    'Factura Rectificativa Por Diferencias (Resto)',
    'Factura Rectificativa Por Diferencias en facturas simplificadas'
]