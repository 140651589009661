import * as React from 'react';
import { Card, CardBody, CardTitle, CardHeader, Row, Col, Table, Badge, Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loading from '../components/Loading';
import EmptyData from '../components/EmptyData';

export default function Suppliers({ period, source, suppliers, setSuppliers }) {
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [modalDelete, setModalDelete] = React.useState(null);
  const [modalCopy, setModalCopy] = React.useState(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  React.useEffect(() => {
    getData();
  }, [period])

  const getData = () => {
    setLoading(true);
    axios.get(`/api/suppliers/?ejercicio=${period}&filtro=CODPRO>=0 ORDER BY CODPRO DESC`, {
      cancelToken: source.token
    })
      .then(res => {
        setSuppliers(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setSuppliers([]);
          setLoading(false);
        }
      })
  }

  const handleCopy = (e, item) => {
    e.preventDefault();
    setLoading2(true);

    axios.get(`/api/suppliers/last_id/?ejercicio=${period}`, {
      cancelToken: source.token
    })
      .then(res => {
        let data = {...item, CODPRO: res.data}
        axios.post(`/api/suppliers/?ejercicio=${period}`, data)
          .then(res => {
            setLoading2(false);
            setModalCopy(null);
            let _suppliers = [...suppliers]
            _suppliers.unshift(data)
            setSuppliers(_suppliers);
            toast('Item copiado con éxito', {type: 'success'});
            navigate(`/suppliers/${data.CODPRO}?period=${searchParams.get('period')}`)
          })
          .catch((err) => {
            err.response.data.forEach((error, key) => {
                setLoading(false);
                toast(error, {type: 'error'});
            })
          })
      })
      .catch(err => {
        setLoading2(false);
        setModalCopy(null);
        toast(JSON.stringify(err.response.data), {type: 'error'});
      })
  }

  const handleDelete = (e, id) => {
    e.preventDefault();
    setLoading2(true);

    axios.delete(`/api/suppliers/${id}/?ejercicio=${period}`)
      .then(res => {
        setLoading2(false);
        setModalDelete(null);
        const newData = suppliers.filter(item => item.CODPRO !== id);
        setSuppliers(newData);
        toast('Proveedor eliminado con éxito', {type: 'success'});
      })
      .catch(err => {
        setLoading2(false);
        setModalDelete(null);
        toast(JSON.stringify(err.response.data), {type: 'error'});
      })
  }

  return (
    <Card>
      <CardHeader className="border-0 pb-0">
        <Row>
            <Col className="d-flex align-items-center">
              <CardTitle>Proveedores</CardTitle>
              { loading && suppliers ? <div className="mb-2 ms-2"><Loading text="Actualizando..." /></div> : null }
            </Col>
            <Col className="d-flex justify-content-end">
              <div>
                <Button size="sm" color="primary" className="d-flex align-items-center" onClick={() => navigate(`/suppliers/new?period=${searchParams.get('period')}`)}><span className="material-icons">add</span> Crear proveedor</Button>
              </div>
            </Col>
          </Row>
      </CardHeader>
      <CardBody>
        {suppliers && suppliers.length >= 0  ?
          suppliers.length > 0 ?
          <>
            <Table responsive>
              <thead>
                <tr>
                  <th>Código</th>
                  <th>Nombre Fiscal</th>
                  <th>Nombre Comercial</th>
                  <th>Teléfono</th>
                  <th>Móvil</th>
                  <th>N.I.F.</th>
                  <th>Población</th>
                  <th>Provincia</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {suppliers.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td>{item.CODPRO}</td>
                      <td>{item.NOMPRO}</td>
                      <td>{item.NCOPRO}</td>
                      <td>{item.TELPRO}</td>
                      <td>{item.MOVPRO}</td>
                      <td>{item.CIFPRO}</td>
                      <td>{item.POBPRO}</td>
                      <td>{item.PROPRO}</td>
                      <td className="d-flex">
                        <Button size="sm" outline className="pb-0 me-1" onClick={() => navigate(`/suppliers/${item.CODPRO}?period=${searchParams.get('period')}`)}><span className="material-icons fs-6">edit</span></Button>
                        <Button size="sm" outline className="pb-0 me-1" onClick={() => setModalCopy(item)}><span className="material-icons fs-6">content_copy</span></Button>
                        <Button size="sm" color="danger" outline className="pb-0 me-1" onClick={() => setModalDelete(item.CODPRO)}><span className="material-icons fs-6">delete</span></Button>
                        
                        <Modal isOpen={modalCopy === item} toggle={() => setModalCopy(null)}>
                          <ModalHeader toggle={() => setModalCopy(null)}>Copiar proveedor</ModalHeader>
                          <ModalBody>
                            ¿Está seguro de que desea copiar el proveedor {item.NOMPRO}?
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              type="button"
                              color="primary"
                              disabled={loading2}
                              onClick={(e) => handleCopy(e, item)}
                            >
                              {loading2 ? <Spinner size="sm" className="me-1"/> : null}
                              {!loading2 ? 'Copiar' : 'Copiando...'}
                            </Button>
                            {' '}
                            <Button outline disabled={loading2} onClick={() => setModalCopy(null)}>
                              Cancel
                            </Button>
                          </ModalFooter>
                        </Modal>

                        <Modal isOpen={modalDelete === item.CODPRO} toggle={() => setModalDelete(null)}>
                          <ModalHeader toggle={() => setModalDelete(null)}>Eliminar proveedor</ModalHeader>
                          <ModalBody>
                            ¿Está seguro de que desea eliminar el proveedor {item.NOMPRO}?
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              type="button"
                              color="danger"
                              disabled={loading2}
                              onClick={(e) => handleDelete(e, item.CODPRO)}
                            >
                              {loading2 ? <Spinner size="sm" className="me-1"/> : null}
                              {!loading2 ? 'Eliminar' : 'Eliminando...'}
                            </Button>
                            {' '}
                            <Button outline disabled={loading2} onClick={() => setModalDelete(null)}>
                              Cancel
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            {/* <Pagination data={data} /> */}
          </>
          :
            <EmptyData
              title="No hay proveedores registrados"
              btn="Crear proveedor"
              onClick={() => navigate(`/?period=${searchParams.get('period')}`)}
            />
        : 
            <Loading />
        }
      </CardBody>
    </Card>
  );
}