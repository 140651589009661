import * as React from "react";
import { Pagination as Paginations, PaginationItem, PaginationLink} from 'reactstrap';
import { useSearchParams } from 'react-router-dom';

export default function Pagination({ data }) {
    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <div className="d-flex align-items-center justify-content-end">
            <div className="mb-3 me-2">Página {searchParams.get('page') ? searchParams.get('page') : '1'} de {Math.ceil(data.count / 10)}</div>
            <Paginations>
                <PaginationItem>
                    <PaginationLink
                    previous
                    disabled={!data.previous}
                    onClick={() => setSearchParams({page: 1})}
                    />
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                    next
                    disabled={!data.next}
                    onClick={() => setSearchParams({page: 2})}
                    />
                </PaginationItem>
            </Paginations>
        </div>
    );
}