import axios from 'axios';
import * as React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Loading from '../components/Loading';
import EmptyData from '../components/EmptyData';
import { isEmpty, validEmail, validURL } from '../lib/validator';
import countries from '../global/countries';
import activities from '../global/activities';
import clienteClaveOperacionHabitual from '../global/cliente_clave_operacion_habitual';
import NumberFormat from '../components/NumberFormat';

const initialValues = {
    ACTPRO: '0',
    TREPRO: '0',
    COPPRO: '0',
    IVAPRO: '0.0',
    RECPRO: '0.0',
    TPIPRO: '1',
    TDEPRO: '1',
    TDIPRO: '1',
    DEDPRO: 100.00,
    DEIPRO: 100.00,
    COPPRO: '1',
    TIPPRO: '0',
}

export default function SupplierForm({ period, source, isModal, closeModal, save }) {
  const [fields, setFields] = React.useState(initialValues);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [loading3, setLoading3] = React.useState(false);
  const [loading4, setLoading4] = React.useState(false);
  const [modalDelete, setModalDelete] = React.useState(false);
  const [tab, setTab] = React.useState('1');
  const [actividades, setActividades] = React.useState([]);
  const [tiposDeGastos, setTiposDeGastos] = React.useState([]);
  const [locales, setLocales] = React.useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const params = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    getData();
  }, [period])

  const getData = () => {
    axios.get(`/api/suppliers/actividades/?ejercicio=${period}&filtro=CODACT>=0`, {
        cancelToken: source.token
      })
        .then(res => {
            setActividades(res.data);
        })

    axios.get(`/api/suppliers/tipos_de_gastos/?ejercicio=${period}&filtro=LIBTGI=0 ORDER BY CODTGI`, {
        cancelToken: source.token
        })
        .then(res => {
            setTiposDeGastos(res.data);
        })

    axios.get(`/api/suppliers/locales/?ejercicio=${period}&filtro=CODLOC>=0 ORDER BY CODLOC`, {
        cancelToken: source.token
        })
        .then(res => {
            setLocales(res.data);
        })

    if(params.id && !isModal) {
        setLoading2(true);
        axios.get(`/api/suppliers/${params.id ? params.id +'/' : ''}?ejercicio=${period}&filtro=CODPRO=${params.id}`, {
            cancelToken: source.token
          })
          .then(res => {
            setLoading2(false);
            setFields(res.data.CODPRO ? res.data : null);
          })
    } else {
        setFields({ ...fields });
        setLoading4(true)
        axios.get(`/api/suppliers/last_id/?ejercicio=${period}`, {
            cancelToken: source.token
          })
            .then(res => {
                setLoading4(false);
                setFields({...fields, CODPRO: res.data});
            })
    }
  }

  const handleChange = (field, value) => {
    setFields({ ...fields, [field]: value });
  }

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (isEmpty(fields['CODPRO'])) {
        formIsValid = false;
        errors['CODPRO'] = 'Campo obligatorio.';
    }

    if (isEmpty(fields['NOMPRO'])) {
        formIsValid = false;
        errors['NOMPRO'] = 'Campo obligatorio.';
    }

    if (!isEmpty(fields['EMAPRO']) && !validEmail(fields['EMAPRO'])) {
        formIsValid = false;
        errors['EMAPRO'] = 'Correo electrónico con formato incorrecto.';
    }

    if(!isEmpty(fields['WEBPRO']) && !validURL(fields['WEBPRO'])) {
        formIsValid = false;
        errors['WEBPRO'] = 'URL con formato no válido.';
    }

    setErrors(errors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      setSupplier()
    }
  }

  const setSupplier = () => {
    setLoading(true);

    axios[!params.id || isModal ? 'post' : 'put'](`/api/suppliers/${params.id && !isModal ? params.id +'/' : ''}?ejercicio=${period}`, {...fields, CODPRO: fields['CODPRO']})
      .then(res => {
        setLoading(false);
        toast(!params.id ? 'Proveedor creado con éxito' : 'Proveedor actualizado con éxito', {type: 'success'});
        if(!params.id && !isModal) navigate(`/suppliers/${res.data.CODPRO}`)
        if(isModal) {
            save(fields);
            closeModal(false);
        }
      })
      .catch((err) => {
        err.response.data.forEach((error, key) => {
            setLoading(false);
            toast(error, {type: 'error'});
        })
      })
  }

  const handleDelete = () => {
    setLoading3(true);

    axios.delete(`/api/suppliers/${params.id}/?ejercicio=${period}`)
      .then(res => {
        setLoading3(false);
        toast('Proveedor eliminado con éxito', {type: 'success'});
        navigate(`/suppliers/`)
      })
  }

  return (
    <>
      <Card style={{margin: isModal ? 0 : null}}>
        <CardBody>
            <div className="d-flex align-items-center">
                {fields !== null && !isModal ? <CardTitle>{!params.id ? 'Crear proveedor' : 'Editar proveedor'}</CardTitle> : null} 
                {loading2 || loading4 ? <div className="mb-2 ms-2"><Loading text="Cargando..." /></div> : null}
            </div>

          {fields !== null ?
          <Form onSubmit={handleSubmit} noValidate>
            <Row>
              <Col md="3">
                <FormGroup>
                  <Label htmlFor="CODPRO">Código</Label>
                  <Input 
                    id="CODPRO" 
                    type="number" 
                    name="CODPRO"    
                    onChange={(e) => handleChange("CODPRO", e.target.value)}
                    value={fields["CODPRO"]}
                    invalid={errors["CODPRO"] ? true : false}
                    readOnly
                  />
                  <FormFeedback>{errors["CODPRO"]}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                    <Label htmlFor="CIFPRO">N.I.F.</Label>
                    <Input 
                        id="CIFPRO" 
                        type="text" 
                        name="CIFPRO"  
                        maxLength={18}  
                        onChange={(e) => handleChange("CIFPRO", e.target.value)}
                        value={fields["CIFPRO"]}
                        invalid={errors["CIFPRO"] ? true : false}
                    />
                  <FormFeedback>{errors["CIFPRO"]}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="NOMPRO">Nombre fiscal</Label>
                  <Input 
                    id="NOMPRO" 
                    type="text" 
                    name="NOMPRO"
                    maxLength={100}
                    onChange={(e) => {
                        setFields({ 
                            ...fields,
                            NOMPRO: e.target.value,
                            NCOPRO: e.target.value
                        })
                    }}
                    value={fields["NOMPRO"]}
                    invalid={errors["NOMPRO"] ? true : false}
                  />
                  <FormFeedback>{errors["NOMPRO"]}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                    <Label htmlFor="NCOPRO">Nombre comercial</Label>
                    <Input 
                        id="NCOPRO" 
                        type="text" 
                        name="NCOPRO"   
                        maxLength={100} 
                        onChange={(e) => handleChange("NCOPRO", e.target.value)}
                        value={fields["NCOPRO"]}
                        invalid={errors["NCOPRO"] ? true : false}
                    />
                  <FormFeedback>{errors["NCOPRO"]}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            <Row>
            <Nav tabs className="mt-3">
                <NavItem>
                    <NavLink className={`${tab === '1' ? 'active' : ''}`} onClick={() => setTab('1')}>Contact</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={`${tab === '2' ? 'active' : ''}`} onClick={() => setTab('2')}>Parámetros</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={`${tab === '3' ? 'active' : ''}`} onClick={() => setTab('3')}>Observaciones</NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={tab} className="p-3">
                <TabPane tabId="1">
                    <Row>
                        <Col md="2">
                            <FormGroup>
                                <Label htmlFor="SIGPRO">Siglas del domicilio</Label>
                                <Input 
                                    id="SIGPRO" 
                                    type="text" 
                                    name="SIGPRO"   
                                    maxLength={2} 
                                    onChange={(e) => handleChange("SIGPRO", e.target.value)}
                                    value={fields["SIGPRO"]}
                                    invalid={errors["SIGPRO"] ? true : false}
                                />
                                <FormFeedback>{errors["SIGPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="8">
                            <FormGroup>
                                <Label htmlFor="DOMPRO">Domicilio</Label>
                                <Input 
                                    id="DOMPRO" 
                                    type="text" 
                                    name="DOMPRO"    
                                    maxLength={100}
                                    onChange={(e) => handleChange("DOMPRO", e.target.value)}
                                    value={fields["DOMPRO"]}
                                    invalid={errors["DOMPRO"] ? true : false}
                                />
                                <FormFeedback>{errors["DOMPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="2">
                            <FormGroup>
                                <Label htmlFor="NUMPRO">Número</Label>
                                <Input 
                                    id="NUMPRO" 
                                    type="number" 
                                    name="NUMPRO" 
                                    maxLength={6}   
                                    onChange={(e) => handleChange("NUMPRO", e.target.value)}
                                    value={fields["NUMPRO"]}
                                    invalid={errors["NUMPRO"] ? true : false}
                                />
                                <FormFeedback>{errors["NUMPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="2">
                            <FormGroup>
                                <Label htmlFor="CPOPRO">Código Postal</Label>
                                <Input 
                                    id="CPOPRO" 
                                    type="text" 
                                    name="CPOPRO"    
                                    maxLength={15}
                                    onChange={(e) => handleChange("CPOPRO", e.target.value)}
                                    value={fields["CPOPRO"]}
                                    invalid={errors["CPOPRO"] ? true : false}
                                />
                                <FormFeedback>{errors["CPOPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="10">
                            <FormGroup>
                                <Label htmlFor="POBPRO">Población</Label>
                                <Input 
                                    id="POBPRO" 
                                    type="text" 
                                    name="POBPRO"    
                                    maxLength={30}
                                    onChange={(e) => handleChange("POBPRO", e.target.value)}
                                    value={fields["POBPRO"]}
                                    invalid={errors["POBPRO"] ? true : false}
                                />
                                <FormFeedback>{errors["POBPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="5">
                            <FormGroup>
                                <Label htmlFor="PROPRO">Província</Label>
                                <Input 
                                    id="PROPRO" 
                                    type="text" 
                                    name="PROPRO"  
                                    maxLength={20}  
                                    onChange={(e) => handleChange("PROPRO", e.target.value)}
                                    value={fields["PROPRO"]}
                                    invalid={errors["PROPRO"] ? true : false}
                                />
                                <FormFeedback>{errors["PROPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="5">
                            <FormGroup>
                                <Label htmlFor="PAIPRO">País</Label>
                                <Input 
                                    id="PAIPRO" 
                                    type="select" 
                                    name="PAIPRO"
                                    maxLength={3}    
                                    onChange={(e) => [handleChange("PAIPRO", e.target.value)]}
                                    value={fields["PAIPRO"]}
                                    invalid={errors["PAIPRO"] ? true : false}
                                >
                                    <option value=""></option>
                                    {countries.map((country, index) => {
                                        return <option key={index} value={country.code}>{country.name}</option>
                                    })}
                                </Input>
                                <FormFeedback>{errors["PAIPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="2">
                            <FormGroup>
                                <Label htmlFor="country">Código del país</Label>
                                <Input 
                                    id="country"
                                    type="text" 
                                    value={fields["PAIPRO"]}
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="TELPRO">Teléfono</Label>
                                <Input 
                                    id="TELPRO" 
                                    type="text" 
                                    name="TELPRO"  
                                    maxLength={12}  
                                    onChange={(e) => handleChange("TELPRO", e.target.value)}
                                    value={fields["TELPRO"]}
                                    invalid={errors["TELPRO"] ? true : false}
                                />
                                <FormFeedback>{errors["TELPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="MOVPRO">Móvil</Label>
                                <Input 
                                    id="MOVPRO" 
                                    type="text" 
                                    name="MOVPRO"    
                                    maxLength={12}
                                    onChange={(e) => handleChange("MOVPRO", e.target.value)}
                                    value={fields["MOVPRO"]}
                                    invalid={errors["MOVPRO"] ? true : false}
                                />
                                <FormFeedback>{errors["MOVPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="BANPRO">Banco</Label>
                                <Input 
                                    id="BANPRO" 
                                    type="text" 
                                    name="BANPRO"  
                                    maxLength={50}  
                                    onChange={(e) => handleChange("BANPRO", e.target.value)}
                                    value={fields["BANPRO"]}
                                    invalid={errors["BANPRO"] ? true : false}
                                />
                                <FormFeedback>{errors["BANPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="ENTPRO">Número de cuenta</Label>
                                <Row>
                                    <Col md="3">
                                        <Input 
                                            id="ENTPRO" 
                                            type="text"
                                            maxLength={4}
                                            name="ENTPRO"    
                                            onChange={(e) => handleChange("ENTPRO", e.target.value.replace(/[^0-9.]/g, ''))}
                                            value={fields["ENTPRO"]}
                                            invalid={errors["ENTPRO"] ? true : false}
                                        />
                                    </Col>
                                    <Col md="3">
                                        <Input 
                                            id="OFIPRO" 
                                            type="text"
                                            maxLength={4}
                                            name="OFIPRO"    
                                            onChange={(e) => handleChange("OFIPRO", e.target.value.replace(/[^0-9.]/g, ''))}
                                            value={fields["OFIPRO"]}
                                            invalid={errors["OFIPRO"] ? true : false}
                                        />
                                    </Col>
                                    <Col md="2">
                                        <Input 
                                            id="DCOPRO" 
                                            type="text"
                                            maxLength={2} 
                                            name="DCOPRO"    
                                            onChange={(e) => handleChange("DCOPRO", e.target.value.replace(/[^0-9.]/g, ''))}
                                            value={fields["DCOPRO"]}
                                            invalid={errors["DCOPRO"] ? true : false}
                                        />
                                    </Col>
                                    <Col md="4">
                                        <Input 
                                            id="CTAPRO" 
                                            type="text"
                                            maxLength={10}
                                            name="CTAPRO"    
                                            onChange={(e) => handleChange("CTAPRO", e.target.value.replace(/[^0-9.]/g, ''))}
                                            value={fields["CTAPRO"]}
                                            invalid={errors["CTAPRO"] ? true : false}
                                        />
                                    </Col>
                                </Row>
                                <FormFeedback>
                                    {
                                        errors["ENTPRO"] 
                                            ? errors["ENTPRO"] 
                                            : errors["OFIPRO"]
                                                ? errors["OFIPRO"]
                                                : errors["DCOPRO"]
                                                    ? errors["DCOPRO"]
                                                    : errors["CTAPRO"]
                                                        ? errors["CTAPRO"]
                                                        : null
                                    }
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="IBAPRO">IBAN</Label>
                                <Input 
                                    id="IBAPRO" 
                                    type="text" 
                                    name="IBAPRO"   
                                    maxLength={50} 
                                    onChange={(e) => handleChange("IBAPRO", e.target.value)}
                                    value={fields["IBAPRO"]}
                                    invalid={errors["IBAPRO"] ? true : false}
                                />
                                <FormFeedback>{errors["IBAPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="PCOPRO">Persona de Contacto</Label>
                                <Input 
                                    id="PCOPRO" 
                                    type="text" 
                                    name="PCOPRO" 
                                    maxLength={50}   
                                    onChange={(e) => handleChange("PCOPRO", e.target.value)}
                                    value={fields["PCOPRO"]}
                                    invalid={errors["PCOPRO"] ? true : false}
                                />
                                <FormFeedback>{errors["PCOPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="EMAPRO">Correo electrónico</Label>
                                <Input 
                                    id="EMAPRO" 
                                    type="text" 
                                    name="EMAPRO"   
                                    maxLength={50} 
                                    onChange={(e) => handleChange("EMAPRO", e.target.value)}
                                    value={fields["EMAPRO"]}
                                    invalid={errors["EMAPRO"] ? true : false}
                                />
                                <FormFeedback>{errors["EMAPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="WEBPRO">Web</Label>
                                <Input 
                                    id="WEBPRO" 
                                    type="text" 
                                    name="WEBPRO"  
                                    maxLength={50}  
                                    onChange={(e) => handleChange("WEBPRO", e.target.value)}
                                    value={fields["WEBPRO"]}
                                    invalid={errors["WEBPRO"] ? true : false}
                                />
                                <FormFeedback>{errors["WEBPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="MEMPRO">Mensaje emergente</Label>
                                <Input 
                                    id="MEMPRO" 
                                    type="text" 
                                    name="MEMPRO"    
                                    maxLength={50}
                                    onChange={(e) => handleChange("MEMPRO", e.target.value)}
                                    value={fields["MEMPRO"]}
                                    invalid={errors["MEMPRO"] ? true : false}
                                />
                                <FormFeedback>{errors["MEMPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="ACTPRO">Actividad</Label>
                                <Input 
                                    id="ACTPRO" 
                                    type="select" 
                                    name="ACTPRO"    
                                    onChange={(e) => handleChange("ACTPRO", e.target.value)}
                                    value={fields["ACTPRO"]}
                                    invalid={errors["ACTPRO"] ? true : false}
                                >
                                    <option value="0">[Sin predefinir]</option>
                                    {actividades.map((actividad, key) => {
                                        return <option key={key} value={actividad.CODACT}>{actividad.EPIACT} - {activities[actividad.EPIACT]}</option>
                                    })}
                                </Input>
                                <FormFeedback>{errors["ACTPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="TIPPRO">Tipo de operaciones</Label>
                                <Input 
                                    id="TIPPRO" 
                                    type="select" 
                                    name="TIPPRO"    
                                    onChange={(e) => handleChange("TIPPRO", e.target.value)}
                                    value={fields["TIPPRO"]}
                                    invalid={errors["TIPPRO"] ? true : false}
                                >
                                    <option value="">Seleccionar</option>
                                    <option value="0">Interior</option>
                                    <option value="1">Importación</option>
                                    <option value="2">Intracomunitaria</option>
                                    <option value="3">Agric. Gan. y Pesca</option>

                                    {/* <option value="">Seleccionar</option>
                                    <option value="0">Interior</option>
                                    <option value="1">Importación</option>
                                    <option value="2">Intracomunitario</option>
                                    <option value="3">Agric. Gan. y Pesca</option> */}

                                    {/* <option value="">Seleccionar</option>
                                    <option value="0">General</option>
                                    <option value="1">Intracomunitario</option> 
                                    <option value="2">Importación</option>
                                    <option value="3">Agric. Gan. y Pesca</option> */}
                                </Input>
                                <FormFeedback>{errors["TIPPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label htmlFor="CLAPRO">Clave modelo 190</Label>
                                <Input 
                                    id="CLAPRO" 
                                    type="select" 
                                    name="CLAPRO"    
                                    onChange={(e) => handleChange("CLAPRO", e.target.value)}
                                    value={fields["CLAPRO"]}
                                    invalid={errors["CLAPRO"] ? true : false}
                                >
                                    <option value="0">Seleccionar</option>
                                    <option value="1">A Rendimientos del trabajo: Empleados por cuenta ajena en general.</option>
                                    <option value="2">B Rendimientos del trabajo: Pensionistas y perceptores de haberes pasivos y demás prestaciones previstas en el artículo 17.2.a de la Ley del Impuesto.</option>
                                    <option value="3">C Rendimientos del trabajo: Pestaciones o subsidios por desempleo.</option>
                                    <option value="4">D Rendimientos del trabajo: Prestaciones por desempleo abonadas en la modalidad de pago único.</option>
                                    <option value="5">E Rendimientos del trabajo: Retribuciones a consejeros y administradores.</option>
                                    <option value="6">F Rendimientos del trabajo: Cursos, conferencias, seminarios y similares y elaboración de obras literarias, artísticas o científicas.</option>
                                    <option value="7">G Rendimientos de actividades económicas: actividades profesionales.</option>
                                    <option value="8">H Rendimientos de actividades económicas: actividades agrícolas, ganaderas y forestales y actividades económicas en estimación objetiva del artículo 96.6.2 de...</option>
                                    <option value="9">I Rendimientos de actividades económicas: rendimientos a que se refiere el artículo 92.8 de la Ley del Impuesto.</option>
                                    <option value="10">J Imputación de rentas por cesión de derechos de imagen: contraprestaciones a que se refiere el artículo 92.8 de la Ley del Impuesto.</option>
                                    <option value="11">K Premios y ganancias patrimoniales de los vecinos derivadas de los aprovechamientos forestales en montes públicos.</option>
                                    <option value="12">L Rentas exentas y dietas exeptuadas de gravamen.</option>
                                    <option value="13">M Rendimientos del trabajo a los que resulta de aplicación el régimen especial *33,ª Copa del América*.</option>

                                </Input>
                                <FormFeedback>{errors["CLAPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        {/* <Col md="3">
                            <FormGroup>
                                <Label htmlFor="CLAPRO">Subclave modelo 190 <span className="text-danger">{'<= (Vamos a sacar este campo?)'}</span></Label>
                                <Input 
                                    id="CLAPRO" 
                                    type="select" 
                                    name="CLAPRO"    
                                    onChange={(e) => handleChange("CLAPRO", e.target.value)}
                                    value={fields["CLAPRO"]}
                                    invalid={errors["CLAPRO"] ? true : false}
                                >
                                    <option value="">Seleccionar</option>
                                </Input>
                                <FormFeedback>{errors["CLAPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col> */}
                    </Row>

                    {fields.TIPPRO === '2' ?
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="CINPRO">Clave de operación intracomunitaria</Label>
                                <Input 
                                    id="CINPRO" 
                                    type="select" 
                                    name="CINPRO"    
                                    onChange={(e) => handleChange("CINPRO", e.target.value)}
                                    value={fields["CINPRO"]}
                                    invalid={errors["CINPRO"] ? true : false}
                                >
                                    <option value="0">[Sin predefinir]</option>
                                    <option value="1">Adquisiciones intracomunitarias sujetas.</option>
                                    <option value="2">Entregas intracomunitarias exentas, excepto las entregas en otros Estados miembros subsiguientes a adquisiciones intracomunitarias exentas en el marco de operaciones triangulares, que se consignará «T», y las entregas intracomunitarias de bienes posteriores a una importación exenta, que se consignarán con las claves «M» o «H» según corresponda.</option>
                                    <option value="3">Entregas intracomunitarias de bienes posteriores a una importación exenta, si son efectuadas por el representante fiscal.</option>
                                    <option value="4">Adquisiciones intracomunitarias de servicios localizadas en el TAI prestadas por empresarios y profesionales establecidos en otros Estados miembros cuyo destinatario es el declarante.</option>
                                    <option value="5">Entregas intracomunitarias de bienes posteriores a una importación exenta.</option>
                                    <option value="6">Prestaciones intracomunitarias de servicios realizadas por el declarante.</option>
                                    <option value="7">Entrega intracomunitaria de bienes posteriores a una importación exenta, de acuerdo con el artículo 27.12o de la Ley del Impuesto sobre el Valor Añadido.</option>
                                    <option value="8">Sustituciones del empresario o profesional destinatario de los bienes expedidos o transportados a otro Estado miembro en el marco de acuerdos de bienes en consigna.</option>
                                    <option value="9">Devolución de bienes desde otro Estado miembro al que previamente fueron enviados desde el TAI en el marco de acuerdos de venta de bienes en consigna.</option>
                                    {/* <option value="1">A - Adquisiciones intracomunitarias sujetas</option>
                                    <option value="2">E - Entregas intracomunitarias exentas, excepto las entregas en otros Estados miembros subsiguientes a adquisiciones intracomunitarias exentas en el marco de operaciones triangulares, que se consignará 'T', y las entregas intracomunitarias de bienes posteriores a una importación exenta, que se consignarán con las claves 'M' o 'H' según corresponda</option>
                                    <option value="3">H - Entregas intracomunitarias de bienes posteriores a una importación exenta, de acuerdo con el artículo 27.12o de la Ley del Impuesto sobre el Valor Añadido, efectuadas por el representante fiscal según lo previsto en el artículo 86.Tres de la Ley del Impuesto</option>
                                    <option value="4">I - Adquisiciones intracomunitarias de servicios localizadas en el TAI prestadas por empresarios o profesionales establecidos en otros EM cuyo destinatario es el declarante</option>
                                    <option value="5">M - Entregas intracomunitarias de bienes posteriores a una importación exenta, de acuerdo con el artículo 27.12o de la Ley del Impuesto sobre el Valor Añadido</option>
                                    <option value="6">S - Prestaciones intracomunitarias de servicios realizadas por el declarante</option>
                                    <option value="7">T - Entregas en otros Estados miembros subsiguientes a adquisiciones intracomunitarias exentas en el marco de operaciones triangulares. Cuando se realice alguna entrega de bienes de las mencionadas en el artículo 79, apartado dos del Reglamento del IVA. Estas operaciones, cuando sean efectuadas para un destinatario para el cual se hayan realizado entregas intracomunitarias, se consignarán en un registro independiente de aquél en que se hayan consignado estas últimas operaciones</option> */}
                                </Input>
                                <FormFeedback>{errors["CINPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    : null}
                    
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="TGIPRO">Tipo de gasto</Label>
                                <Input 
                                    id="TGIPRO"
                                    type="select" 
                                    name="TGIPRO"  
                                    maxLength={3}  
                                    onChange={(e) => handleChange("TGIPRO", e.target.value)}
                                    value={fields["TGIPRO"]}
                                    invalid={errors["TGIPRO"] ? true : false}
                                >
                                    <option value="">Seleccionar</option>
                                    {tiposDeGastos.map((tipoDeGasto, key) => {
                                        return <option key={key} value={tipoDeGasto.CODTGI}>{tipoDeGasto.DENTGI}</option>
                                    })}
                                </Input>
                                <FormFeedback>{errors["TGIPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="TPIPRO">Tipo de IVA predefinido</Label>
                                <Input 
                                    id="TPIPRO"
                                    type="select" 
                                    name="TPIPRO"    
                                    onChange={(e) => handleChange("TPIPRO", e.target.value)}
                                    value={fields["TPIPRO"]}
                                    invalid={errors["TPIPRO"] ? true : false}
                                >
                                    <option value="">Seleccionar</option>
                                    <option value="0">Manual</option>
                                    <option value="1">Normal</option>
                                    <option value="2">Reducido</option>
                                    <option value="3">Superreducido</option>
                                    <option value="4">Normal + Recargo de Equivalencia</option>
                                    <option value="5">Reducido + Recargo de Equivalencia</option>
                                    <option value="6">Superreducido + Recargo de Equivalencia</option>
                                </Input>
                                <FormFeedback>{errors["TPIPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>

                    {fields.TIPPRO === '0' ?
                    <Row className="mt-4">
                        <Col md="12">
                            <FormGroup>
                                <Input
                                    type="checkbox" 
                                    id="RCCPRO" 
                                    name="RCCPRO"
                                    defaultChecked={fields["RCCPRO"]}
                                    onClick={(e) => handleChange("RCCPRO", e.target.checked ? 1 : 0)}
                                    invalid={errors["RCCPRO"] ? true : false}
                                />
                                    <Label className="form-check-label" htmlFor="RCCPRO">Acogido al Régimen especial del criterio de caja</Label>
                                <FormFeedback>{errors["RCCPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    : null}
                    
                    <Row>
                        <Col md="9">
                            <FormGroup>
                                <Label htmlFor="TDEPRO">Tipo de dedución</Label>
                                <Input 
                                    id="TDEPRO" 
                                    type="select" 
                                    name="TDEPRO"    
                                    onChange={(e) => handleChange("TDEPRO", e.target.value)}
                                    value={fields["TDEPRO"]}
                                    invalid={errors["TDEPRO"] ? true : false}
                                >
                                    <option value="1">Deducible</option>
                                    <option value="2">No Deducible</option>
                                    <option value="3">Prorrata</option>
                                    <option value="4">Prorrata de la deducción</option>
                                </Input>
                                <FormFeedback>{errors["TDEPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="3">
                            <FormGroup>
                                <Label htmlFor="DEDPRO">% Dedución</Label>
                                <NumberFormat 
                                    id="DEDPRO" 
                                    type="number" 
                                    name="DEDPRO"    
                                    onChange={(e) => handleChange("DEDPRO", e.target.value)}
                                    value={fields["DEDPRO"]}
                                    invalid={errors["DEDPRO"] ? true : false}
                                />
                            <FormFeedback>{errors["DEDPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="9">
                            <FormGroup>
                                <Label htmlFor="TDIPRO">Tipo de dedución IRPF</Label>
                                <Input 
                                    id="TDIPRO" 
                                    type="select" 
                                    name="TDIPRO"    
                                    onChange={(e) => handleChange("TDIPRO", e.target.value)}
                                    value={fields["TDIPRO"]}
                                    invalid={errors["TDIPRO"] ? true : false}
                                >
                                    <option value="0">[Sin predefinir]</option>
                                    <option value="1">Deducible</option>
                                    <option value="2">No Deducible</option>
                                    <option value="3">Prorrata</option>
                                    <option value="4">Prorrata de la deducción</option>
                                </Input>
                                <FormFeedback>{errors["TDIPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="3">
                            <FormGroup>
                                <Label htmlFor="DEIPRO">% Dedución</Label>
                                <NumberFormat 
                                    id="DEIPRO" 
                                    type="number" 
                                    name="DEIPRO"    
                                    onChange={(e) => handleChange("DEIPRO", e.target.value)}
                                    value={fields["DEIPRO"]}
                                    invalid={errors["DEIPRO"] ? true : false}
                                />
                            <FormFeedback>{errors["DEIPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="9">
                            <FormGroup>
                                <Label htmlFor="TREPRO">Tipo de retenciones</Label>
                                <Input 
                                    id="TREPRO" 
                                    type="select" 
                                    name="TREPRO"
                                    onChange={(e) => {
                                        setFields({ 
                                            ...fields,
                                            TREPRO: e.target.value,
                                            RETPRO: e.target.value === '1' ? 15.00 : (e.target.value === '5' ? 19.00 : 0)
                                        })
                                    }}
                                    value={fields["TREPRO"]}
                                    invalid={errors["TREPRO"] ? true : false}
                                >
                                    <option value="0">[Sin predefinir]</option>
                                    <option value="1">Actividad profesional (dineraria) Retención 15% y 7%</option>
                                    <option value="2">Actividad profesional (en espécie)</option>
                                    <option value="3">Actividad agrícola (dineraria)</option>
                                    <option value="4">Actividad agrícola (en espécie)</option>
                                    <option value="5">Arrendamiento (dinerario) Retención 19%</option>
                                    <option value="6">Arrendamiento (en espécie)</option>
                                    <option value="7">Actividad empresarial</option>
                                </Input>
                                <FormFeedback>{errors["TREPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md="3">
                            <FormGroup>
                                <Label htmlFor="RETPRO">% Retención</Label>
                                <Input 
                                    id="RETPRO" 
                                    type="number" 
                                    name="RETPRO"    
                                    onChange={(e) => handleChange("RETPRO", e.target.value)}
                                    value={fields["RETPRO"]}
                                    invalid={errors["RETPRO"] ? true : false}
                                />
                            <FormFeedback>{errors["RETPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="COPPRO">Clave de operación habitual</Label>
                                <Input 
                                    id="COPPRO" 
                                    type="select" 
                                    name="COPPRO"    
                                    onChange={(e) => handleChange("COPPRO", e.target.value)}
                                    value={fields["COPPRO"]}
                                    invalid={errors["COPPRO"] ? true : false}
                                >
                                    {/* <option value="0">[Sin predefinir]</option> */}
                                    {clienteClaveOperacionHabitual.map((claveOperacion, key) => {
                                        return <option value={key}>{claveOperacion}</option>
                                    })}
                                </Input>
                                <FormFeedback>{errors["COPPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="ACPPRO">Ayuda en el cálculo</Label>
                                <Input 
                                    id="ACPPRO" 
                                    type="select" 
                                    name="ACPPRO"    
                                    onChange={(e) => handleChange("ACPPRO", e.target.value)}
                                    value={fields["ACPPRO"]}
                                    invalid={errors["ACPPRO"] ? true : false}
                                >
                                    <option value="">Seleccionar</option>
                                    <option value="0">Manual</option>
                                    <option value="1">Un tipo de IVA</option>
                                    <option value="2">Varios tipos de IVA</option>
                                    <option value="3">Exenta de IVA</option>
                                    <option value="4">Parte exenta, con un tipo de IVA</option>
                                    <option value="5">Parte exenta, con varios tipos de IVA</option>
                                    <option value="6">Un tipo de IVA con I.R.P.F.</option>
                                    <option value="7">Varios tipos de IVA con I.R.P.F.</option>
                                    <option value="8">Exenta de IVA con I.R.P.F.</option>
                                    <option value="9">Parte exenta, con un tipo de IVA con I.R.P.F.</option>
                                    <option value="10">Parte exenta, con varios tipos de IVA con I.R.P.F.</option>
                                </Input>
                                <FormFeedback>{errors["ACPPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label htmlFor="LOCPRO">Local arrendado</Label>
                                <Input 
                                    id="LOCPRO" 
                                    type="select" 
                                    name="LOCPRO"    
                                    onChange={(e) => handleChange("LOCPRO", e.target.value)}
                                    value={fields["LOCPRO"]}
                                    invalid={errors["LOCPRO"] ? true : false}
                                >
                                    <option value="">Seleccionar</option>
                                    {locales.map((local, key) => {
                                        return <option key={key} value={local.CODLOC}>{local.DESLOC}</option>
                                    })}

                                    {/* <option value="0">[Sin predefinir]</option>
                                    <option value="1">Actividad profesional (dineraria)</option>
                                    <option value="2">Actividad profesional (en espécie)</option>
                                    <option value="3">Actividad agrícola (dineraria)</option>
                                    <option value="4">Actividad agrícola (en espécie)</option>
                                    <option value="5">Arrendamiento (dinerario)</option>
                                    <option value="6">Arrendamiento (en espécie)</option>
                                    <option value="7">Actividad empresarial</option> */}
                                </Input>
                                <FormFeedback>{errors["LOCPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="3">
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Input 
                                    id="OBSPRO" 
                                    type="textarea" 
                                    name="OBSPRO"    
                                    onChange={(e) => handleChange("OBSPRO", e.target.value)}
                                    value={fields["OBSPRO"]}
                                    invalid={errors["OBSPRO"] ? true : false}
                                    style={{height: 300}}
                                />
                                <FormFeedback>{errors["OBSPRO"]}</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </TabPane>
            </TabContent> 
            </Row>           

            <br />
            <div className="d-flex">
              <Button type="submit" color="primary" disabled={loading || loading2 || loading3 || loading4}>{!params.id || (params.id && isModal) ? 'Crear' : 'Editar'} {loading ? <Spinner size="sm" className="ms-1" /> : null}</Button>
              <Button type="button" color="dark" className="ms-2" outline disabled={loading || loading2 || loading3 || loading4} onClick={() => !isModal ? navigate('/suppliers') : closeModal(false)}>Cancelar</Button>
              {params.id ? <Button color="danger" type="button" className="ms-auto" outline disabled={loading || loading2 || loading3 || loading4} onClick={() => setModalDelete(true)}>Eliminar{loading3 ? <Spinner size="sm" className="ms-1" /> : null}</Button> : null}
            </div>
          </Form>
          : 
            loading2 ?
                <Loading />  
            :
                <EmptyData
                title={`No existe proveedor con código ${params.id}`}
                btn="Crear proveedor"
                onClick={() => [setFields({}), navigate('/suppliers/new')]}
                />
          }
        </CardBody>
      </Card>

      <Modal isOpen={modalDelete} toggle={() => setModalDelete(false)}>
        <ModalHeader toggle={() => setModalDelete(false)}>Eliminar usuario</ModalHeader>
        <ModalBody>
          ¿Está seguro de que desea eliminar el proveedor {fields?.NOMPRO}?
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            disabled={loading || loading2 || loading3}
            onClick={() => handleDelete()}
          >
            {loading3 ? <Spinner size="sm" className="me-1"/> : null}
            {!loading3 ? 'Eliminar' : 'Eliminando...'}
          </Button>
          {' '}
          <Button outline disabled={loading || loading2 || loading3} onClick={() => setModalDelete(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}