import * as React from 'react';
import { Card, CardBody, CardTitle, CardHeader, Row, Col, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, FormGroup, Label, Input } from 'reactstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import Loading from '../components/Loading';
import EmptyData from '../components/EmptyData';
import Drawer from '../components/Drawer';
import Filter from '../components/Filter';
import activities from '../global/activities';
import tiposDeOperaciones from '../global/tipos_de_operaciones';
import clienteClaveOperacionHabitual from '../global/cliente_clave_operacion_habitual';
import NumberFormat from '../components/NumberFormat';

export default function IssuedInvoices({ period, source, issuedInvoices, setIssuedInvoices }) {
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [modalDelete, setModalDelete] = React.useState(null);
  const [modalCopy, setModalCopy] = React.useState(null);
  const [drawer, setDrawer] = React.useState(false);
  const [actividades, setActividades] = React.useState([]);
  const [totales, setTotales] = React.useState({});

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  React.useEffect(() => {
    getData();
  }, [period])

  const getData = (filter='') => {
    setLoading(true);
    axios.get(`/api/issued-invoices/?ejercicio=${period}&filtro=CODFEM>=0 ${filter ? filter : ''} ORDER BY CODFEM DESC`, {
      cancelToken: source.token
    })
      .then(res => {
        let totales = {
          base_exenta: 0,
          base_imponible: 0,
          iva: 0,
          re: 0,
          retencion: 0,
          suplido: 0,
          total: 0
        };
        res.data.forEach((item) => {
          totales.base_exenta = totales.base_exenta + item.BA0FEM;
          totales.base_imponible = totales.base_imponible + item.BA1FEM + item.BA2FEM + item.BA3FEM + item.BA4FEM + item.BA5FEM;
          totales.iva = totales.iva + item.CI1FEM + item.CI2FEM + item.CI3FEM + item.CI4FEM + item.CI5FEM;
          totales.re = totales.re + item.CR1FEM + item.CR2FEM + item.CR3FEM + item.CR4FEM + item.CR5FEM;
          totales.retencion = totales.retencion + item.IRETFEM;
          totales.suplido = totales.suplido + item.SUPFEM;
          totales.total = totales.total + item.TOTFEM;
        })

        setTotales(totales);

        setIssuedInvoices(res.data);
        setLoading(false);
        setDrawer(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setIssuedInvoices([]);
          setLoading(false);
          setDrawer(false);
        }
      })

    axios.get(`/api/issued-invoices/actividades/?ejercicio=${period}&filtro=CODACT>=0`, {
      cancelToken: source.token
      })
      .then(res => {
        console.log('res', res.data)
        setActividades(res.data);
      })
  }

  const handleCopy = (e, item) => {
    e.preventDefault();
    setLoading2(true);

    axios.get(`/api/issued-invoices/last_id/?ejercicio=${period}`, {
      cancelToken: source.token
    })
      .then(res => {
        let data = {...item, CODFEM: res.data}
        axios.post(`/api/issued-invoices/?ejercicio=${period}`, data)
          .then(res => {
            setLoading2(false);
            setModalCopy(null);
            let _issuedInvoices = [...issuedInvoices]
            _issuedInvoices.unshift(data)
            setIssuedInvoices(_issuedInvoices);
            toast('Item copiado con éxito', {type: 'success'});
            navigate(`/issued-invoices/${data.CODFEM}?period=${searchParams.get('period')}`)
          })
          .catch((err) => {
            err.response.data.forEach((error, key) => {
                setLoading(false);
                toast(error, {type: 'error'});
            })
          })
      })
      .catch(err => {
        setLoading2(false);
        setModalCopy(null);
        toast(JSON.stringify(err.response.data), {type: 'error'});
      })
  }

  const handleDelete = (e, id) => {
    e.preventDefault();
    setLoading2(true);

    axios.delete(`/api/issued-invoices/${id}/?ejercicio=${period}`)
      .then(res => {
        setLoading2(false);
        setModalDelete(null);
        const newData = issuedInvoices.filter(item => item.CODFEM !== id);
        setIssuedInvoices(newData);
        toast('Factura emitida eliminado con éxito', {type: 'success'});
      })
      .catch(err => {
        setLoading2(false);
        setModalDelete(null);
        toast(JSON.stringify(err.response.data), {type: 'error'});
      })
  }

  return (
    <>
      <div className='d-flex pb-4' style={{width: '100%', overflowY: 'hidden', overflowX: 'auto'}}>
        <div className="me-2" style={{flex: 1}}>
          <Card className="h-100 m-0">
            <CardBody className="pt-3 pb-3">
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <div className="">
                    <small className="m-0">
                      Base Exenta <br/>
                      <b>{<NumberFormat value={totales?.base_exenta} displayType={'text'} />}</b>
                    </small>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="ms-2 me-2" style={{flex: 1}}>
          <Card className="h-100 m-0">
            <CardBody className="pt-3 pb-3">
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <div className="">
                    <small className="m-0">
                      Base Imponible <br/>
                      <b>{<NumberFormat value={totales?.base_imponible} displayType={'text'} />}</b>
                    </small>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="ms-2 me-2" style={{flex: 1}}>
          <Card className="h-100 m-0">
            <CardBody className="pt-3 pb-3">
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <div className="">
                    <small className="m-0">
                      IVA <br/>
                      <b>{<NumberFormat value={totales?.iva} displayType={'text'} />}</b>
                    </small>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="ms-2 me-2" style={{flex: 1}}>
          <Card className="h-100 m-0">
            <CardBody className="pt-3 pb-3">
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <div className="">
                    <small className="m-0">
                      R.E. <br/>
                      <b>{<NumberFormat value={totales?.re} displayType={'text'} />}</b>
                    </small>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="ms-2 me-2" style={{flex: 1}}>
          <Card className="h-100 m-0">
            <CardBody className="pt-3 pb-3">
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <div className="">
                    <small className="m-0">
                      Retención <br/>
                      <b>{<NumberFormat value={totales?.retencion} displayType={'text'} />}</b>
                    </small>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="ms-2 me-2" style={{flex: 1}}>
          <Card className="h-100 m-0">
            <CardBody className="pt-3 pb-3">
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <div className="">
                    <small className="m-0">
                      Suplidos <br/>
                      <b>{<NumberFormat value={totales?.suplido} displayType={'text'} />}</b>
                    </small>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="ms-2" style={{flex: 1}}>
          <Card className="h-100 m-0">
            <CardBody className="pt-3 pb-3">
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <div className="">
                    <small className="m-0">
                      Total de Factura <br/>
                      <b>{<NumberFormat value={totales?.total} displayType={'text'} />}</b>
                    </small>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
      <Card>
        <CardHeader className="border-0 pb-0">
          <Row>
              <Col className="d-flex align-items-center">
                <CardTitle>Facturas Emitidas</CardTitle>
                { loading && issuedInvoices ? <div className="mb-2 ms-2"><Loading text="Actualizando..." /></div> : null }
              </Col>
              <Col className="d-flex justify-content-end">
                <div className="d-flex">
                  <Button size="sm" color="dark" outline className="d-flex align-items-center" onClick={() => setDrawer(true)}><span className="material-icons">filter_list</span> Filtrar</Button>
                  <Button size="sm" color="primary" className="d-flex align-items-center ms-2" onClick={() => navigate(`/issued-invoices/new?period=${searchParams.get('period')}`)}><span className="material-icons">add</span> Crear factura emitida</Button>
                </div>
              </Col>
            </Row>
        </CardHeader>
        <CardBody>
          {issuedInvoices && issuedInvoices.length >= 0  ?
            issuedInvoices.length > 0 ?
            <>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Tipo</th>
                    <th>Registro</th>
                    <th>Actividad</th>
                    <th>NIF</th>
                    <th>Nombre cliente</th>
                    <th>Factura</th>
                    <th>Tipo de operación</th>
                    <th>Clave de operación</th>
                    <th>Fecha del registro</th>
                    <th>Base exenta</th>
                    <th>Base imponible</th>
                    <th>IVA</th>
                    <th>R.E.</th>
                    <th>Retención</th>
                    <th>Suplido</th>
                    <th>Total factura</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {issuedInvoices.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>{item?.MOVFEM === 'F' ? 'Factura Emitida' : 'Ingreso'}</td>
                        <td>{item?.CODFEM}</td>
                        <td>{actividades.find(actividad => actividad.CODACT === item?.ACTFEM)?.EPIACT} - {activities[actividades.find(actividad => actividad.CODACT === item?.ACTFEM)?.EPIACT]}</td>
                        <td>{item?.NIFFEM}</td>
                        <td>{item?.NOMFEM}</td>
                        <td>{item?.FACFEM}</td>
                        <td>{tiposDeOperaciones[item?.TIPFEM]}</td>
                        <td>{clienteClaveOperacionHabitual[item?.COPFEM]}</td>
                        <td>{moment(item?.FECFEM).format('DD/MM/YYYY')}</td>
                        <td>{<NumberFormat value={item.BA0FEM} displayType={'text'} />}</td>
                        <td>{<NumberFormat value={item?.BA1FEM + item.BA2FEM + item.BA3FEM + item.BA4FEM + item.BA5FEM} displayType={'text'} />}</td>
                        <td>{<NumberFormat value={item?.CI1FEM + item.CI2FEM + item.CI3FEM + item.CI4FEM + item.CI5FEM} displayType={'text'} />}</td>
                        <td>{<NumberFormat value={item?.CR1FEM + item.CR2FEM + item.CR3FEM + item.CR4FEM + item.CR5FEM} displayType={'text'} />}</td>
                        <td>{<NumberFormat value={item?.IRETFEM} displayType={'text'} />}</td>
                        <td>{<NumberFormat value={item?.SUPFEM} displayType={'text'} />}</td>
                        <td>{<NumberFormat value={item?.TOTFEM} displayType={'text'} />}</td>
                        
                        <td className="d-flex">
                          <Button size="sm" outline className="pb-0 me-1" onClick={() => navigate(`/issued-invoices/${item.CODFEM}?period=${searchParams.get('period')}`)}><span className="material-icons fs-6">edit</span></Button>
                          <Button size="sm" outline className="pb-0 me-1" onClick={() => setModalCopy(item)}><span className="material-icons fs-6">content_copy</span></Button>
                          <Button size="sm" color="danger" outline className="pb-0 me-1" onClick={() => setModalDelete(item.CODFEM)}><span className="material-icons fs-6">delete</span></Button>
                          
                          <Modal isOpen={modalCopy === item} toggle={() => setModalCopy(null)}>
                            <ModalHeader toggle={() => setModalCopy(null)}>Copiar factura</ModalHeader>
                            <ModalBody>
                              ¿Está seguro de que desea copiar la factura {item.CODFEM}?
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                type="button"
                                color="primary"
                                disabled={loading2}
                                onClick={(e) => handleCopy(e, item)}
                              >
                                {loading2 ? <Spinner size="sm" className="me-1"/> : null}
                                {!loading2 ? 'Copiar' : 'Copiando...'}
                              </Button>
                              {' '}
                              <Button outline disabled={loading2} onClick={() => setModalCopy(null)}>
                                Cancel
                              </Button>
                            </ModalFooter>
                          </Modal>

                          <Modal isOpen={modalDelete === item.CODFEM} toggle={() => setModalDelete(null)}>
                            <ModalHeader toggle={() => setModalDelete(null)}>Eliminar factura emitida</ModalHeader>
                            <ModalBody>
                              ¿Está seguro de que desea eliminar la factura emitida con código {item.CODFEM}?
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                type="button"
                                color="danger"
                                disabled={loading2}
                                onClick={(e) => handleDelete(e, item.CODFEM)}
                              >
                                {loading2 ? <Spinner size="sm" className="me-1"/> : null}
                                {!loading2 ? 'Eliminar' : 'Eliminando...'}
                              </Button>
                              {' '}
                              <Button outline disabled={loading2} onClick={() => setModalDelete(null)}>
                                Cancel
                              </Button>
                            </ModalFooter>
                          </Modal>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
              {/* <Pagination data={data} /> */}
            </>
            :
              <EmptyData
                title="No hay facturas emitidas registrados"
                btn="Crear factura emitida"
                onClick={() => navigate(`/issued-invoices/new?period=${searchParams.get('period')}`)}
              />
          : 
              <Loading />
          }
        </CardBody>
      </Card>

      <Drawer 
        isOpen={drawer} 
        direction={'right'}
        title="Filtrar"
        onClose={() => setDrawer(false)}
      >
        <Filter 
          source={source} 
          period={period}
          type="issued-invoices"
          loading={loading}
          onFilter={getData}
        />
      </Drawer>
    </>
  );
}