import React, { useState, useEffect } from "react";
import { Outlet, Link, useNavigate, useLocation, useSearchParams, useParams } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, FormGroup, Modal, ModalBody, ModalHeader, ListGroup, ListGroupItem } from 'reactstrap';
import { SlideDown } from 'react-slidedown'
import axios from 'axios';

export default function App({ source, period, changePeriod }) {
  const [menu, setMenu] = useState(false);
  const [submenu, setSubmenu] = useState(null);
  const [dropdown, setDropdown] = useState(false);
  const [user, setUser] = useState(null);
  const [periodos, setPeriodos] = useState([]);
  const [periodo, setPeriodo] = useState(null);
  const [modalAccount, setModalAccount] = useState(false);
  const [users, setUsers] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if(localStorage.token === undefined) navigate('/login');
  }, [])
  
  useEffect(() => {
    if(
      location.pathname === '/products' || 
      location.pathname === '/categories'  
    ) {
      setSubmenu('catalog')
    }
    else {
      setSubmenu(null)
    }
  }, [location.pathname])

  useEffect(() => {
    if(localStorage.token === undefined) navigate('/login');
    if(!searchParams.get('period') || searchParams.get('period') === 'null') setSearchParams({period: period})
  }, [period, searchParams])

  const setCustomer = (item) => {
    setCustomerData(item)
    console.log('axios.defaults.headers.get', axios.defaults.headers)
    axios.defaults.headers.get['Admin-User'] = item.id
    axios.defaults.headers.post['Admin-User'] = item.id
    axios.defaults.headers.put['Admin-User'] = item.id
    axios.defaults.headers.patch['Admin-User'] = item.id
    axios.defaults.headers.delete['Admin-User'] = item.id
    setModalAccount(false)
    setRefresh(true)
    setTimeout(() => {
      setRefresh(false)
    }, 10)
  }


  useEffect(() => {
    axios.get('/api/auth/profile/')
      .then(res => {
        setUser(res.data);
        console.clear()
        console.log('asdf', res.data.is_staff)

        if(!customerData && res.data.is_staff) {
          setModalAccount(true)
    
          axios.get('/api/users/?pagination=false')
            .then((res) => {
              setUsers(res.data);
            })
        }
      })

      //get current year new Date()
      let year = new Date().getFullYear();
      let periodos = [];
      for(let i = year; i >= 2010; i--) {
        periodos.push(i)
      }

      setPeriodos(periodos)
      if(searchParams.get('period')) {
        source.cancel('Operation canceled by the user.')
        changePeriod(searchParams.get('period'))
      } else {
        changePeriod(new Date().getFullYear())
      }
  }, [])

  const logout = () => {
    localStorage.clear();
    delete axios.defaults.headers.common['Authorization'];
    navigate('/login');
  }

  return (
    <div className={`app ${menu ? 'show-menu' : ''}`}>
      <aside>
        <div className="sidebar d-flex flex-column flex-shrink-0 text-white">
            <Link to="/dashboard" className="sidebar-top d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
              <img src="/logo-gp.png" style={{width: 30}}/>
              <h1 className="mb-0 ms-2" style={{fontSize: 18}}>GestaPymes</h1>
            </Link>
            <div className="sidebar-menu">
              <h4>Ejercicio</h4>
              <FormGroup className="ps-3 pe-3">
                <Input
                  type="select"
                  name="period"
                  size="sm"
                  style={{
                    height: 32,
                    backgroundColor: '#00000055',
                    border: 'none',
                    color: '#fff',
                  }}
                  disabled={
                    location.pathname !== '/customers' && 
                    location.pathname !== '/customers/' && 
                    location.pathname !== '/suppliers' && 
                    location.pathname !== '/suppliers/' &&
                    location.pathname !== '/issued-invoices' && 
                    location.pathname !== '/issued-invoices/' &&
                    location.pathname !== '/invoices-received' &&
                    location.pathname !== '/invoices-received/' &&
                    location.pathname !== '/investment-assets' &&
                    location.pathname !== '/investment-assets/' &&
                    location.pathname !== '/billings' &&
                    location.pathname !== '/billings/' &&
                    location.pathname !== '/dashboard' &&
                    location.pathname !== '/dashboard/'
                  }
                  onChange={(e) => [source.cancel('Operation canceled by the user.'), changePeriod(parseInt(e.target.value), setSearchParams({period: e.target.value}))]}
                >
                  {periodos.map((item, key) => {
                    return <option key={key} selected={parseInt(period) === parseInt(item)}>{item}</option>
                  })}
                </Input>
              </FormGroup>

              <h4>Menu</h4>
              <ul className="sidebar-bottom nav nav-pills flex-column">
                  <li>
                    <Link to={`/dashboard?period=${searchParams.get('period')}`} className={`nav-link text-white ${location.pathname.indexOf('/dashboard') > -1 ? 'active' : ''}`}>
                      <span className="material-icons">dashboard</span>
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to={`/customers?period=${searchParams.get('period')}`} className={`nav-link text-white ${location.pathname.indexOf('/customers') > -1 ? 'active' : ''}`}>
                      <span className="material-icons">people</span>
                      Clientes
                    </Link>
                  </li>
                  <li>
                    <Link to={`/suppliers?period=${searchParams.get('period')}`} className={`nav-link text-white ${location.pathname.indexOf('/suppliers') > -1 ? 'active' : ''}`}>
                      <span className="material-icons">room_preferences</span>
                      Proveedores
                    </Link>
                  </li>
                  <li>
                    <Link to={`/issued-invoices?period=${searchParams.get('period')}`} className={`nav-link text-white ${location.pathname.indexOf('/issued-invoices') > -1 ? 'active' : ''}`}>
                      <span className="material-icons">receipt</span>
                      Facturas Emitidas
                    </Link>
                  </li>
                  <li>
                    <Link to={`/invoices-received?period=${searchParams.get('period')}`} className={`nav-link text-white ${location.pathname.indexOf('/invoices-received') > -1 ? 'active' : ''}`}>
                      <span className="material-icons">receipt</span>
                      Facturas Recibidas
                    </Link>
                  </li>
                  <li>
                    <Link to={`/investment-assets?period=${searchParams.get('period')}`} className={`nav-link text-white ${location.pathname.indexOf('/investment-assets') > -1 ? 'active' : ''}`}>
                      <span className="material-icons">paid</span>
                      Bienes de Inversión
                    </Link>
                  </li>
                  <li>
                    <Link to={`/billings?period=${searchParams.get('period')}`} className={`nav-link text-white ${location.pathname.indexOf('/billings') > -1 ? 'active' : ''}`}>
                      <span className="material-icons">request_quote</span>
                      Facturación
                    </Link>
                  </li>
                  {/* <li>
                    <Link to={`/invoices-received/?period=${searchParams.get('period')}`} className={`nav-link text-white ${location.pathname.indexOf('/invoices-received') > -1 ? 'active' : ''}`}>
                      <span className="material-icons">receipt</span>
                      Facturas Recibidas
                    </Link>
                  </li> */}

                  {user?.is_staff ?
                  <>
                    <h4 className="mt-3">Configuración</h4>
                    <li>
                      <Link to={`/users/?period=${searchParams.get('period')}`} className={`nav-link text-white ${location.pathname.indexOf('/users') > -1 ? 'active' : ''}`}>
                        <span className="material-icons">manage_accounts</span>
                        Usuarios
                      </Link>
                    </li>
                  </>
                  : null}

                  {/*<li>
                    <div className="nav-link text-white" onClick={() => setSubmenu(submenu !== 'catalog' ? 'catalog' : null)}>
                      <span className="material-icons">category</span>
                      Catálogo
                    </div>

                    <SlideDown>
                    {submenu === 'catalog' ?
                      <ul>
                        <li>
                          <Link to="/products" className={`nav-link text-white ${location.pathname === '/products' ? 'active' : ''}`}>Productos</Link>
                        </li>
                        <li>
                          <Link to="/categories" className={`nav-link text-white ${location.pathname === '/categories' ? 'active' : ''}`}>Categorias</Link>
                        </li>
                      </ul>
                    : null}
                    </SlideDown>

                  </li>
                  <li>
                    <Link to="/dashboard" className="nav-link text-white">
                      <span className="material-icons">paid</span>
                      Ventas
                    </Link>
                  </li>
                  <li>
                    <Link to="/dashboard" className="nav-link text-white">
                      <span className="material-icons">inventory_2</span>
                      Inventario
                    </Link>
                  </li> */}
              </ul>
            </div>
        </div>
      </aside>
      <main>
        <nav className="navbar navbar-expand-sm bg-light navbar-light">
            <div className="container-fluid ps-4 pe-4">
              <div className="collapse navbar-collapse" id="collapsibleNavbar">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                        <div className="nav-link p-0 navbar-menu" onClick={() => setMenu(!menu)}>
                          <span className="material-icons">menu</span>
                        </div>
                    </li>
                    {/* <li className="nav-item">
                        <a className="nav-link" href="#">Link</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">Link</a>
                    </li> */}
                  </ul>
                  <ul className="navbar-nav ms-auto">
                    {user?.is_staff ?
                    <li className="nav-item">
                        <a className="nav-link d-flex align-items-center" href="#" onClick={() => setModalAccount(true) }><span className="material-icons m-0 me-1">person</span> {customerData ? customerData.username : 'Seleccionar cuenta'}</a>
                    </li>
                    : null}

                    {/* <li className="nav-item">
                        <a className="nav-link" href="#">Link</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">Link</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">Link</a>
                    </li> */}
                    {/* <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">Full Name</a>
                        <ul className="dropdown-menu dropdown-menu-end">
                          <li><a className="dropdown-item" href="#"><span className="material-icons">person</span> Perfil</a></li>
                          <li><a className="dropdown-item" href="#"><span className="material-icons">vpn_key</span> Cambiar contraseña</a></li>
                          <li><a className="dropdown-item" href="/"><span className="material-icons">logout</span> Salir</a></li>
                        </ul>
                    </li> */}
                    <Dropdown className="nav-item" isOpen={dropdown} toggle={() => setDropdown(!dropdown)}>
                      <DropdownToggle tag="a" className="nav-link" caret style={{cursor: 'pointer'}}>
                        {user?.first_name} {user?.last_name}
                      </DropdownToggle>
                      <DropdownMenu container="body">
                        <DropdownItem onClick={() => navigate(`/profile/?period=${searchParams.get('period')}`)}><span className="material-icons">person</span> Perfil</DropdownItem>
                        <DropdownItem onClick={() => navigate(`/change-password/?period=${searchParams.get('period')}`)}><span className="material-icons">vpn_key</span> Cambiar contraseña</DropdownItem>
                        <DropdownItem onClick={() => logout()}><span className="material-icons">logout</span> Salir</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </ul>
              </div>
            </div>
        </nav>
        <div className="container-fluid p-4">
            {!refresh ? <Outlet profile={'profile={profile} '}  /> : null}
        </div>
        <div className="ps-4 pe-4 d-flex align-items-center mt-auto" style={{width: '100%', height: 40, minHeight: 40, maxHeight: 40, backgroundColor: '#fff', borderTop: '1px solid #eee'}}>
            <div className="d-flex align-items-center w-100" style={{fontSize: 12}}>
              <div>© 2022 GestaPymes - Todos los derechos reservados</div>
              <div className="ms-auto">Desarrollado por: <a href="https://start-7.com/" target="_blank">Start7</a></div>
            </div>
        </div>
      </main>

      <Modal isOpen={modalAccount} toggle={() => setModalAccount(false)}>
        <ModalHeader>
          <h5>Seleccione una cuenta</h5>
        </ModalHeader>
        <ModalBody>
          <ListGroup>
            {users && users.map((item, key) => {
              return (
                <ListGroupItem onClick={() => setCustomer(item)}>
                  <b>Nombre:</b> {item.first_name} {item.last_name} <br/>
                  <b>Correo:</b> {item.username} <br/>
                  <b>Base de datos: </b> {item?.profile?.customer_database}
                </ListGroupItem>
              )
            })}
          </ListGroup>
        </ModalBody>
      </Modal>
    </div>
  );
}